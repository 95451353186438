import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import useChangeAccessToken from "../hooks/useChangeAccessToken";

function PrivateRoute({ children }) {
  const isLogin = useSelector(state => state.auth.userAuth);

  // 인증 필요 페이지 접근 시 token 유효기간 체크
  useEffect(() => {
    if (isLogin !== null) {
      useChangeAccessToken(isLogin);
    }
  }, [isLogin]);

  if (!isLogin) {
    // 미로그인 상태에서 인증 페이지 접근 시도 시 메인 페이지 리다이렉트
    return <Navigate to="/home" replace />;
  }

  return children;
}

export default PrivateRoute;
