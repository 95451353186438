import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { contactSubmitApi } from "../../../services/controller/contactController";

import { SET_ALERT_MSG } from "../shared/commonSlice";

// initialState
const initialState = {
  isLoading: false,
};

// Actions (API)
export const submitContact = createAsyncThunk(
  "contactSlice/submitContact",
  async (res, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await contactSubmitApi({
        name: res.name,
        companyName: res.companyName,
        tel: res.tel,
        email: res.email,
        content: res.content,
        agree: res.agree,
      });
      if (data.code === "0000") {
        dispatch(SET_ALERT_MSG("문의를 성공적으로 등록했습니다."));
      }
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.status || "알 수 없는 에러");
    } finally {
      dispatch(SET_ALERT_MSG("문의하기에 실패했습니다. 다시 시도해주세요."));
    }
  },
);

const contactSlice = createSlice({
  name: "contactSlice",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(submitContact.pending, state => {
        state.isLoading = true;
      })
      .addCase(submitContact.fulfilled, state => {
        state.isLoading = false;
      })
      .addCase(submitContact.rejected, state => {
        state.isLoading = false;
      });
  },
});

export default contactSlice.reducer;
