/**
 * 입력받은 term에 맞는 기간 return 함수
 * 시작일은 함수 사용 당일 기준
 *
 * @param {string} term monthly or yearly
 * @returns {{start: string, end: string}} 시작일, 종료일 (YYYY-MM-DD) 형태
 * */
const usePeriodOfUse = term => {
  // 오늘 날짜를 시작일로 설정
  const startDate = new Date();
  // 종료일도 시작일과 동일하게 초기화
  const endDate = new Date(startDate);

  switch (term) {
    case "monthly":
      endDate.setMonth(endDate.getMonth() + 1);
      break;
    case "yearly":
      endDate.setFullYear(endDate.getFullYear() + 1);
      break;
    default:
      throw new Error("error");
  }

  // YYYY-MM-DD 형태로 반환하기 위한 간단한 포맷팅
  const startStr = startDate.toISOString().split("T")[0];
  const endStr = endDate.toISOString().split("T")[0];

  // return {
  //   start: startStr,
  //   end: endStr,
  // };
  return `${startStr} ~ ${endStr}`;
};

export default usePeriodOfUse;
