import React, { useState } from "react";
import styled from "styled-components";

import { TheButton, TheInput, TheTable } from "../../../components";

import rockIcon from "../../../assets/images/lock.png";
import { changePwdApi } from "../../../services/controller/mypageController";

import { SET_ALERT_MSG } from "../shared/commonSlice";
import { USER_LOGOUT } from "../shared/authSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const PwEditTable = () => {
  const { t } = useTranslation("mypage");

  const dispatch = useDispatch();
  const [pwInfo, serPwInfo] = useState({
    currentPwd: "",
    newPwd: "",
    checkPwd: "",
  });

  const handleInputChange = ({ field, e }) => {
    const updatedValue = e.target.value;

    serPwInfo(prevState => ({
      ...prevState,
      [field]: updatedValue,
    }));
  };

  const handlePwdChange = async e => {
    e.preventDefault();

    let { currentPwd, newPwd, checkPwd } = pwInfo;
    currentPwd = currentPwd.trim();
    newPwd = newPwd.trim();
    checkPwd = checkPwd.trim();

    if (newPwd !== checkPwd) {
      dispatch(SET_ALERT_MSG(t("notSame")));
      return;
    }
    if (currentPwd === checkPwd) {
      dispatch(SET_ALERT_MSG(t("same")));
      return;
    }

    try {
      let data = await changePwdApi({
        p0: currentPwd,
        p1: newPwd,
        p2: checkPwd,
      });

      switch (data?.status) {
        case "0000":
          serPwInfo({ currentPwd: "", newPwd: "", checkPwd: "" });
          dispatch(USER_LOGOUT());
          dispatch(SET_ALERT_MSG(t("changeComplete")));
          return;
        default:
          return;
      }
    } catch (error) {
      if (error.code === "ERROR_0014") {
        dispatch(SET_ALERT_MSG(t("cannotUse")));
        return;
      }
      dispatch(SET_ALERT_MSG(error.message));
    }
  };

  return (
    <TableContainer onSubmit={handlePwdChange}>
      <div className="changeInfoContainer">
        <div className="imgWrap">
          <img src={rockIcon} alt="rock" />
        </div>
        <div className="changeInfoContent">
          <p>{t("cautionTitle")}</p>
          <ul>
            <li>{t("caution1")}</li>
            <li>{t("caution2")}</li>
            <li>{t("caution3")}</li>
            <li>{t("caution4")}</li>
          </ul>
        </div>
      </div>
      <div className="title">{t("changePwTitle")}</div>
      <TheTable type="details" colgroupList={["15%", "85%"]}>
        <tr>
          <td>{t("currentPw")}</td>
          <td className="inputWrap">
            <TheInput
              type="password"
              $width="100%"
              $padding="0.44rem 1rem"
              value={pwInfo.currentPwd}
              _onChange={e => handleInputChange({ field: "currentPwd", e })}
            />
          </td>
        </tr>
        <tr>
          <td>{t("newPw")}</td>
          <td className="inputWrap">
            <TheInput
              type="password"
              $width="100%"
              $padding="0.44rem 1rem"
              value={pwInfo.newPwd}
              _onChange={e => handleInputChange({ field: "newPwd", e })}
            />
          </td>
        </tr>
        <tr>
          <td>{t("checkPw")}</td>
          <td className="inputWrap">
            <TheInput
              type="password"
              $width="100%"
              $padding="0.44rem 1rem"
              value={pwInfo.checkPwd}
              _onChange={e => handleInputChange({ field: "checkPwd", e })}
            />
          </td>
        </tr>
      </TheTable>
      <div className="btnWrap">
        <TheButton
          $width="9.75rem"
          type="submit"
          disabled={
            pwInfo.currentPwd === "" ||
            pwInfo.newPwd === "" ||
            pwInfo.checkPwd === ""
          }
        >
          {t("edit")}
        </TheButton>
      </div>
    </TableContainer>
  );
};

const TableContainer = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  & .title {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2.125rem;
  }
  & tbody {
    & tr {
      & td {
        height: 3.75rem;
        padding: 0 1rem;
        text-align: left;
        font-size: 0.938rem;
        font-weight: 400;
        line-height: 1.25rem;
        letter-spacing: -0.025rem;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        vertical-align: middle;
      }

      & td:nth-child(odd) {
        color: ${({ theme: { color } }) => color.black[300]};
      }
    }
  }

  & .btnWrap {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 0.75rem;
  }

  & .inputWrap {
    display: flex;
    flex-direction: row;
    gap: 0.625rem;
    padding: 0.2rem 1rem;
    align-items: center;
  }

  & .changeInfoContainer {
    background-color: ${({ theme: { color } }) => color.gray[400]};
    display: flex;
    flex-direction: row;
    padding: 0 2rem;
    border-radius: 0.375rem;
    align-items: center;
    gap: 2rem;
    margin-bottom: 1rem;
  }

  & .imgWrap {
    width: 5rem;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    & img {
      width: 3.1875rem;
      height: 4.041875rem;
    }

    &
  }
  & .changeInfoContent {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    padding: 1.5rem 0;
    border-radius: 0.375rem;

    & p {
      margin-left: 0.875rem;
      font-size: 1rem;
      font-weight: 600;
      padding: 0 0 0.5rem 0;
    }

    & li {
      padding: 0.3125rem;
      color: ${({ theme: { color } }) => color.gray[800]};
    }
  }
`;

export default PwEditTable;
