import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import FindPwStepBox from "./FindPwStepBox";
import { TheBox } from "../../../components";

import useFormValidation from "../../../hooks/useFormValidation";
import { SET_ALERT_MSG } from "../shared/commonSlice";

const FindPwPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { type } = useParams();

  // const [loginType, setLoginType] = useState(
  //   type === "0" ? "Individual" : "Business",
  // );
  // const loginType = type === "0" ? "Individual" : "Business"; 이걸로 나중에 바까줘ㅗ요
  const loginType = type === "0" ? "Individual" : "Individual";
  const [userInfo, setUserInfo] = useState({
    id: "",
    telNum: "",
    regNum: "",
    newPw: "",
    newPwCheck: "",
  });

  // 유효성 훅
  const {
    validateEmail,
    isPwError,
    validatePassword,
    isPwCheckError,
    isPwCheckValid,
    validatePasswordCheck,
    isRegNumError,
    validateRegNum,
  } = useFormValidation();

  // const handleTypeSwitch = newType => {
  //   setLoginType(newType);
  //   const newTypeParam = newType === "Individual" ? "0" : "1";
  //   navigate(`/find/password/${newTypeParam}`);
  //   localStorage.setItem("accountType", newType);
  //   resetAllData();
  // };

  // const resetAllData = () => {
  //   setUserInfo({
  //     id: "",
  //     tel: "",
  //     regNum: "",
  //     newPw: "",
  //     newPwCheck: "",
  //   });
  //   setCurrentStep(0);
  // };

  const handleChange = e => {
    const { name, value } = e.target;
    setUserInfo(prevState => ({
      ...prevState,
      [name]: value,
    }));

    const validationFunctions = {
      newPw: validatePassword,
      newPwCheck: () => validatePasswordCheck(userInfo.newPw, value),
      regNum: validateRegNum,
    };

    if (validationFunctions[name]) {
      validationFunctions[name](value);
    }
  };

  const niceInputChange = tel => {
    setUserInfo(prevState => ({
      ...prevState,
      telNum: tel,
    }));
  };

  const successFind = () => {
    navigate("/find/id/success", {
      state: { passwordChanged: true },
    });
  };

  return (
    <TheBox $width="31.25rem" $height="max-contents" padding="2.25rem">
      <FindInputWrap>
        <FindPwStepBox
          userInfo={userInfo}
          handleChange={handleChange}
          handleTypeSwitch={() =>
            dispatch(
              SET_ALERT_MSG("3월 7일 정식 오픈일까지 \n잠시 기다려 주세요"),
            )
          }
          // handleTypeSwitch={handleTypeSwitch}
          isRegNumError={isRegNumError}
          validateEmail={validateEmail}
          isPwError={isPwError}
          isPwCheckError={isPwCheckError}
          isPwCheckValid={isPwCheckValid}
          successFind={successFind}
          type={type}
          loginType={loginType}
          niceInputChange={niceInputChange}
        />
      </FindInputWrap>
    </TheBox>
  );
};

const FindInputWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2.25rem;
  gap: 2rem;
  margin: 0 auto;
  white-space: pre-line;
`;

export default FindPwPage;
