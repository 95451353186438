import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import {
  TheAccordionTable,
  TheSearchBox,
  TheSection,
} from "../../../components";
import { getFaqListApi } from "../../../services/controller/faqController";

const FaQPage = () => {
  const { t } = useTranslation("faq");

  // 검색 셋팅
  const searchFilter = [
    { label: t("division"), value: "Category" },
    { label: t("subject"), value: "Title" },
    { label: t("contents"), value: "Content" },
  ];

  const [faqList, setFaqList] = useState([]);
  const [searchType, setSearchType] = useState("");
  const [searchWord, setSearchWord] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [pageCnt, setPageCnt] = useState(1);
  const [resultCnt, setResultCnt] = useState(0);
  const [expandedIdx, setExpandedIdx] = useState(null);

  const handleOptionSelect = option => {
    setSearchType(option.value);
  };

  const handleDetailClick = idx => {
    setExpandedIdx(prev => (prev === idx ? null : idx));
  };

  const getFaqList = async (page = 1) => {
    let list = [];
    let pageNationCnt = 0;
    let totalCnt = 0;

    try {
      const pageSize = 5;

      const { output } = await getFaqListApi({
        type: searchType,
        search: searchWord,
        pageNum: page,
        pageSize,
      });

      list = output?.data.map(faq => ({
        ...faq,
        idx: faq.FaqIdx, // FaqIdx를 idx로 변경 (아코디언 컴포넌트에서 사용하기 위함)
      }));

      pageNationCnt = output?.pageCnt;
      totalCnt = output?.totalCnt;
    } catch (error) {
      console.error(error);
    } finally {
      setActivePage(page);
      setPageCnt(pageNationCnt);
      setResultCnt(totalCnt);
      setFaqList(list);
    }
  };

  const handleSearch = async (page = 1) => {
    setActivePage(page);
    await getFaqList(page);
  };

  useEffect(() => {
    handleSearch(activePage);
  }, [activePage]);

  return (
    <TheSection title={t("title")}>
      <FaQContainer>
        <TheSearchBox
          $height="2.75rem"
          $inputWidth="49.5rem"
          onDate={false}
          searchFilter={searchFilter}
          onOptionSelect={handleOptionSelect}
          _searchWord={e => setSearchWord(e.value)}
          _search={() => handleSearch(1)}
        />
        <TheAccordionTable
          totalPages={pageCnt}
          showDataCnt={resultCnt}
          isNoData={!faqList.length}
          list={faqList}
          expandedIdx={expandedIdx}
          _handlePagination={e => setActivePage(e)}
          _handleDetailClick={handleDetailClick}
        />
      </FaQContainer>
    </TheSection>
  );
};

const FaQContainer = styled.div`
  display: grid;
  row-gap: 1.5rem;
  margin-top: 1.5rem;
`;

export default FaQPage;
