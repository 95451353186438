import { useState } from "react";

// 아이디 유효성 검사
const useIdValidation = () => {
  const [isIdValid, setIsIdValid] = useState(false);
  const [isIdError, setIsIdError] = useState(false);

  const validateId = id => {
    const regex = /^(?=.*[A-Za-z])[A-Za-z0-9]{4,15}$/;

    const valid = regex.test(id);
    setIsIdValid(valid);
    setIsIdError(!valid);

    return { valid, error: !valid }; // 바로 검사 결과 반환
  };

  return { isIdValid, isIdError, validateId };
};

// 이메일 유효성 검사
const useEmailValidation = () => {
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isEmailError, setIsEmailError] = useState(false);

  const validateEmail = email => {
    const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    const valid = regex.test(email);
    setIsEmailValid(valid);
    setIsEmailError(!valid);

    return { valid, error: !valid };
  };

  return { isEmailValid, isEmailError, validateEmail };
};

// 비밀번호 유효성 검사
const usePasswordValidation = () => {
  const [isPwError, setIsPwError] = useState(false);

  const validatePassword = password => {
    const regex =
      /^(?=(.*[A-Za-z]))(?=(.*\d))(?=(.*[\!\@\#\$\^\*\(\)\-\_\=\+\[\]\{\}\;\:\'\"\<\>\,\.\?\/\~\`\\]))[A-Za-z\d\!\@\#\$\^\*\(\)\-\_\=\+\[\]\{\}\;\:\'\"\<\>\,\.\?\/\~\`\\]{8,}$/; //eslint-disable-line no-useless-escape
    const valid = regex.test(password);
    setIsPwError(!valid);

    return { valid, error: !valid };
  };
  return { isPwError, validatePassword };
};

// 비밀번호 확인 유효성 검사
const usePasswordCheckValidation = () => {
  const [isPwCheckError, setIsPwCheckError] = useState(false);
  const [isPwCheckValid, setIsPwCheckValid] = useState(false);

  const validatePasswordCheck = (password, passwordCheck) => {
    const valid = password === passwordCheck;
    setIsPwCheckError(!valid);
    setIsPwCheckValid(valid);

    return { valid, error: !valid }; // 바로 검사 결과 반환
  };

  return { isPwCheckError, isPwCheckValid, validatePasswordCheck };
};

// 전화번호
const usePhoneValidation = () => {
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [isPhoneError, setIsPhoneError] = useState(false);

  const validatePhone = phone => {
    const regex = /^\d{11}$/;
    const valid = regex.test(phone);

    setIsPhoneValid(valid);
    setIsPhoneError(!valid);

    return { valid, error: !valid }; // 유효성 결과를 바로 반환
  };

  return { isPhoneValid, isPhoneError, validatePhone };
};

// 사업자번호
const useRegNumValidation = () => {
  const [isRegNumValid, setIsRegNumValid] = useState(false);
  const [isRegNumError, setIsRegNumError] = useState(false);

  const validateRegNum = regNum => {
    const regex = /^\d{3}-\d{2}-\d{5}$/;
    const valid = regex.test(regNum);
    setIsRegNumValid(valid);
    setIsRegNumError(!valid);

    return { valid, error: !valid }; // 바로 검사 결과 반환
  };

  return { isRegNumValid, isRegNumError, validateRegNum };
};

const useFormValidation = () => {
  const { isIdValid, isIdError, validateId } = useIdValidation(); // 아이디 유효성 검사 추가
  const { isEmailValid, isEmailError, validateEmail } = useEmailValidation();
  const { isPwError, validatePassword } = usePasswordValidation();
  const { isPwCheckError, isPwCheckValid, validatePasswordCheck } =
    usePasswordCheckValidation();
  const { isPhoneValid, isPhoneError, validatePhone } = usePhoneValidation();
  const { isRegNumValid, isRegNumError, validateRegNum } =
    useRegNumValidation();

  return {
    isIdValid,
    isIdError,
    validateId,
    isEmailValid,
    isEmailError,
    validateEmail,
    isPwError,
    validatePassword,
    isPwCheckError,
    isPwCheckValid,
    validatePasswordCheck,
    isPhoneValid,
    isPhoneError,
    validatePhone,
    isRegNumValid,
    isRegNumError,
    validateRegNum,
  };
};

export default useFormValidation;
