import React, { useMemo, useState } from "react";
import styled from "styled-components";

import VideoStartBtn from "../../../assets/images/videoStartBtn.png";
import ErrorVideoMain from "../../../assets/images/error_video_main.png";
import { ReactComponent as TitleLogo } from "../../../assets/svg/titleLogo.svg";
import { ReactComponent as SixWsCopy } from "../../../assets/svg/sixWsTitle.svg";

const SixWsContainer = ({ t }) => {
  const [playingIndex, setPlayingIndex] = useState(null);
  const [videoError, setVideoError] = useState({});
  const [imageError, setImageError] = useState({});

  const sixWs = [
    {
      videoIndex: 0,
      cardTitle: t("cardTitle-0"),
      card: t("card-0"),
      cardContent: t("cardContent-0"),
    },
    {
      videoIndex: 1,
      cardTitle: t("cardTitle-1"),
      card: t("card-1"),
      cardContent: t("cardContent-1"),
    },
    {
      videoIndex: 2,
      cardTitle: t("cardTitle-2"),
      card: t("card-2"),
      cardContent: t("cardContent-2"),
    },
    {
      videoIndex: 3,
      cardTitle: t("cardTitle-3"),
      card: t("card-3"),
      cardContent: t("cardContent-3"),
    },
    {
      videoIndex: 4,
      cardTitle: t("cardTitle-4"),
      card: t("card-4"),
      cardContent: t("cardContent-4"),
    },
    {
      videoIndex: 5,
      cardTitle: t("cardTitle-5"),
      card: t("card-5"),
      cardContent: t("cardContent-5"),
    },
  ];

  const isAssetsUrl = path => {
    const protocol = window.location.protocol;
    const hostName = window.location.hostname;

    return process.env.REACT_APP_ASSETS_URL
      ? `${process.env.REACT_APP_ASSETS_URL}/${path}`
      : `${protocol}//${hostName}/tosassets/${path}`;
  };

  const getVideoSrc = useMemo(() => {
    return videoIndex => {
      return isAssetsUrl(`video/6w_${videoIndex}.mp4`);
    };
  }, []);

  const getImgSrc = useMemo(() => {
    return videoIndex => {
      return isAssetsUrl(`image/mainPop${videoIndex}.png`);
    };
  }, []);

  // 2개씩 묶어서 100vh 차지하도록
  const groupedItems = [];
  for (let i = 0; i < sixWs.length; i += 2) {
    groupedItems.push(sixWs.slice(i, i + 2)); // 두 항목씩 묶기
  }

  const handleClick = videoIndex => {
    if (playingIndex === videoIndex) {
      setPlayingIndex(null);
    } else {
      setPlayingIndex(videoIndex);
    }
  };

  // 비디오 에러 처리
  const handleVideoError = videoIndex => {
    setVideoError(prevState => ({
      ...prevState,
      [videoIndex]: true,
    }));
  };

  // 이미지 에러 처리
  const handleImageError = videoIndex => {
    setImageError(prevState => ({
      ...prevState,
      [videoIndex]: true,
    }));
  };

  return (
    <SectionEl>
      {groupedItems.map((group, index) => (
        <SixSectionWrap key={index}>
          <div className={index !== 1 ? "grayBackground" : ""}>
            <SixSectionEl id={`section-${index + 1}`}>
              <div className="innerContainer">
                {index === 0 && (
                  <ScrollTitleWrap>
                    <SixWsCopy />
                  </ScrollTitleWrap>
                )}
                {group.map((item, i) => (
                  <div
                    key={i}
                    className={`infoBox ${i % 2 !== 0 ? "reverse" : ""}`}
                  >
                    <TextWrap>
                      <div className="cardTitle">
                        <TitleLogo /> {item.cardTitle}
                      </div>
                      <div className="cardText">{item.card}</div>
                      <div className="cardContent">{item.cardContent}</div>
                    </TextWrap>
                    <ImgWrap>
                      {playingIndex === item.videoIndex ? (
                        <video
                          src={getVideoSrc(item.videoIndex)}
                          autoPlay
                          loop
                          muted
                          playsInline
                          className="background-video"
                          onError={() => handleVideoError(item.videoIndex)}
                        >
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <div
                          className="videoWrap"
                          onClick={() => handleClick(item.videoIndex)}
                        >
                          {videoError[item.videoIndex] ? (
                            <img
                              src={ErrorVideoMain}
                              alt="error"
                              className="thumbnail"
                            />
                          ) : (
                            <img
                              src={
                                imageError[item.videoIndex]
                                  ? ErrorVideoMain
                                  : getImgSrc(item.videoIndex)
                              }
                              alt="thumbnail"
                              className="thumbnail"
                              onError={() => handleImageError(item.videoIndex)}
                            />
                          )}
                          {!imageError[item.videoIndex] && (
                            <div className="startBtn">
                              <img src={VideoStartBtn} alt="startBtn" />
                            </div>
                          )}
                        </div>
                      )}
                    </ImgWrap>
                  </div>
                ))}
              </div>
            </SixSectionEl>
          </div>
        </SixSectionWrap>
      ))}
    </SectionEl>
  );
};

const SectionEl = styled.section`
  width: 100%;
  height: 100%;
  padding: calc(5rem + 3rem) 2.5rem 3rem;
  background-color: ${({ theme: { color } }) => color.white[400]};
`;

const SixSectionWrap = styled.div`
  width: 100%;
`;

const SixSectionEl = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  min-width: 20.5rem;

  & .innerContainer {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    width: 87.5rem;
  }

  & .infoBox {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 18.75rem;
    justify-content: center;
    align-items: center;
    white-space: pre-line;
    margin: 0 auto;
  }

  & .infoBox.reverse {
    flex-direction: row-reverse;
  }
`;

const ScrollTitleWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  & svg {
    width: 52.875rem;
    height: 3.8125rem;
    fill: ${({ theme: { color } }) => color.black[400]};
  }
`;

const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 37.375rem;
  height: 100%;
  padding: 3rem;
  gap: 1rem;

  & .cardTitle {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.491875rem;
    color: ${({ theme: { color } }) => color.blue[400]};

    & svg {
      width: 9.821875rem;
      height: 1.1875rem;
      fill: ${({ theme: { color } }) => color.blue[400]};
    }
  }

  & .cardText {
    font-weight: 600;
    font-size: 1.875rem;
    line-height: 2.386875rem;
    color: ${({ theme: { color } }) => color.blue[800]};
  }

  & .cardContent {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
  }
`;

const ImgWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 31.25rem;
  height: 100%;
  /*padding: 0 3rem;*/
  border-radius: 0.5rem;
  background-color: black;
  margin: 0 3rem;

  & .videoWrap {
    width: 100%;
    height: 100%;
    position: relative;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0.5rem;
      background-size: cover;
    }

    & .startBtn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: none;
      padding: 1rem 2rem;
      color: white;
      font-size: 1.5rem;
      cursor: pointer;

      & img {
        width: 3.75rem;
        height: 3.75rem;
      }
    }
  }

  & video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.5rem;
    background-size: cover;
  }
`;

export default SixWsContainer;
