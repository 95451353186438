import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { TheSearchBox, TheSection, TheTable } from "../../../components";
import { useNavigate } from "react-router-dom";

import { getNoticeListApi } from "../../../services/controller/noticeController";

const NoticePage = () => {
  const { t } = useTranslation("notice");
  const navigate = useNavigate();

  // 검색 셋팅
  const searchFilter = [
    {
      label: t("division"),
      value: "category",
    },
    {
      label: t("subject"),
      value: "title",
    },
    {
      label: t("contents"),
      value: "content",
    },
  ];

  // 테이블 셋팅
  const columns = [
    "No",
    t("division"),
    t("subject"),
    t("author"),
    t("creatDate"),
    t("views"),
  ];
  const columnSize = ["7.9%", "7.9%", "49.3%", "11.6%", "11.6%", "11.6%"];

  const [noticeList, setNoticeList] = useState([{}]);
  const [searchType, setSearchType] = useState("");
  const [searchWord, setSearchWord] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [pageCnt, setPageCnt] = useState(10);
  const [resultCnt, setResultCnt] = useState(1);
  const [dateRange, setDateRange] = useState(["", ""]);

  const handleOptionSelect = option => {
    setSearchType(option.value);
  };

  const handleDetailClick = notice => {
    navigate(`/notice/${notice.NoticeIdx}`, { state: notice });
  };

  const getNoticeList = async (page = 1) => {
    const getDate = today => {
      if (!today) return "";

      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, "0");
      const day = today.getDate().toString().padStart(2, "0");

      return `${year}-${month}-${day}`;
    };

    let list = [];
    let pageNationCnt = 0;
    let totalCnt = 0;

    try {
      const pageSize = 10;

      const data = await getNoticeListApi({
        type: searchType,
        search: searchWord,
        pageNum: page,
        pageSize,
        startDate: `${getDate(dateRange[0])}${dateRange[0] ? "T00:00:00" : ""}`,
        endDate: `${getDate(dateRange[1])}${dateRange[1] ? "T23:59:59" : ""}`,
      });

      if (data.result === true && data.status === "0000") {
        list = data.output.data;
        pageNationCnt = Math.ceil(Number(data.output.totalCnt) / pageSize); // 화면에 그릴 페이지네이션 수
        totalCnt = data.output.totalCnt; // 결과에 해당하는 총 데이터 수
      }
    } catch (error) {
      console.error(error);
    } finally {
      setActivePage(page);
      setPageCnt(pageNationCnt); // 화면에 그릴 페이지네이션 수
      setResultCnt(totalCnt); // 결과에 해당하는 총 데이터 수
      setNoticeList(list);
    }
  };

  const handleSearch = async (page = 1) => {
    setActivePage(page);
    await getNoticeList(page);
  };

  useEffect(() => {
    // 노티스 리스트 호출 api
    handleSearch(activePage);
  }, [activePage]);

  return (
    <TheSection title={t("title")}>
      <NoticeContainer>
        <TheSearchBox
          $height="2.75rem"
          $inputWidth="38.563rem"
          searchFilter={searchFilter}
          onOptionSelect={handleOptionSelect}
          _handleDate={e => setDateRange(e)}
          _searchWord={e => setSearchWord(e.value)}
          _search={() => handleSearch(1)}
        />
        <TheTable
          className="noticeTable"
          colgroupList={columnSize}
          theadList={columns}
          totalPages={pageCnt}
          showDataCnt={resultCnt}
          isNoData={!noticeList?.length}
          _handlePagination={e => setActivePage(e)}
        >
          {noticeList?.map((notice, index) => (
            <tr className="noticeTr" key={index}>
              {notice.isFixed ? (
                <td className="importTagTd">
                  <p className="importTag">중요</p>
                </td>
              ) : (
                <td>{notice.NoticeIdx}</td>
              )}
              <td>{notice.Category}</td>
              <td
                className={notice.isFixed ? "subject" : ""}
                onClick={() => handleDetailClick(notice)}
                style={{
                  textAlign: "left",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {notice.Title}
              </td>
              <td>{notice.Author ?? "관리자자"}</td>
              <td>{notice.CreatedDate?.replace(/T.*$/, "")}</td>
              <td>{notice.viewCount}</td>
            </tr>
          ))}
        </TheTable>
      </NoticeContainer>
    </TheSection>
  );
};

const NoticeContainer = styled.div`
  display: grid;
  row-gap: 1.5rem;
  margin-top: 1.5rem;

  & tbody {
    & tr {
      height: 4rem;
    }
    & td {
      color: ${({ theme: { color } }) => color.gray[950]};
      font-weight: 400;
    }
    & tr:hover > td {
      color: ${({ theme: { color } }) => color.blue[300]} !important;
    }

    & .noticeTr {
      & .importTagTd {
        padding: 0;
        display: flex;
        justify-content: center;
      }

      & .importTag {
        width: 2.5rem;
        height: 1.25rem;
        border-radius: 0.375rem;
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 1.25rem;
        letter-spacing: -0.025rem;
        text-align: center;
        color: ${({ theme: { color } }) => color.white[400]};
        background-color: ${({ theme: { color } }) => color.black[900]};
      }

      & .subject {
        color: ${({ theme: { color } }) => color.black[400]};
        font-weight: 600;
      }
    }
  }
`;

export default NoticePage;
