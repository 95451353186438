import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as CloseBtn } from "../../assets/svg/closeBtn.svg";
import carouselLeft from "../../assets/images/carouselLeft.png";
import carouselRight from "../../assets/images/carouselRight.png";

const TheCarouselModal = ({
  children,
  width,
  height,
  _closeModal,
  activeSlide = 0,
  onNextSlide,
  onPrevSlide,
  title = "",
  $margin = "0 0",
  $padding = "0.75rem 2.5rem",
  $width = "30rem",
  $height = "30rem",
  $fontSize = "0.938rem",
  $fontWeight = 600,
  $style = "default",
}) => {
  const styles = {
    $margin: $margin,
    $padding: $padding,
    $width: $width,
    $height: $height,
    $fontSize: $fontSize,
    $fontWeight: $fontWeight,
    $style: $style,
  };

  const [currentSlide, setCurrentSlide] = useState(activeSlide);

  useEffect(() => {
    setCurrentSlide(activeSlide);
  }, [activeSlide]);

  const handleNextSlide = () => {
    const nextSlide =
      currentSlide + 1 < React.Children.count(children) ? currentSlide + 1 : 0;
    setCurrentSlide(nextSlide);
    if (onNextSlide) onNextSlide(nextSlide);
  };

  const handlePrevSlide = () => {
    const prevSlide =
      currentSlide - 1 >= 0
        ? currentSlide - 1
        : React.Children.count(children) - 1;
    setCurrentSlide(prevSlide);
    if (onPrevSlide) onPrevSlide(prevSlide);
  };

  return (
    <ModalBg>
      <Modal {...styles} width={width} height={height}>
        <div className="modalHeader">
          <h2>{title}</h2>
          <div className="closeIconWrap" onClick={_closeModal}>
            <CloseBtn />
          </div>
        </div>
        <CarouselContainer>
          <CarouselSlideContainer $currentSlide={currentSlide}>
            {React.Children.toArray(children)?.map((child, index) => (
              <CarouselSlide key={index}>{child}</CarouselSlide>
            ))}
          </CarouselSlideContainer>
          <CarouselControls>
            <ArrowButton onClick={handlePrevSlide} $left={true} />
            <ArrowButton onClick={handleNextSlide} $right={true} />
          </CarouselControls>
        </CarouselContainer>
      </Modal>
    </ModalBg>
  );
};
const ModalBg = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.65);
  opacity: 1;
  animation: fadein 0.3s;
  z-index: 400;

  @keyframes fadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const Modal = styled.div`
  position: relative;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  animation: scaleUp 0.3s;
  overflow: hidden;
  border-radius: 1.25rem;

  @keyframes scaleUp {
    0% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }

  & .modalHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 3.75rem;
    padding: 1rem;
    background-color: rgba(59, 77, 211, 1);
    border-top-left-radius: ${({ $radius }) => $radius};
    border-top-right-radius: ${({ $radius }) => $radius};
  }

  & h2 {
    font-size: 1.5rem;
    color: ${({ theme: { color } }) => color.white[400]};
    text-align: center;
    flex: 1;
    line-height: 2.125rem;
    font-weight: 600;
    padding-left: 1.75rem;
  }
  & .closeIconWrap {
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      transform: scale(1.2);
    }
  }

  @keyframes rotateCloseIcon {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(180deg);
    }
  }
`;

const CarouselContainer = styled.div`
  position: relative;
  width: ${({ width }) => width};
  height: calc(100% - 3.75rem);
  /*padding: 0 3rem 0 3rem;*/
  padding-left: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: white;
  border-bottom-left-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
`;

const CarouselSlideContainer = styled.div`
  display: flex;
  transition: transform 0.3s ease-in-out;
  transform: ${({ $currentSlide }) => `translateX(-${$currentSlide * 100}%)`};
  width: 100%;
  gap: 3rem;
`;

const CarouselSlide = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease-in-out;
`;

const CarouselControls = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
`;

const ArrowButton = styled.button`
  position: absolute;
  top: 50%;
  z-index: 1;
  width: 3rem;
  height: 3rem;
  font-size: 2rem;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  padding: 1rem;
  transform: translateY(-50%);
  background-repeat: no-repeat;
  background-position: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }

  ${({ $left }) =>
    $left
      ? `left: 0; background-image: url(${carouselLeft});`
      : `right: 0; background-image: url(${carouselRight});`}
`;
export default TheCarouselModal;
