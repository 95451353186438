import React from "react";
import styled from "styled-components";

/**
 * alert, confirm 모달
 * @example
 * // 사용 예시
 * <TheModal
 *   type="alert || confirm"
 *   isBorderLine = false // 테두리 선, 그림자 삭제
 *   _close={() => console.log('modalClose')}
 *   $width = "20rem",
 *   $padding = "1.25rem 1.5rem",
 * />
 * */
const TheBox = ({
  children,
  className,
  isBorderLine = true,
  $width = "100%",
  $height = "100%",
  $padding = "3rem 2.25rem",
  $margin = "0",
  $border = "1.25rem",
}) => {
  const styles = {
    $width: $width,
    $height: $height,
    $padding: $padding,
    $margin: $margin,
    $border: $border,
  };

  return (
    <BoxEl isBorderLine={isBorderLine} className={className} {...styles}>
      {children}
    </BoxEl>
  );
};
const BoxEl = styled.article.withConfig({
  shouldForwardProp: prop => !["isBorderLine"].includes(prop),
})`
  border-radius: ${({ $border }) => $border};
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  padding: ${({ $padding }) => $padding};
  margin: ${({ $margin }) => $margin};
  background-color: ${({ theme: { color } }) => color.white[400]};

  ${({ isBorderLine }) =>
    isBorderLine &&
    `box-shadow: 0 1rem 1rem 0 ${({ theme: { color } }) => color.shadow[500]};
     border: 0.062rem solid #E5E5E5;
    `}
`;

export default TheBox;
