import axios from "axios";
import { setInterceptors } from "./interceptors.js";

function createInstance() {
  const { protocol, hostname } = window.location;

  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
      ? `${process.env.REACT_APP_API_URL}`
      : `${protocol}//${hostname}/tosapi`,
  });
  return setInterceptors(instance);
}

export const api = createInstance();
