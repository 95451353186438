import { api } from "../index";

export const checkCouponCodeApi = async data => {
  try {
    const response = await api.post("/api/v1/vouchers/new", data);

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getCouponListApi = async data => {
  try {
    const response = await api.post("/api/v1/vouchers/list", data);

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const voucherPaymentApi = async data => {
  try {
    const response = await api.post("/api/v1/payments/vouchers\n", data);

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
