export const fontSize = {
  "1px": "0.062rem",
  "2px": "0.125rem",
  "3px": "0.188rem",
  "4px": "0.250rem",
  "5px": "0.312rem",
  "6px": "0.375rem",
  "7px": "0.438rem",
  "8px": "0.500rem",
  "9px": "0.562rem",
  "10px": "0.625rem",
  "11px": "0.688rem",
  "12px": "0.750rem",
  "13px": "0.812rem",
  "14px": "0.875rem",
  "15px": "0.938rem",
  "16px": "1.000rem",
  "17px": "1.062rem",
  "18px": "1.125rem",
  "19px": "1.188rem",
  "20px": "1.250rem",
  "21px": "1.312rem",
  "22px": "1.375rem",
  "23px": "1.438rem",
  "24px": "1.500rem",
  "25px": "1.562rem",
  "26px": "1.625rem",
  "27px": "1.688rem",
  "28px": "1.750rem",
  "29px": "1.812rem",
  "30px": "1.875rem",
  "31px": "1.938rem",
  "32px": "2.000rem",
  "33px": "2.062rem",
  "34px": "2.125rem",
  "35px": "2.188rem",
  "36px": "2.250rem",
  "37px": "2.312rem",
  "38px": "2.375rem",
  "39px": "2.438rem",
  "40px": "2.500rem",
  "41px": "2.562rem",
  "42px": "2.625rem",
  "43px": "2.688rem",
  "44px": "2.750rem",
  "45px": "2.812rem",
  "46px": "2.875rem",
  "47px": "2.938rem",
  "48px": "3.000rem",
};
