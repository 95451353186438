import { TheModal } from "../../../components";
import React from "react";
import styled from "styled-components";

const UserConsentModal = ({
  type = "confirm",
  selectAuth,
  authList,
  src,
  _justClose,
  _acceptClose,
}) => {
  const handleJustClose = value => {
    _justClose && _justClose(value);
  };
  const handleAcceptClose = value => {
    _acceptClose && _acceptClose(value);
  };

  /*
  @authList 예시
    authList = {
      privacyTermsAgreed: {
        title: "제목",
        content: "내용",
      },
    };
  */

  return (
    <TheModal
      title={authList[selectAuth]?.title}
      type={type}
      $width="55rem"
      // $width="38rem"
      $padding="1.25rem 0.875rem"
      _justClose={handleJustClose}
      _acceptClose={handleAcceptClose}
    >
      <AuthWrapEl>
        <iframe
          src={src}
          width="100%"
          height="500px"
          style={{ border: "none" }}
          sandbox="allow-scripts allow-same-origin"
        >
          {/*{authList[selectAuth]?.content}*/}
        </iframe>
      </AuthWrapEl>
    </TheModal>
  );
};

const AuthWrapEl = styled.div`
  text-align: left;
  /*max-height: 31.25rem;*/
  padding: 0.75rem 0.875rem 0.5rem calc(2rem - 0.375rem);
  /*overflow-y: scroll;*/
  white-space: pre-line;

  /* ScrollBar Custom */
  &::-webkit-scrollbar {
    width: 0.375rem;
    /*height: 0.5rem;*/
  }
  &::-webkit-scrollbar-thumb {
    background: #cacad7;
    border-radius: 0.625rem;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
`;

export default UserConsentModal;
