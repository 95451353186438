import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { TheSection, TheTab } from "../../../components";

const MyPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation("mypage");

  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      id: "userinfoedit",
      label: t("tab1"),
      content: "",
    },
    {
      id: "pwedit",
      label: t("tab2"),
      content: "",
    },
    {
      id: "usagedetail",
      label: t("tab3"),
      content: "",
    },
  ];

  const handleTabChange = index => {
    setActiveTab(index);
    navigate(`/mypage/${tabs[index].id}`);
  };

  useEffect(() => {
    const activeIndex = tabs.findIndex(tab =>
      location.pathname.includes(tab.id),
    );
    if (activeIndex >= 0 && activeIndex !== activeTab) {
      setActiveTab(activeIndex);
    }
  }, [location.pathname, tabs, activeTab]);

  return (
    <TheSection title={t("title")}>
      <Container>
        <TheTab
          tabs={tabs}
          onTabChange={handleTabChange}
          activeTab={activeTab}
          $width="31.25rem"
          $contentMinHeight="2.75rem"
          $backgroundColor="none"
          contentUsed={false}
        />
        <Outlet />
      </Container>
    </TheSection>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 1.5rem 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export default MyPage;
