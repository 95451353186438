import React from "react";
import styled from "styled-components";

import { TheButton, TheCheckBox } from "../../components";

import { ReactComponent as CloseIcon } from "../../assets/svg/closeIcon.svg";

import {
  generateUUID,
  getUUID,
  setCookie,
  deleteCookie,
} from "../../utils/custom/setCookie";

/**
 * alert, confirm 모달
 * @example
 * // 사용 예시
 * <TheModal
 *   type="alert || confirm"
 *   _close={() => console.log('modalClose')}
 *   $width = "20rem",
 *   $padding = "1.25rem 1.5rem",
 *   useLoading = false, // useLoading에 isLoading값 넣어주면 버튼 동글동글 돌아감
 * />
 * */
const TheModal = ({
  children,
  type = "alert",
  title = "",
  content = "",
  useLoading = false,
  showAllocateBtn = true,
  showStopWatchingTodayBtn = false,
  lUUIDKey = "mainPopup",
  _justClose,
  _acceptClose,
  $width = "20rem",
  $height = "max-content",
  $padding = "1.25rem 1.5rem",
  $backgroundImg = false,
}) => {
  const styles = {
    $width: $width,
    $height: $height,
    $padding: $padding,
    $backgroundImg: $backgroundImg,
  };

  const handleClose = () => {
    _justClose && _justClose();
  };
  const handleAcceptClose = () => {
    _acceptClose && _acceptClose();
  };

  const handleCheckbox = isChecked => {
    // 1 로컬스토리지에 저장된 uuid 가져오기
    let popupUUID = getUUID(lUUIDKey);
    // 저장된 uuid 없을 경우 생성
    if (!popupUUID) {
      generateUUID(lUUIDKey);
    }
    // 저장된 uuid 꺼내오기
    popupUUID = getUUID(lUUIDKey);
    // uuid가 존재할 경우 checked 값에 따라 쿠키 세팅/ 삭제 로직 진행
    switch (isChecked) {
      case true: // true일 경우 쿠키 세팅
        setCookie(lUUIDKey, popupUUID, 1);
        return;
      default: // false일 경우 쿠키 삭제
        deleteCookie(lUUIDKey);
        return;
    }
  };

  const loadingBtn = () => {
    return (
      <Loading>
        <div></div>
      </Loading>
    );
  };

  return (
    <SectionEl>
      <ArticleEl {...styles}>
        <ul>
          <TitleWrap>
            <CloseBtnEl onClick={() => handleClose()}>
              <CloseIcon />
            </CloseBtnEl>
          </TitleWrap>
          <li>
            <ModalTitle>{title}</ModalTitle>
            <ModalContent>{content}</ModalContent>
          </li>
          <li>{children}</li>
          <li
            style={{
              padding: "1.5rem 0 0.75rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            {type === "confirm" && (
              <TheButton
                $style="popupCancel"
                $width="6.25rem"
                _onClick={() => handleClose()}
              >
                취소
              </TheButton>
            )}
            {showAllocateBtn && (
              <TheButton
                $style="popupDefault"
                $width="6.25rem"
                _onClick={() => handleAcceptClose()}
              >
                {useLoading ? loadingBtn() : "확인"}
              </TheButton>
            )}
          </li>
          {showStopWatchingTodayBtn && (
            <StopWatchingTodayWrap {...styles}>
              <ul>
                <li>
                  <TheCheckBox
                    id="remember"
                    label="오늘 하루 보지 않기"
                    $color="#111111"
                    $checkColor="#111111"
                    _onChange={e => handleCheckbox(e.target.checked)}
                    $size="1.125rem"
                  />
                </li>
                <li>
                  <button onClick={() => handleClose()}>닫기</button>
                </li>
              </ul>
            </StopWatchingTodayWrap>
          )}
        </ul>
      </ArticleEl>
    </SectionEl>
  );
};

const SectionEl = styled.section`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${({ theme: { color } }) => color.shadow[46]};
  z-index: 101;
`;
const ArticleEl = styled.article`
  padding: ${({ $padding }) => $padding};
  min-width: 20rem;
  width: 100%;
  height: ${({ $height }) => $height};
  max-width: ${({ $width }) => $width};
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 1.25rem;
  transform: translate(-50%, -50%);
  background-color: ${({ theme: { color } }) => color.white[400]};
  background-image: ${({ $backgroundImg }) =>
    $backgroundImg ? `url(${$backgroundImg})` : "none"};
  box-shadow: 1rem 1rem 1.875rem 0
    ${({ theme: { color } }) => color.shadow[600]};
  text-align: center;
  overflow: hidden;
`;
const TitleWrap = styled.div`
  position: relative;
  min-height: 0.5rem;
  margin-bottom: 1.25rem;
`;

const ModalContent = styled.div`
  margin-top: 0.5rem;
  text-align: center;
  white-space: pre-line;
  line-height: 1.5rem;
`;
const ModalTitle = styled.strong`
  font-size: 1.438rem;
  font-weight: 600;
  line-height: 2.125rem;
  letter-spacing: -0.025em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  white-space: pre-line;
`;
const CloseBtnEl = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  border: none;
`;

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;

  div {
    border: 0.125rem solid #f3f3f3;
    border-top: 0.25rem solid gray;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const StopWatchingTodayWrap = styled.li`
  position: absolute;
  width: ${({ $width }) => $width};
  height: calc(39px + 1rem);
  min-height: max-content;
  bottom: 0;
  left: 0;
  padding: 0 2rem 0 1.5rem;
  background-color: #e6fceb;
  font-size: 0.85rem;

  & ul {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & button {
      font-family: "Pretendard";
      font-size: 0.85rem;
      background-color: transparent;
      border: none;
    }
  }
`;

export default TheModal;
