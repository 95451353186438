import React from "react";

import { ReactComponent as WarningIcon } from "../../../assets/svg/warning.svg";
import styled from "styled-components";
import { TheButton, TheSection } from "../../../components";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NotFoundPage = () => {
  const { t } = useTranslation("notFound");
  const navigate = useNavigate();
  return (
    <TheSection>
      <Container>
        <WarningIcon />
        <div>
          <strong>{t("title")}</strong>
          <p>{t("desc")}</p>
        </div>
        <TheButton $width="9.75rem" _onClick={() => navigate("/home")}>
          {t("navigate")}
        </TheButton>
      </Container>
    </TheSection>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1.5rem;
  width: 100%;
  height: 100%;
  padding: 1rem 1rem;
  & > svg {
    width: 3.75rem;
    height: 3.75rem;
  }

  & > div {
    text-align: center;
    & > strong {
      display: block;
      font-size: 2rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
      line-height: 2.387rem;
    }
    & > p {
      line-height: 1.193rem;
      white-space: pre-wrap;
    }
  }
`;

export default NotFoundPage;
