import React from "react";
import styled from "styled-components";

/**
 * 라디도 박스
 * @example
 * // 사용 예시
 * <TheRadioBox
 *    id="아이디"
 *    label={"보여질 라벨"}
 *    checked={선택여부 true 시 체크}
 *    _onChange={선택 변경 처리}
 *    $checkColor={"선택된 상태의 박스 내부 색"}
 *    $size="1.125rem"
 * />
 * */
const TheRadioBox = ({
  id,
  label,
  checked,
  _onChange,
  disabled = false,
  $width = "100%",
  $margin = "0 0",
  $padding = "1rem 0 0 1rem",
  $size = "1.125rem",
  $color,
  $checkColor,
  $fontWeight = "400",
}) => {
  const handleChange = event => {
    _onChange && _onChange(event);
  };
  const styles = {
    $margin: $margin,
    $padding: $padding,
    $width: $width, // 라벨 너비
    $size: $size, // radio 버튼 가로 세로 크기
    $color: $color, // 라벨색
    $checkColor: $checkColor, // 체크된 상태 색
    $fontWeight: $fontWeight,
  };

  return (
    <RadioWrapper>
      <RadioStyle
        type="radio"
        id={id}
        name={id}
        checked={checked}
        onChange={handleChange}
        disabled={disabled}
        {...styles}
      />
      <StyledLabel htmlFor={id} {...styles}>
        {label}
      </StyledLabel>
    </RadioWrapper>
  );
};

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const RadioStyle = styled.input`
  appearance: none;
  width: ${({ $size }) => $size};
  height: ${({ $size }) => $size};
  border-radius: 50%;
  box-shadow: 0 0 0 0.063rem
    ${({ theme: { color } }) => color.checkbox.defaultBorder};
  border: 0.25rem solid white;
  background-color: ${({ theme: { color } }) => color.checkbox.defaultBorder};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  box-sizing: border-box;

  // 체크된 상태
  &:checked {
    box-shadow: 0 0 0 0.063rem
      ${({ $checkColor, theme: { color } }) => $checkColor || color.black[900]};
    border: 0.25rem solid white;
    background-color: ${({ $checkColor, theme: { color } }) =>
      $checkColor || color.black[900]};
  }

  // 체크된 상태에서 비활성화
  &:checked:disabled {
    background-color: ${({ theme: { color } }) => color.checkbox.defaultBorder};
    cursor: not-allowed;
  }

  // 체크되지 않은 상태에서 비활성화
  &:disabled {
    box-shadow: 0 0 0 0.063rem
      ${({ theme: { color } }) => color.checkbox.disabledBackground};
    border: 0.25rem solid
      ${({ theme: { color } }) => color.checkbox.disabledBackground};
    background-color: white;
    cursor: not-allowed;
  }
`;

const StyledLabel = styled.label`
  color: ${({ $color, theme: { color } }) => $color || color.black[900]};
  font-weight: ${({ $fontWeight }) => $fontWeight};
  font-size: 0.875rem;
  line-height: 1.25rem;
  max-width: ${({ $width }) => $width};
  letter-spacing: -0.021875rem;
`;

export default TheRadioBox;
