import { createSlice } from "@reduxjs/toolkit";

// initialState
const initialState = {
  isLoading: false,
  darkMode: false,
  alertMsg: "",
  errorMsg: "",
  sessionExpiration: {
    show: false,
    content: "",
  },
};

const commonSlice = createSlice({
  name: "commonSlice",
  initialState,
  reducers: {
    SET_LOADING: (state, action) => {
      state.isLoading = action.payload;
    },
    SET_DARK_MODE: state => {
      if (state.darkMode) {
        localStorage.removeItem("darkMode");
        state.darkMode = false;
      } else {
        localStorage.setItem("darkMode", "On");
        state.darkMode = true;
      }
    },
    SET_ALERT_MSG: (state, action) => {
      state.alertMsg = action.payload;
    },
    DELETE_ALERT_MSG: state => {
      state.alertMsg = "";
    },
    SET_ERROR: (state, action) => {
      state.errorMsg = action.payload || "API 호출 중 오류 발생";
      state.alertMsg = action.payload || "API 호출 중 오류 발생";
    },
    SET_CLEAR_ERROR: state => {
      state.errorMsg = "";
    },
    SET_SESSION_EXPIRED: (state, action) => {
      state.sessionExpiration = action.payload;
    },
  },
  extraReducers: () => {},
});

export const {
  SET_LOADING,
  SET_ALERT_MSG,
  DELETE_ALERT_MSG,
  SET_ERROR,
  SET_CLEAR_ERROR,
  SET_SESSION_EXPIRED,
} = commonSlice.actions;
export default commonSlice.reducer;
