import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { TheInput, TheButton } from "../../../components";
import {
  getNiceInfoApi,
  getNiceModuleApi,
} from "../../../services/controller/commonController";
import { SET_ALERT_MSG } from "../shared/commonSlice";
import { useDispatch } from "react-redux";
import { getFindIdApi } from "../../../services/controller/findController";

const FindIdStepBox = ({
  currentStep,
  userInfo,
  setUserInfo,
  // isRegNumError,
  validateRegNum,
  onNiceAuth,
  goFindPw,
  type,
}) => {
  const { t } = useTranslation("find");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [reqNo, setReqNo] = useState("");
  const [findedId, setFindedId] = useState("");

  const handleInputChange = ({ value, field }) => {
    setUserInfo(prevState => ({
      ...prevState,
      [field]: value,
    }));

    const validationFunctions = {
      manager: validateRegNum,
      regNum: validateRegNum,
    };
    if (validationFunctions[field]) {
      validationFunctions[field](value);
    }
  };

  const getNiceModule = async () => {
    try {
      setIsLoading(true);
      const left = screen.width / 2 - 500 / 2;
      const top = screen.height / 2 - 800 / 2;
      const option = `status=no, menubar=no, toolbar=no, resizable=no, width=500, height=600, left=${left}, top=${top}`;

      let tabUrl = `/find/id/${Number(type)}`;
      let returnUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ""}/blank?toUrl=${tabUrl}`;

      const data = await getNiceModuleApi({
        returnUrl,
      });

      if (data) {
        const { enc_data, integrity, token_version_id } = data.output;

        window.open("", "nicePopup", option);

        const formEl = document.getElementById("nicePopup");

        formEl.target = "nicePopup";

        formEl.enc_data.value = enc_data;
        formEl.token_version_id.value = token_version_id;
        formEl.integrity_value.value = integrity;

        formEl.submit();
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      dispatch(SET_ALERT_MSG(error.message));
      setIsLoading(false);
    }
  };

  const onLogin = async () => {
    try {
      const data = await getFindIdApi({
        reqNo: reqNo,
        mobileNo: userInfo.telNum,
      });

      setFindedId(data.output.USERID);
      onNiceAuth(1);
    } catch (error) {
      dispatch(SET_ALERT_MSG(error.message));
    }
  };

  // 컴포넌트가 처음 마운트될 때, 전역 함수 등록
  useEffect(() => {
    // 자식 창에서 phoneNumber 전달 시 호출할 함수
    window.setNice = async data => {
      try {
        const res = await getNiceInfoApi({
          ...data,
        });

        setReqNo(res.output.requestno);

        // setRequestNo(res.output.requestno);
        handleInputChange({ value: res.output?.name, field: "userName" });
        handleInputChange({ value: res.output?.mobileno, field: "telNum" });

        // 자식 창에서 부모 창의 화면을 바꿀 때 호출할 함수(새로고침 없이)
        window.navigateTo = path => {
          navigate(path);
        };
      } catch (error) {
        console.error(error);
        dispatch(SET_ALERT_MSG(error.message));
      }
    };
  }, [navigate]);

  const loadingBtn = () => {
    return (
      <Loading>
        <div></div>
      </Loading>
    );
  };

  switch (currentStep) {
    case 0:
      return (
        <StepBox>
          <div className="guide">
            <h4>
              {t("step1-title1")}
              <br />
              <span>{t("step1-span")}</span>
              {t("step1-title2")}
            </h4>
          </div>
          <div className="inputWrap">
            <TheInput
              name="userName"
              flag="underLine"
              label={t("userName")}
              value={userInfo.userName}
              disabled={true}
              type="text"
              placeholder={t("userName-placeholder")}
              required={true}
              $padding="0.75rem 0"
            />
            {Number(type) === 1 && (
              <TheInput
                name="regNum"
                label={t("regNum")}
                value={userInfo.regNum}
                flag="underLine"
                type="text"
                _onChange={e =>
                  handleInputChange({ value: e.target.value, field: "regNum" })
                }
                placeholder={t("regNum-placeholder")}
                required={true}
                $padding="0.75rem 0"
                $margin="0 0 0.5rem 0"
              />
            )}
            <TheInput
              name="telNum"
              label={t("tel")}
              flag="underLine"
              type="text"
              disabled={true}
              errorMsg={t("step1-telError")}
              value={userInfo.telNum}
              placeholder={t("tel-placeholder")}
              required={true}
              $margin="0 0 0.5rem 0"
              $padding="0.75rem 0"
            />
          </div>
          <div className="btnWrap">
            {!userInfo.telNum ? (
              <TheButton _onClick={getNiceModule}>
                {isLoading ? loadingBtn() : t("step1-btn")}
              </TheButton>
            ) : (
              <TheButton
                _onClick={onLogin}
                disabled={userInfo.userName === "" && userInfo.tel === ""}
              >
                로그인
              </TheButton>
            )}
          </div>
          {/*나이스*/}
          <form
            name="nicePopup"
            id="nicePopup"
            action="https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb"
            method="POST"
            style={{ display: "none" }}
          >
            <input type="hidden" id="m" name="m" value="service" />
            <input
              type="hidden"
              id="token_version_id"
              name="token_version_id"
            />
            <input type="hidden" id="enc_data" name="enc_data" />
            <input type="hidden" id="integrity_value" name="integrity_value" />
          </form>
        </StepBox>
      );
    case 1:
      if (userInfo.code === "1") {
        return (
          <StepBox>
            <div className="guide">
              <h4>{t("step3-fail-title1")}</h4>
              <div className="boldText">{t("step3-fail-title2")}</div>
            </div>
            <div className="graybox">
              <div className="smallTitle">{t("step3-not-user")}</div>
              <div className="smallSubtitle">{t("step3-ad")}</div>
              <div className="btnWrap">
                <TheButton
                  $width="7rem"
                  $style="primaryText"
                  $fontSize="0.875rem"
                  _onClick={() => navigate("/join/user")}
                >
                  {t("step3-sign")}
                </TheButton>
              </div>
            </div>
            <div className="btnWrap">
              <TheButton _onClick={() => navigate("/home")}>
                {t("step3-home")}
              </TheButton>
            </div>
          </StepBox>
        );
      }

      return (
        <StepBox>
          <div className="guide">
            <h4>{t("step3-title")}</h4>
            <div className="boldText">{t("step3-bold")}</div>
          </div>
          <div className="graybox">
            <p>{findedId}</p>
          </div>
          <div className="btnWrap">
            <TheButton
              $width="9.75rem"
              $padding="0.75rem 1.25rem"
              _onClick={() => navigate("/home")}
            >
              {t("step3-home")}
            </TheButton>
            <TheButton
              $width="9.75rem"
              $style="primary"
              $padding="0.75rem 1.25rem"
              _onClick={goFindPw}
            >
              {t("step3-findPw")}
            </TheButton>
          </div>
        </StepBox>
      );
    default:
      return null;
  }
};

const StepBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  font-size: 1rem;
  line-height: 1.375rem;

  & .guide {
    text-align: center;
    & > h4 {
      font-weight: 400;
      & > span {
        font-weight: 600;
      }
    }

    & .boldText {
      font-weight: 600;
    }
    & .redText {
      font-size: 0.875rem;
      color: rgba(220, 0, 0, 1);
    }
  }

  & .inputWrap {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  & .graybox {
    display: flex;
    flex-direction: column;
    background-color: ${({ theme: { color } }) => color.gray[200]};
    border-radius: 0.375rem;
    padding: 1.5rem 1rem;
    width: 20rem;
    margin: 0 auto;
    text-align: center;
    gap: 1rem;
    & > p {
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 1.25;
    }
    & > .smallTitle {
      font-size: 0.875rem;
      line-height: 1.5rem;
      font-weight: 400;
      letter-spacing: calc(0.875rem * -0.025);
    }

    & > .smallSubtitle {
      font-weight: 600;
      letter-spacing: calc(1rem * -0.025);
    }
  }
  & .btnWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }

  & input[type="password"]::-ms-reveal,
  & input[type="password"]::-ms-clear {
    display: none;
  }
`;

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;

  div {
    border: 0.125rem solid #f3f3f3;
    border-top: 0.25rem solid gray;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default FindIdStepBox;
