import store from "./../features/store";

import { setErrorhandler } from "./setErrorhandler";
import removeBeforeFirstDelimiter from "../hooks/useRemoveBeforeFirstDelimiter";
import useChangeAccessToken from "../hooks/useChangeAccessToken";

import { USER_LOGOUT } from "../features/main/shared/authSlice";
import { SET_SESSION_EXPIRED } from "../features/main/shared/commonSlice";

export function setInterceptors(instance) {
  instance.interceptors.request.use(
    function (config) {
      const accessToken = store.getState().auth.userAuth;
      const userInfo = store.getState().auth.userInfo;
      const isLogin = store.getState().auth.userAuth;

      let headers = {
        "Content-Type": config.headers["Content-Type"] || "application/json",
        Language: "KR",
        UserID: accessToken
          ? removeBeforeFirstDelimiter(userInfo?.UserAlias)
          : "",
        Userlevel: accessToken ? userInfo?.UserLevel : "",
        Accesstoken: accessToken ?? "",
      };

      config.headers = {
        ...config.headers,
        ...headers,
      };

      // 로그인 유저의 토큰 만료 시간 확인
      if (isLogin) {
        useChangeAccessToken(isLogin);
      }

      return config;
    },
    function (error) {
      return Promise.reject(error);
    },
  );

  // 응답 인터셉터 추가하기
  instance.interceptors.response.use(
    async function (response) {
      if (response?.data?.status !== "0000") {
        const error = setErrorhandler(response);
        return Promise.reject(error);
      }
      return response;
    },
    function (error) {
      let networkStatus = Number(error.response.status);

      switch (networkStatus) {
        case 401:
          // 토큰 만료 로그아웃 처리 진행
          store.dispatch(USER_LOGOUT());
          store.dispatch(
            SET_SESSION_EXPIRED({
              show: true,
              content: "로그인 시간 경과로 다시 로그인 해주세요.",
            }),
          );
          break;
        default:
          error.message = "네트워크 장애입니다.\n다시 시도해주세요";
          break;
      }

      return Promise.reject(error);
    },
  );
  return instance;
}
