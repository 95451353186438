import { createBrowserRouter, Navigate } from "react-router-dom";
import Root from "../features/main/Root";
import PrivateRoute from "./PrivateRouter";
import PublicRoute from "./PublicRoute";

import HomePage from "../features/main/home/HomePage";
import NotFoundPage from "../features/main/notFound/NotFoundPage";
import NoticePage from "../features/main/notice/NoticePage";
import ApplyServicePage from "../features/main/apply/ApplyServicePage";
import PurchasePage from "../features/main/purchase/PurchasePage";
import ContactPage from "../features/main/contact/ContactPage";
import DownloadPage from "../features/main/download/DownloadPage";
import UserSignupPage from "../features/main/signup/UserSignupPage";
import FindPage from "../features/main/find/FindPage";
import FindIdPage from "../features/main/find/FindIdPage";
import FindPwPage from "../features/main/find/FindPwPage";
import NoticeDetailPage from "../features/main/notice/NoticeDetailPage";
import FaQPage from "../features/main/faq/FaqPage";

import MyTosPage from "../features/main/mytos/MyTosPage";
import BusinessTosPage from "../backup/mytos/BusinessTosPage";
import MyPage from "../features/main/mypage/MyPage";
import UserInfoEditTable from "../features/main/mypage/UserInfoEditTable";
import PwEditTable from "../features/main/mypage/PwEditTable";
import ServiceUsageTable from "../features/main/mypage/ServiceUsageTable";
import { BlankPage } from "../features/main/signup/BlankPage";
// import SignupPage from "../features/main/signup/SignupPage";
// import CorpSignupPage from "../backup/corpsingup/CorpSignupPage";
// import AdminRoot from "../features/admin/AdminRoot";
// import AdminLoginPage from "../features/admin/login/AdminLoginPage";

export const Router = createBrowserRouter([
  {
    path: "/blank",
    element: <BlankPage />,
  },
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "",
        element: <Navigate to="home" replace />,
      },
      {
        path: "home",
        element: (
          <PublicRoute>
            <HomePage />
          </PublicRoute>
        ),
      },
      {
        path: "notice",
        element: <NoticePage />,
      },
      {
        path: "notice/:id",
        element: <NoticeDetailPage />,
      },
      {
        path: "faq",
        element: <FaQPage />,
      },
      // {
      //   path: "faq/:id",
      //   element: <FaqPageDetailPage />,
      // },
      {
        path: "apply",
        element: <ApplyServicePage />,
      },
      {
        path: "purchase",
        element: <PurchasePage />,
      },
      {
        path: "contact",
        element: <ContactPage />,
      },
      {
        path: "download",
        element: <DownloadPage />,
      },
      // {
      //   path: "join",
      //   element: <SignupPage />,
      // },
      // {
      //   path: "join/corp",
      //   element: <CorpSignupPage />,
      // },
      {
        path: "join/user",
        element: <UserSignupPage />,
      },
      {
        path: "find",
        element: <FindPage />,
        children: [
          {
            path: "",
            element: <Navigate to="id/:type" replace />,
          },
          {
            path: "id/:type",
            element: <FindIdPage />,
          },
          {
            path: "password/:type",
            element: <FindPwPage />,
          },
        ],
      },
      {
        path: "mytos",
        element: (
          <PrivateRoute>
            <MyTosPage />
          </PrivateRoute>
        ),
      },
      {
        path: "businesstos",
        element: (
          <PrivateRoute>
            <BusinessTosPage />
          </PrivateRoute>
        ),
      },
      {
        path: "mypage",
        element: (
          <PrivateRoute>
            <MyPage />
          </PrivateRoute>
        ),
        children: [
          {
            path: "",
            element: <Navigate to="userinfoedit" replace />,
          },
          {
            path: "userinfoedit",
            element: <UserInfoEditTable />,
          },
          {
            path: "pwedit",
            element: <PwEditTable />,
          },
          {
            path: "usagedetail",
            element: <ServiceUsageTable />,
          },
        ],
      },
      {
        path: "*",
        element: <NotFoundPage />,
      },
    ],
  },
  // {
  //   path: "admin",
  //   element: <AdminRoot />,
  //   children: [
  //     {
  //       path: "",
  //       element: <Navigate to="/admin/login" replace />,
  //     },
  //     {
  //       path: "login",
  //       element: <AdminLoginPage />,
  //     },
  //     {
  //       path: "/admin/*",
  //       element: <Navigate to="/admin/login" replace />,
  //     },
  //   ],
  // },
]);
