/**
 * @param key {string} keyName
 * @description 미로그인/ 로그인 상관 없이 랜덤한 UUID 생성하여 localStorage에 세팅
 * */
export const generateUUID = key => {
  localStorage.setItem(key, Math.floor(10000 + Math.random() * 90000));
};

/**
 * @param key {string} keyName
 * @description 세팅된 로컬스토리지 값 존재 여부 확인
 * */
export const getUUID = key => {
  return localStorage.getItem(key);
};

/**
 * @param key {string} keyName
 * @description 세팅된 로컬스토리지 값 삭제
 * */
export const deleteUUID = key => {
  localStorage.removeItem(key);
};

/**
 * @param name {string} keyName
 * @param value {string} value
 * @param days { number} 날짜
 * @description 입력받은 일수만큼의 time 생성하여 쿠키에 저장
 * */
export const setCookie = (name, value, days) => {
  days = days ?? 1;
  const date = new Date();

  // days * 24시간 * 60분 * 60초 * 1000밀리초
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);

  // 쿠키 문자열 생성
  document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/`;
};

/**
 * @param name {string} keyName
 * @param value {string} value
 * @return // 쿠키 값이 일치하면 false, 아니면 true
 * @description 일치하는 name, value를 가진 쿠키 존재 여부 확인하여 존재 시 false, 미존재 시 true 리턴
 * */
export const getCookie = (name, value) => {
  const matches = document.cookie.match(new RegExp(`(?:^|; )${name}=([^;]*)`));

  if (!matches) return false; // 해당 name의 쿠키가 존재하지 않음

  const storedValue = decodeURIComponent(matches[1]); // 쿠키 값 디코딩
  return storedValue === value;
};

/**
 * @param name {string} keyName
 * @description 일치하는 name을 가진 쿠키 존재한다면 삭제
 * */
export const deleteCookie = name => {
  setCookie(name, "", -1); // days에 음수를 주면 쿠키가 바로 만료됨
};
