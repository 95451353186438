import React from "react";
import styled from "styled-components";

/**
 * @param {Object} props
 * @param {string[]} props.options - 토글로 표시할 옵션들의 배열 (예: ["Individual", "Business"])
 * @param {string} props.selected - 현재 선택된 옵션 (예: "Individual")
 * @param {(option: string) => void} props.onChange - 토글을 클릭했을 때 선택 옵션을 변경하는 콜백 함수
 */

const TheToggle2 = ({
  children,
  options = [],
  selected,
  onChange,
  $margin = "0 0",
  $padding = "0 0",
  $width = "16.25rem",
  $height = "2.75rem",
  $fontSize = "1.125rem",
  $fontWeight = 600,
}) => {
  const styles = {
    $margin: $margin,
    $padding: $padding,
    $width: $width,
    $height: $height,
    $fontSize: $fontSize,
    $fontWeight: $fontWeight,
  };

  return (
    <ToggleContainer {...styles}>
      {options.map(option => (
        <ToggleBtn
          type="button"
          key={option}
          className={option === selected ? "active" : ""}
          onClick={() => onChange(option)}
        >
          {option}
          {children}
        </ToggleBtn>
      ))}
      <ActivedBtn type="button" selected={selected} options={options} />
    </ToggleContainer>
  );
};

const ToggleContainer = styled.div`
  display: flex;
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  margin: ${({ $margin }) => $margin};
  padding: ${({ $padding }) => $padding};
  background: rgba(0, 0, 0, 0.3);
  border-radius: 4.4575rem;
  position: relative;
`;

const ToggleBtn = styled.button`
  flex: 1;
  height: 100%;
  border: none;
  border-radius: 4.4575rem;
  color: ${({ theme: { color } }) => color.white[400]};
  background-color: transparent;
  cursor: pointer;
  font-size: ${({ $fontSize }) => $fontSize};
  font-weight: ${({ $fontWeight }) => $fontWeight};
  line-height: 0.89125rem;
  transition: background-color 0.5s ease;
  position: relative;
  z-index: 1;

  &.active {
    background-color: ${({ theme: { color } }) => color.blue[400]};
  }
`;

const ActivedBtn = styled.div.withConfig({
  shouldForwardProp: prop => prop !== "options",
})`
  position: absolute;
  top: 0;
  left: ${({ selected, options }) => (selected === options[0] ? "0" : "50%")};
  width: 50%;
  height: 100%;
  background-color: ${({ theme: { color } }) => color.blue[400]};
  border-radius: 4.4575rem;
  transition: left 0.3s ease;
  z-index: 0;
`;

export default TheToggle2;
