import { api } from "../index";

export const getNoticeListApi = async data => {
  try {
    const response = await api.post("/user/notice/list", data);

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getNoticeDetailApi = async data => {
  try {
    const response = await api.post("/user/notice/detail", data);

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
