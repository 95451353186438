import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { withdrawApi } from "../../../services/controller/mypageController";

// initialState
const initialState = {
  widthDrawStatus: "",
  isLoading: false,
  error: null,
};

// Actions (Api)

export const withdrawAccount = createAsyncThunk(
  "mypageSlice/withdrawAccount",
  async ({ UserID, Password }, { rejectWithValue }) => {
    try {
      const res = await withdrawApi({ UserID, Password });
      return res.output;
    } catch (error) {
      return rejectWithValue(error.response?.status || "An error occurred");
    }
  },
);

const mypageSlice = createSlice({
  name: "mypageSlice",
  initialState,

  reducers: {},
  extraReducers: builder => {
    builder
      // Withdraw account
      .addCase(withdrawAccount.pending, state => {
        state.isLoading = true;
      })
      .addCase(withdrawAccount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.widthDrawStatus = action.payload;
      })
      .addCase(withdrawAccount.rejected, (state, action) => {
        state.isLoading = false;
        state.widthDrawStatus = action.payload;
        state.error = action.payload;
      });
  },
});

export default mypageSlice.reducer;
