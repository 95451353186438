import styled from "styled-components";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { TheProgressBar, TheSection, TheToggle2 } from "../../../components";
import ServiceCard from "./ServiceCard";
import PaymentCard from "./PaymentCard";
import PaymentCompletedCard from "./PaymentCompletedCard";
import PromotionalBannerContainer from "./PromotionalBannerContainer";
import PrivateRoute from "../../../router/PrivateRouter";

import { SET_ALERT_MSG } from "../shared/commonSlice";

const ApplyServicePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("apply");

  const isLogin = useSelector(state => state.auth.userAuth);

  // 프로그레스바 셋팅
  const steps = ["서비스 선택", "결제하기", "결제 완료"];
  const [currentStep, setCurrentStep] = useState(0);
  const [paymentType, setPaymentType] = useState("monthly");
  const [selectedService, setSelectedService] = useState(null);

  const handlePaymentChange = () => {
    setPaymentType(paymentType === "monthly" ? "yearly" : "monthly");
  };

  // 단계별 컴포넌트 셋팅
  const getCurrentStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <>
            {/*수정하꺼야*/}
            <div className="toggleWrap">
              <TheToggle2
                options={["monthly", "yearly"]}
                selected={paymentType}
                onChange={handlePaymentChange}
              />
            </div>
            <ServiceCard term={paymentType} onProgressStep={handleSetService} />
          </>
        );
      case 1:
        // 로그인 x인 상태라면 로그인 먼저 진행해주세요 띄우고 종료
        if (!isLogin) {
          dispatch(SET_ALERT_MSG("로그인 후 이용 가능한 서비스입니다."));
          setCurrentStep(0);
          return;
        }
        return (
          <PrivateRoute>
            <PaymentCard
              payInfo={selectedService}
              onProgressStep={handleProgressStep}
            />
          </PrivateRoute>
        );
      case 2:
        return (
          <PrivateRoute>
            <PaymentCompletedCard
              payInfo={selectedService}
              onMovePage={handleMovePage}
            />
          </PrivateRoute>
        );
      default:
        return null;
    }
  };

  const handleMovePage = path => {
    navigate(path);
  };

  const handleProgressStep = service => {
    let step = service.type === "goForward" ? currentStep + 1 : currentStep - 1;
    if (currentStep <= steps.length - 1) setCurrentStep(step);
  };

  const handleSetService = service => {
    setSelectedService(() => service.serviceType);
    handleProgressStep(service);
  };

  return (
    <TheSection title={t("title")} $width="100%" $padding="8rem 0 0">
      <ServiceContainer>
        <div className="progressBar">
          <TheProgressBar steps={steps} currentStep={currentStep} />
        </div>
        {getCurrentStep()}
      </ServiceContainer>
      <PromotionalBannerContainer />
    </TheSection>
  );
};

const ServiceContainer = styled.div`
  width: 100%;
  display: grid;
  row-gap: 2rem;
  margin-bottom: 3.75rem;

  & .toggleWrap {
    margin: 0 auto;
  }

  & .progressBar {
    padding: 2rem 0 0;
  }
`;

// const EventBanner = styled.div`
//   display: flex;
//   justify-content: center;
// `;

export default ApplyServicePage;
