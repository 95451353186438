import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  TheBox,
  TheButton,
  TheDropBox,
  TheModal,
  TheSection,
} from "../../../components";
import { getMyTosVdInfo, getUsagePattern, getVMID } from "./mytosSlice";
import IndividualUsageInfo from "./IndividualUsageInfo";
import vdComputer from "../../../assets/images/vdComputer.png";

import removeBeforeFirstDelimiter from "../../../hooks/useRemoveBeforeFirstDelimiter";

const MyTosPage = () => {
  const dispatch = useDispatch();
  const wsRef = useRef(null);
  const navigate = useNavigate();

  // 전역 상태값
  const {
    userInfo: { enc_str, loginserver, UserAlias },
  } = useSelector(state => state.auth);

  // 로컬 상태값
  const [vdInfo, setVdInfo] = useState(null);
  const [selectedPc, setSelectedPc] = useState(null);
  const [downModal, setDownModal] = useState(false);

  const handlePcChange = e => {
    setSelectedPc(e);
  };

  const handleBtnClick = route => {
    navigate(route);
  };

  const moToDownload = () => {
    setDownModal(false);
    navigate("/download");
  };

  // vd접속
  const myTosClick = async () => {
    // vd접속을 위한 vdId 불러오기
    let vdId = await dispatch(
      getVMID({ VMName: selectedPc.value, dedicateFlag: 1 }),
    ).unwrap();

    const url = `dslinker://CONNECTVM?VMID=${vdId}&userinfo=${enc_str}&info=${loginserver}&OTP=0&useags=1`;
    wsRef.current = new WebSocket(`ws://127.0.0.1:21660`);

    wsRef.current.onopen = () => {
      window.location.href = url;
    };

    wsRef.current.onerror = e => {
      setDownModal(true);
      console.error(e);
    };
  };

  // 선택한 vd정보
  const vdInfoItem = async () => {
    const UserID = removeBeforeFirstDelimiter(UserAlias);
    const res = await dispatch(getMyTosVdInfo({ UserID }));
    await dispatch(getUsagePattern({ UserID }));

    if (res) {
      const _vdInfo = res?.payload;
      const selectedVd = _vdInfo?.length >= 1 ? _vdInfo[0] : null;
      await setVdInfo(_vdInfo);
      await setSelectedPc({
        label: selectedVd?.VDName,
        value: selectedVd?.VDName,
      });
    }
  };

  const isVdInfo = () => {
    return vdInfo?.find(item => item?.VDName === selectedPc?.value) || null;
  };

  const isVdState = () => {
    if (!vdInfo || vdInfo.length === 0) return "비정상";

    const vmState =
      vdInfo?.find(item => item?.VDName === selectedPc?.value)?.VMState || null;

    switch (Number(vmState)) {
      case 1:
        return "기타";
      case 2:
        return "동작 중";
      case 3:
        return "종료";
      case 4:
        return "종료 중";
      case 6:
        return "저장";
      case 9:
        return "일시 중지";
      case 10:
        return "시작 중";
      case 11:
        return "재시작";
      default:
        return "비정상";
    }
  };

  useEffect(() => {
    vdInfoItem();
  }, []);

  if (vdInfo === null) {
    return;
  }

  const isCurrentVd = () => {
    // vd 접근 제한플래그 : 접속 활성
    if (isVdInfo()?.AccessRestrictionFlag === 0) {
      return (
        <div className="vdInfoBox">
          <div className="vdInfoDrop">
            <div className="InfoLabel">PC 이름</div>
            <TheDropBox
              $width="10rem"
              $height="1.8rem"
              $padding="0.625rem 0.75rem"
              $fontSize="0.938rem"
              onOptionSelect={handlePcChange}
              initialOption={selectedPc ? selectedPc.value : "PC 선택"}
              options={
                vdInfo?.map(item => ({
                  label: item.VDName,
                  value: item.VDName,
                })) || []
              }
            />
          </div>

          <div className="vdInfo">
            <div className="InfoLabel">운영체제</div> {isVdInfo()?.OSName || ""}
          </div>
          <div className="vdInfo">
            <div className="InfoLabel">프로세서</div>
            <span className="fontBold">{isVdInfo()?.CPUCoreCnt}</span> vCPU
          </div>
          <div className="vdInfo">
            <div className="InfoLabel"> RAM</div>
            <span className="fontBold">
              {(isVdInfo()?.TotalMemory / 1024).toFixed(2)}
            </span>{" "}
            GB
          </div>
          <div className="vdInfo">
            <div className="InfoLabel">SSD</div>
            <span className="fontBold">
              {(isVdInfo()?.TotalDisk / 1024).toFixed(2)}
            </span>{" "}
            GB
          </div>
        </div>
      );
    } else {
      return (
        <div className="vdInfoBox">
          <div className="vdInfoDrop">
            <div className="InfoLabel">PC 이름</div>
            <TheDropBox
              $width="10rem"
              $height="1.8rem"
              $padding="0.625rem 0.75rem"
              $fontSize="0.938rem"
              onOptionSelect={handlePcChange}
              initialOption={selectedPc ? selectedPc.value : "PC 선택"}
              options={
                vdInfo?.map(item => ({
                  label: item.VDName,
                  value: item.VDName,
                })) || []
              }
            />
          </div>

          <div className="warnningMsg">
            <p>VD 접근이 제한되었습니다.</p>
            <span>관리자에게 문의해주세요</span>
          </div>
        </div>
      );
    }
  };

  if (selectedPc.value) {
    return (
      <TheSection>
        <MyTosWrap>
          <TheBox $width="70rem" $height="17rem" $padding="0">
            <VdInfoContainer>
              {/*vd정보*/}
              {isCurrentVd()}

              {/*vd이미지 & 동작 상태*/}
              <div className="imgWrap">
                <img src={vdComputer} alt="vdComputer" />
                <p>VD상태 | {isVdState()}</p>
              </div>

              <div className="btnWrap">
                <TheButton
                  disabled={isVdInfo()?.AccessRestrictionFlag === 1}
                  $width="18rem"
                  $radius="0.375rem"
                  $padding="1rem 2.5rem"
                  $fontSize="1.25rem"
                  $fontWeight="600"
                  _onClick={myTosClick}
                >
                  TOS 접속
                </TheButton>
                <TheButton
                  $style="primaryLine"
                  $width="18rem"
                  $radius="0.375rem"
                  $padding="1rem 2.5rem"
                  $fontSize="1.25rem"
                  $fontWeight="600"
                  _onClick={() => handleBtnClick("/mypage")}
                >
                  마이페이지
                </TheButton>
              </div>
            </VdInfoContainer>
          </TheBox>
          <IndividualUsageInfo isVdInfo={isVdInfo} />
        </MyTosWrap>
        {downModal && (
          <TheModal
            type="confirm"
            title="링커 다운로드 안내"
            content={`링커가 다운로드 되지 않았습니다\n확인을 누르시면 다운로드 페이지로 이동합니다`}
            $width="30rem"
            $lineHeight="2rem"
            _justClose={() => setDownModal(false)}
            _acceptClose={() => moToDownload()}
          ></TheModal>
        )}
      </TheSection>
    );
  }

  return (
    <TheSection>
      <MyTosWrap>
        <TheBox $width="70rem" $height="17rem" $padding="0">
          <NoVdInfo>
            <div className="noTitle">
              <h4>현재 이용 중인 TheOtherSpace가 없습니다.</h4>
              <p>상품 구매 후 바로 사용할 수 있습니다.</p>
            </div>
            <div className="noBtnWrap">
              <TheButton
                $width="11rem"
                $radius="0.375rem"
                $padding="0.875rem 2.5rem"
                $fontSize="0.9375rem"
                $lineHeight="1.25rem"
                $fontWeight="600"
                _onClick={() => handleBtnClick("/apply")}
              >
                서비스 신청
              </TheButton>
              <TheButton
                $style="primaryLine"
                $width="11rem"
                $radius="0.375rem"
                $padding="0.875rem 2.5rem"
                $fontSize="0.9375rem"
                $lineHeight="1.25rem"
                $fontWeight="600"
                _onClick={() => handleBtnClick("/mypage")}
              >
                마이페이지
              </TheButton>
            </div>
          </NoVdInfo>
        </TheBox>
        {/*마지막 차트 박스*/}
        <IndividualUsageInfo isVdInfo={isVdInfo} />
      </MyTosWrap>
    </TheSection>
  );
};
const MyTosWrap = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 auto;
`;
const VdInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  row-gap: 0.5rem;

  & .vdInfoBox,
  & .imgWrap,
  & .btnWrap {
    width: 23.3125rem;
  }

  & .vdInfoBox {
    display: flex;
    flex-direction: column;
    padding: 2.25rem;
  }

  & .vdInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 0.062rem solid ${({ theme: { color } }) => color.white[600]};
    padding: 0.75rem 1rem;

    & .InfoLabel {
      width: 5.75rem;
    }

    & .fontBold {
      font-weight: 600;
    }
  }

  & .warnningMsg {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    & p {
      font-weight: 600;
      color: ${({ theme: { color } }) => color.red[400]};
      line-height: 2rem;
    }

    & span {
      font-weight: 400;
      color: ${({ theme: { color } }) => color.red[400]};
    }
  }

  & .vdInfoDrop {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 0.062rem solid ${({ theme: { color } }) => color.white[600]};
    padding: 0.325rem 1rem;

    & .InfoLabel {
      width: 5.75rem;
    }
  }

  & .imgWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;

    & img {
      width: 22.5rem;
      height: 10.25rem;
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }

    p {
      border-radius: 6.25rem;
      background-color: #5e6da9;
      padding: 0.5625rem 1rem;
      color: ${({ theme: { color } }) => color.white[400]};
      font-size: 0.8125rem;
      font-weight: 500;
    }
  }

  & .btnWrap {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2.25rem;
    min-width: 16.5625rem;
    justify-content: center;
    align-items: center;
  }
`;

const NoVdInfo = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.25rem;

  & .noTitle {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    text-align: center;

    & h4 {
      font-size: 1.5rem;
      font-weight: 600;
    }

    & p {
      font-size: 1rem;
      font-weight: 400;
    }
  }
  & .noBtnWrap {
    display: flex;
    flex-direction: row;
    column-gap: 0.5rem;
    width: 30rem;
    justify-content: center;
    align-items: center;
  }
`;

export default MyTosPage;
