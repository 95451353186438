import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ReactComponent as CircleCheckIcon } from "../../../assets/svg/circleCheck.svg";

import TheSection from "../../../components/layout/TheSection";
import { TheButton, TheTab } from "../../../components";

const FindPage = () => {
  const { t } = useTranslation("find");
  const navigate = useNavigate();
  const location = useLocation();
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);

  const handleTabChange = index => {
    let type = localStorage.getItem("accountType") === "Individual" ? 0 : 1;

    if (index === 0) {
      navigate(`id/${type}`);
    } else if (index === 1) {
      navigate(`password/${type}`);
    }
  };

  const tabs = [
    {
      label: "아이디 찾기",
      id: "id",
      content: "",
    },
    {
      label: "비밀번호 찾기",
      id: "password",
      content: "",
    },
  ];

  useEffect(() => {
    if (location.state?.passwordChanged) {
      setIsPasswordChanged(true);
    }
  }, [location.pathname]);

  return (
    <TheSection title={isPasswordChanged ? "" : t("section-title")}>
      {isPasswordChanged ? (
        <ChangeContainer>
          <CircleCheckIcon />
          <div>
            <strong>{t("pw-change-title")}</strong>
            <p>{t("pw-change-subtitle")}</p>
          </div>
          <TheButton $width="9.75rem" _onClick={() => navigate("/home")}>
            {t("log-in")}
          </TheButton>
        </ChangeContainer>
      ) : (
        <Container>
          <TheTab
            tabs={tabs}
            onTabChange={handleTabChange}
            $width="31.25rem"
            $contentMinHeight="25rem"
            $backgroundColor="none"
            contentUsed={false}
          />
          <Outlet />
        </Container>
      )}
    </TheSection>
  );
};

const ChangeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1.5rem;
  width: 100%;
  height: 100%;
  padding: 1rem 1rem;
  & > svg {
    width: 5rem;
    height: 5rem;
  }

  & > div {
    text-align: center;
    & > strong {
      display: block;
      font-size: 2rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
      line-height: 2.387rem;
    }
    & > p {
      line-height: 1.193rem;
      white-space: pre-wrap;
    }
  }
`;

const Container = styled.div`
  width: 31.25rem;
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export default FindPage;
