import { api } from "../index";

export const mytosVdInfoApi = async data => {
  try {
    const res = await api.post("/user/allocatedVD", data);
    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const mytosUsagePatternApi = async data => {
  try {
    const res = await api.post("/dashboard/usagePattern", data);
    return res.data;
  } catch (error) {
    if (!error.code === "ERROR_0000") {
      console.error(error);
      throw error;
    }
    throw error;
  }
};

export const mytosVdIdApi = async data => {
  try {
    const res = await api.post("/user/VMID", data);
    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
