import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { TheInput, TheButton, TheToggle2 } from "../../../components";
import {
  getNiceInfoApi,
  getNiceModuleApi,
} from "../../../services/controller/commonController";
import { SET_ALERT_MSG } from "../shared/commonSlice";
import { useDispatch } from "react-redux";
import { getFindPwApi } from "../../../services/controller/findController";

const FindPwStepBox = ({
  userInfo,
  handleChange,
  isRegNumError,
  handleTypeSwitch,
  isPwError,
  isPwCheckError,
  successFind,
  type,
  loginType,
  niceInputChange,
}) => {
  const { t } = useTranslation("find");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [reqNo, setReqNo] = useState("");

  const getNiceModule = async () => {
    try {
      const left = screen.width / 2 - 500 / 2;
      const top = screen.height / 2 - 800 / 2;
      const option = `status=no, menubar=no, toolbar=no, resizable=no, width=500, height=600, left=${left}, top=${top}`;

      let tabUrl = `/find/password/${Number(type)}`;
      let returnUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ""}/blank?toUrl=${tabUrl}`;

      const data = await getNiceModuleApi({
        returnUrl,
      });

      if (data) {
        const { enc_data, integrity, token_version_id } = data.output;

        window.open("", "nicePopup", option);

        const formEl = document.getElementById("nicePopup");

        formEl.target = "nicePopup";

        formEl.enc_data.value = enc_data;
        formEl.token_version_id.value = token_version_id;
        formEl.integrity_value.value = integrity;

        formEl.submit();
      }
    } catch (error) {
      console.error(error);
      dispatch(SET_ALERT_MSG(error.message));
    }
  };

  const onFindPw = async () => {
    try {
      const data = await getFindPwApi({
        reqNo: reqNo,
        mobileNo: userInfo.telNum,
        userId: userInfo.id,
        pwd: userInfo.newPw,
        confirmPwd: userInfo.newPw,
      });
      if (data.status === "0000") {
        successFind();
      }
    } catch (error) {
      dispatch(SET_ALERT_MSG(error.message));
    }
  };

  // 컴포넌트가 처음 마운트될 때, 전역 함수 등록
  useEffect(() => {
    // 자식 창에서 phoneNumber 전달 시 호출할 함수
    window.setNice = async data => {
      try {
        const res = await getNiceInfoApi({
          ...data,
        });

        setReqNo(res.output.requestno);

        niceInputChange(res.output?.mobileno);

        // 자식 창에서 부모 창의 화면을 바꿀 때 호출할 함수(새로고침 없이)
        window.navigateTo = path => {
          navigate(path);
        };
      } catch (error) {
        console.error(error);
        dispatch(SET_ALERT_MSG(error.message));
      }
    };
  }, [navigate]);

  return (
    <StepBox>
      <ToggleDiv>
        <TheToggle2
          options={["Individual", "Business"]}
          selected={loginType}
          onChange={selectedType => handleTypeSwitch(selectedType)}
        />
      </ToggleDiv>
      <div className="guide">
        <h4>
          {t("pw-step1-title")}
          <div className="boldText">{t("pw-step1-bold")}</div>
        </h4>
      </div>
      <div className="inputWrap">
        <TheInput
          name="id"
          flag="underLine"
          label={t("id")}
          value={userInfo.id}
          _onChange={handleChange}
          placeholder={t("id-placeholder")}
          required={true}
          $padding="0.75rem 0"
        />
        {Number(type) === 1 && (
          <TheInput
            name="regNum"
            flag="underLine"
            label={t("regNum")}
            value={userInfo.regNum}
            type="text"
            _onChange={handleChange}
            placeholder={t("regNum-placeholder")}
            required={true}
            isError={isRegNumError}
            $padding="0.75rem 0"
          />
        )}
        <FileWrapEl>
          <li>
            <TheInput
              name="telNum"
              flag="underLine"
              label={t("tel")}
              value={userInfo.telNum}
              type="text"
              disabled={true}
              _onChange={handleChange}
              placeholder={t("tel-placeholder")}
              required={true}
              errorMsg={t("step1-telError")}
              $width="100%"
              $margin="0 0 0.5rem 0"
              $padding="0.75rem 0"
            />
          </li>
          <li>
            <TheButton
              $style="blackLine"
              $padding="0.7rem 0"
              _onClick={getNiceModule}
            >
              {t("step1-btn")}
            </TheButton>
          </li>
        </FileWrapEl>
      </div>
      <div className="inputWrap">
        <TheInput
          name="newPw"
          type="password"
          flag="underLine"
          label={t("pw")}
          value={userInfo.newPw}
          _onChange={handleChange}
          placeholder={t("pw-placeholder")}
          isError={isPwError}
          required={true}
          showRes={true}
          errorMsg={t("pw-step3-pwError")}
          $margin="0 0 0.5rem 0"
          $padding="0.75rem 0"
        />
        <TheInput
          name="newPwCheck"
          value={userInfo.newPwCheck}
          type="password"
          flag="underLine"
          label={t("pw-check")}
          _onChange={handleChange}
          placeholder={t("pw-check-placeholder")}
          isError={isPwCheckError}
          required={true}
          showRes={true}
          errorMsg={t("pw-step3-pwCheck-fail")}
          $margin="0 0 0.5rem 0"
          $padding="0.75rem 0"
        />
      </div>
      <TheButton
        _onClick={onFindPw}
        disabled={
          !userInfo.id === "" ||
          !userInfo.telNum === "" ||
          isPwError ||
          isPwCheckError ||
          !userInfo.newPw ||
          !userInfo.newPwCheck
        }
      >
        {t("check")}
      </TheButton>
      <div className="btnWrap">
        <TheButton
          $style="primaryText"
          $fontSize="0.875rem"
          _onClick={() => navigate("/join/user")}
        >
          {t("sign-in")}
        </TheButton>
      </div>
      {/*나이스*/}
      <form
        name="nicePopup"
        id="nicePopup"
        action="https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb"
        method="POST"
        style={{ display: "none" }}
      >
        <input type="hidden" id="m" name="m" value="service" />
        <input type="hidden" id="token_version_id" name="token_version_id" />
        <input type="hidden" id="enc_data" name="enc_data" />
        <input type="hidden" id="integrity_value" name="integrity_value" />
      </form>
    </StepBox>
  );
};

const StepBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  font-size: 1rem;
  line-height: 1.375rem;

  & .guide {
    text-align: center;

    & > h4 {
      font-weight: 400;

      & > span {
        font-weight: 600;
      }
    }

    & .boldText {
      font-weight: 600;
    }

    & .redText {
      font-size: 0.875rem;
      color: ${({ theme: { color } }) => color.red[500]};
    }
  }

  & .inputWrap {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  & .graybox {
    display: flex;
    flex-direction: column;
    background-color: ${({ theme: { color } }) => color.gray[200]};
    border-radius: 0.375rem;
    padding: 1.5rem 1rem;
    width: 20rem;
    margin: 0 auto;
    text-align: center;
    gap: 1rem;
    & > p {
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 1.25;
    }
    & > .smallTitle {
      font-size: 0.875rem;
      line-height: 1.5rem;
      font-weight: 400;
      letter-spacing: calc(0.875rem * -0.025);
    }

    & > .smallSubtitle {
      font-weight: 600;
      letter-spacing: calc(1rem * -0.025);
    }
  }
  & .btnColumnWrap {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
  }

  & .btnRowWrap {
    display: flex;
    flex-direction: row;
    gap: 0.625rem;
    font-size: 0.875rem;
  }
  & .btnWrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & input[type="password"]::-ms-reveal,
  & input[type="password"]::-ms-clear {
    display: none;
  }
`;

const FileWrapEl = styled.ul`
  display: grid;
  grid-template-columns: 15.5rem 1fr;
  gap: 0.5rem;

  & li:nth-child(2) {
    align-self: end;
    margin-bottom: 0.2rem;
  }
`;

const ToggleDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export default FindPwStepBox;
