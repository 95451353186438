import React, { useState } from "react";
import { TheSection } from "../../../components";
import UserForm from "./UserForm";
// import UserSuccessTable from "./UserSuccessTable";
import SignupSuccessBox from "./SignupSuccessBox";

const UserSignupPage = () => {
  const [completion, setCompletion] = useState(false);

  return (
    <TheSection title={!completion ? "회원 가입" : ""}>
      {!completion ? (
        <UserForm _setResult={e => setCompletion(e)} />
      ) : (
        // <UserSuccessTable />
        <SignupSuccessBox />
      )}
    </TheSection>
  );
};

export default UserSignupPage;
