import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

// 훅
import useFormValidation from "../../../hooks/useFormValidation";

// 컴포넌트
import {
  TheBox,
  TheButton,
  TheCheckBox,
  TheDropBox,
  TheInput,
} from "../../../components";
import UserConsentModal from "./UserConsentModal";

// 리덕스 & api
import {
  checkDuplicateUserId,
  // getServiceContent,
  // registerUser,
} from "./signupSlice";
import { SET_ALERT_MSG } from "../shared/commonSlice";
import {
  getNiceInfoApi,
  getNiceModuleApi,
} from "../../../services/controller/commonController";
import { signupApi } from "../../../services/controller/signupController";
import { useTranslation } from "react-i18next";

const UserForm = ({ _setResult }) => {
  const { t } = useTranslation("footer");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // 훅
  const {
    validateId,
    validateEmail,
    validatePassword,
    validatePasswordCheck,
    validatePhone,
  } = useFormValidation();

  // 전역
  const authList = {
    termsOfUse: {
      // TOS 이용약관
      content: "",
      name: "termsOfUse",
      title: t("termsOfUse"),
      src: `${window.location.origin}/policy/termsOfUse.html`,
    },
    privacyPolicy: {
      // TOS 개인정보 처리방침
      content: "",
      name: "privacyPolicy",
      title: t("privacyPolicy"),
      src: `${window.location.origin}/policy/privacyPolicy.html`,
    },
  };

  const [userInfoList, setUserInfoList] = useState([
    {
      label: "아이디",
      placeholder: "아이디 입력",
      name: "userId",
      value: "",
      isValid: false, // 유효성 통과
      isError: false, // 유효성 불가
      required: true,
      requiredMsg: `영문 최소 1자 포함, 4~16자 이내(숫자 사용 가능)`,
    },
    {
      label: "비밀번호",
      placeholder: `※ ${8}자리이상, 영문, 숫자, 특수기호 포함`,
      type: "password",
      name: "password",
      value: "",
      isValid: false, // 유효성 통과
      isError: false, // 유효성 불가
      required: true,
      requiredMsg: `${8}자리이상, 영문, 숫자, 특수기호 포함`,
    },
    {
      label: "비밀번호 확인",
      placeholder: "비밀번호 확인",
      type: "password",
      name: "confirmPassword",
      isValid: false, // 유효성 통과
      isError: false, // 유효성 불가
      value: "",
      required: true,
      requiredMsg: "비밀번호가 일치하지 않습니다.",
    },
    {
      label: "이름",
      placeholder: "본인인증을 완료해주세요",
      name: "userName",
      value: "",
      isValid: false,
      required: true,
      disabled: true,
      requiredMsg: "사용자 이름을 입력해주세요.",
    },
    {
      label: "전화번호",
      placeholder: "본인인증을 완료해주세요",
      name: "userPhone",
      value: "",
      required: true,
      requiredMsg: "'-' 을 제외한 전화번호 입력",
      disabled: true,
      isValid: false, // 유효성 통과
      isError: false, // 유효성 불가
    },
    {
      label: "이메일",
      placeholder: "",
      name: "userEmail",
      value: "",
      required: false,
      requiredMsg: "올바른 이메일 형식 입력",
      isValid: false, // 유효성 통과
      isError: false, // 유효성 불가
    },
  ]);
  const [consentList, setConsentList] = useState([
    {
      text: "개인 정보 수집 및 이용 동의(필수)",
      required: true,
      requiredMsg: "개인 정보 수집 및 이용 동의가 필요합니다.",
      accepted: false,
      content: "",
      name: "privacyPolicy",
      title: t("privacyPolicy"),
      src: `${window.location.origin}/policy/privacyPolicy.html`,
    },
    {
      text: "이용약관 동의(필수)",
      required: true,
      requiredMsg: "이용약관 동의가 필요합니다.",
      accepted: false,
      content: "",
      name: "termsOfUse",
      title: t("termsOfUse"),
      src: `${window.location.origin}/policy/termsOfUse.html`,
    },
    // {
    //   text: "프로모션 정보 수신 동의(선택)",
    //   name: "promotionTermsAgreed",
    //   accepted: false,
    // },
  ]);
  const [email, setEmail] = useState({
    id: "",
    email: "",
  });
  const [selectAuth, setSelectAuth] = useState("termsOfUse");
  const [showModal, setShowModal] = useState(false);
  const emailList = [
    { label: "직접입력", value: "" },
    { label: "naver.com", value: "naver.com" },
    { label: "daum.net", value: "daum.net" },
    { label: "hanmail.net", value: "hanmail.net" },
    { label: "gmail.com", value: "gmail.com" },
    { label: "nate.com", value: "nate.com" },
  ];
  const [checkedUserId, setCheckedUserId] = useState(false);
  const [requestNo, setRequestNo] = useState(false); // 회원가입시 추가해야함 (나이스 인증 요청 고유 번호 - 나이스 인증시 받음)

  const onChangeFormLib = async e => {
    e.preventDefault();

    try {
      let tmpArr = {};
      userInfoList.map(
        item => (tmpArr = { ...tmpArr, [item.name]: item.value }),
      );
      consentList.map(
        item => (tmpArr = { ...tmpArr, [item.name]: item.accepted }),
      );
      let {
        userId,
        password,
        confirmPassword,
        userName,
        userEmail,
        userPhone,
        promotionTermsAgreed,
      } = tmpArr;

      let data = await signupApi({
        userId,
        password,
        confirmPassword,
        userName,
        userEmail,
        userPhone,
        privacyTermsAgreed: true,
        serviceTermsAgreed: true,
        promotionTermsAgreed,
        reqNo: requestNo, // 나이스인증고유번호
      });
      // let res = await dispatch(
      //   registerUser({
      //     userId,
      //     password,
      //     confirmPassword,
      //     userName,
      //     userEmail,
      //     userPhone,
      //     privacyTermsAgreed,
      //     serviceTermsAgreed,
      //     promotionTermsAgreed,
      //     reqNo: requestNo, // 나이스인증고유번호
      //   }),
      // );

      console.log("data : ", data);
      // 오류코드 (추후 수정)

      switch (data.status) {
        case "0000":
          _setResult && _setResult(true);
          return;
        default:
          _setResult && _setResult(false);
          return;
      }
      // if (res?.status !== "0000") {
      //   const error = setErrorhandler({ status: res?.payload });
      //   if (error.code === "ERROR_0002") error.message = "NICE 본인 인증 오류.";
      //   if (error.code === "ERROR_0014")
      //     error.message =
      //       "이미 가입한 회원입니다. \n아이디/ 패스워드 찾기 기능을 이용해주세요.";
      //
      //   dispatch(SET_ALERT_MSG(error.message));
      // } else {
      //   _setResult && _setResult(res.payload);
      // }
    } catch (error) {
      switch (error.code) {
        case "ERROR_0002":
          error.message = "NICE 본인 인증 오류.";
          break;
        case "ERROR_0014":
          error.message =
            "이미 가입한 회원입니다. \n아이디/ 패스워드 찾기 기능을 이용해주세요.";
          break;
      }
      dispatch(SET_ALERT_MSG(error.message));
      _setResult && _setResult(false);
    }
  };

  const setEmailValue = ({ name, value }) => {
    // 아이디, 이메일로 분리된 이메일 값 세팅
    setEmail(prevState => ({
      ...prevState,
      [name]: value,
    }));

    // 메일주소 유효성 검사 및 값 세팅
    let idx = userInfoList.findIndex(item => item.name === "userEmail");
    setInputValue({
      value: `${name === "id" ? value : email.id}@${name === "email" ? value : email.email}`,
      obj: userInfoList[idx],
    });
  };
  const setValidValue = ({ name, isValid, isError }) => {
    // 유효성 검사 후 사용가능/ 에러 flag 세팅
    setUserInfoList(prevItems =>
      prevItems.map(item =>
        item.name === name ? { ...item, isValid, isError } : item,
      ),
    );
  };

  const setInputValue = ({ value, obj }) => {
    // 입력값 세팅
    setUserInfoList(prevItems =>
      prevItems.map(item =>
        item.name === obj.name ? { ...item, value } : item,
      ),
    );

    // userId가 변경될 때마다 중복확인 상태를 false로 초기화
    if (obj.name === "userId") {
      setCheckedUserId(false);
    }

    // 입력받은 값이 미존재일 경우 return
    if (value.trim() === "") {
      setValidValue({
        name: obj.name,
        isValid: false,
        isError: false,
      });
      return;
    }
    // 유효성 검사
    let valid, error;

    switch (obj.name) {
      case "userId":
        ({ valid, error } = validateId(value));
        break;
      case "userEmail":
        ({ valid, error } = validateEmail(value));
        break;
      case "userPhone":
        ({ valid, error } = validatePhone(value));
        break;
      case "password": {
        const confirmPassword = userInfoList.find(
          item => item.name === "confirmPassword" && item.value.trim() !== "",
        )?.value;
        if (confirmPassword) {
          ({ valid, error } = validatePasswordCheck(value, confirmPassword));
          setValidValue({
            name: "confirmPassword",
            isValid: valid,
            isError: error,
          });
        }
        ({ valid, error } = validatePassword(value));
        break;
      }
      case "confirmPassword": {
        const password = userInfoList.find(
          item => item.name === "password",
        )?.value;
        ({ valid, error } = validatePasswordCheck(password, value));
        break;
      }
      default:
        valid = true;
        break;
    }

    setValidValue({
      name: obj.name,
      isValid: valid,
      isError: error,
    });
  };

  const checkId = async e => {
    e.preventDefault();

    const userId = userInfoList.find(item => item.name === "userId");

    if (userId?.value) {
      try {
        const res = await dispatch(checkDuplicateUserId(userId.value));

        const isAvailable = res.payload;
        setCheckedUserId(isAvailable);

        if (isAvailable) {
          dispatch(SET_ALERT_MSG("사용 가능한 아이디입니다."));
        } else {
          dispatch(SET_ALERT_MSG("사용 불가능한 아이디입니다."));
        }

        // if (res?.payload !== "0000") {
        //   const error = setErrorhandler({ status: res?.payload });
        //   dispatch(SET_ALERT_MSG(error.message));
        // }
      } catch (e) {
        setCheckedUserId(false);
        dispatch(SET_ALERT_MSG("사용 불가능한 아이디입니다."));
        console.error(e);
      }
    }
  };

  const handleCheckBoxClick = ({ obj /*, value*/ }) => {
    // 만약 해당 정책이 이미 동의 상태일땐? 그냥 동의만 풀어줌.
    if (obj.accepted) {
      setConsentList(prevItems =>
        prevItems.map(item =>
          item.name === obj.name ? { ...item, accepted: false } : item,
        ),
      );
    } else {
      setConsentList(prevItems =>
        prevItems.map(item =>
          item.name === obj.name ? { ...item, accepted: true } : item,
        ),
      );
    }
    // console.log(value);
    // 클릭된 정책 내용을 담은 모달을 엶
    // setSelectAuth(obj.name);
    // setShowModal(value);
  };
  const justModalOpen = ({ obj, value }) => {
    // 클릭된 정책 내용을 담은 모달을 엶
    setSelectAuth(obj.name);
    setShowModal(value);
  };
  const handleAuthAccept = value => {
    // 모달 내부에서 동의/ 취소 클릭에 따라 체크여부 세팅
    setConsentList(prevItems =>
      prevItems.map(item =>
        item.name === selectAuth ? { ...item, accepted: value } : item,
      ),
    );
    setShowModal(false);
  };
  const isSubmitBtnDisabled = () => {
    // 필수값의 입력/ 체크 여부 + 유효성 검사 통과 여부
    let result = false;

    // userInfoList 검사
    userInfoList.map(item => {
      switch (item.required) {
        case true:
        case "true":
          // 필수값일 때
          if (item.value?.trim() === "" || !item.isValid) {
            result = true;
            return;
          }
          break;
        default:
          break;
      }
    });

    // consentList 검사
    consentList.map(item => {
      switch (item.required) {
        case true:
        case "true":
          if (!item.accepted) {
            result = true;
            return;
          }
          break;
        default:
          break;
      }
    });

    // 아이디 중복확인 여부
    if (!checkedUserId) {
      result = true;
    }

    return result;
  };

  const getNiceModule = async () => {
    try {
      const left = screen.width / 2 - 500 / 2;
      const top = screen.height / 2 - 800 / 2;
      const option = `status=no, menubar=no, toolbar=no, resizable=no, width=500, height=600, left=${left}, top=${top}`;

      let returnUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ""}/blank`;

      const data = await getNiceModuleApi({
        returnUrl,
      });

      if (data) {
        const { enc_data, integrity, token_version_id } = data.output;

        window.open("", "nicePopup", option);

        const formEl = document.getElementById("nicePopup");

        formEl.target = "nicePopup";

        formEl.enc_data.value = enc_data;
        formEl.token_version_id.value = token_version_id;
        formEl.integrity_value.value = integrity;

        formEl.submit();
      }
    } catch (error) {
      console.error(error);
      dispatch(SET_ALERT_MSG(error.message));
    }
  };

  // 컴포넌트가 처음 마운트될 때, 전역 함수 등록
  useEffect(() => {
    // 자식 창에서 phoneNumber 전달 시 호출할 함수
    window.setNice = async data => {
      try {
        const res = await getNiceInfoApi({
          ...data,
        });

        setRequestNo(res.output.requestno);

        setUserInfoList(prevState =>
          prevState.map(item =>
            item.name === "userPhone"
              ? {
                  ...item,
                  value: res.output?.mobileno,
                  isValid: true,
                }
              : item,
          ),
        );
        setUserInfoList(prevState =>
          prevState.map(item =>
            item.name === "userName"
              ? { ...item, value: res.output?.name, isValid: true }
              : item,
          ),
        );

        // 자식 창에서 부모 창의 화면을 바꿀 때 호출할 함수(새로고침 없이)
        window.navigateTo = path => {
          navigate(path);
        };
      } catch (error) {
        console.error(error);
        dispatch(SET_ALERT_MSG(error.message));
      }
    };
  }, [navigate]);

  const userNameComponent = item => {
    return (
      <form onSubmit={e => e.preventDefault()}>
        <FileWrapEl>
          <li>
            <TheInput
              flag="underLine"
              $padding="0.75rem 1.2rem"
              label={item.label}
              type={item.type ?? "text"}
              name={item.name}
              value={item.value}
              // isValid={item.isValid}
              isError={item.isError}
              showRes={true}
              errorMsg={item.requiredMsg}
              required={item.required}
              placeholder={item.placeholder}
              disabled={item.disabled}
              _onChange={e =>
                setInputValue({
                  obj: item,
                  value: e.target.value,
                })
              }
            />
          </li>
          <li>
            <TheButton
              $style="blackLine"
              $padding="0.7rem 0"
              // disabled={item.value === ""}
              _onClick={getNiceModule}
            >
              본인인증
            </TheButton>
          </li>
        </FileWrapEl>
      </form>
    );
  };
  const userIdComponent = item => {
    return (
      <form onSubmit={checkId}>
        <FileWrapEl>
          <li>
            <TheInput
              flag="underLine"
              $padding="0.75rem 0"
              label={item.label}
              type={item.type ?? "text"}
              name={item.name}
              value={item.value}
              // isValid={item.isValid}
              isError={item.isError}
              showRes={true}
              errorMsg={item.requiredMsg}
              required={item.required}
              placeholder={item.placeholder}
              _onChange={e =>
                setInputValue({
                  obj: item,
                  value: e.target.value,
                })
              }
            />
          </li>
          <li>
            <TheButton
              $style="blackLine"
              $padding="0.7rem 0"
              disabled={item.value === "" || item.isError}
              type="submit"
            >
              중복확인
            </TheButton>
          </li>
        </FileWrapEl>
      </form>
    );
  };
  const userEmailComponent = item => {
    return (
      <TheInput
        flag="label_custom"
        label={item.label}
        isValid={item.isValid}
        isError={item.isError}
        required={item.required}
      >
        <EmailWrapEl isValid={item.isValid} isError={item.isError}>
          <li>
            <TheInput
              $padding="0.75rem 0"
              flag="underLine"
              type="text"
              name="id"
              value={email.id}
              // isValid={item.isValid}
              isError={item.isError}
              required={item.required}
              placeholder={item.placeholder}
              _onChange={e =>
                setEmailValue({
                  name: e.target.name,
                  value: e.target.value,
                })
              }
            />
          </li>
          <li>
            <span style={{ paddingTop: 0, textAlign: "center" }}>@</span>
          </li>
          <li>
            <TheInput
              $padding="0.75rem 0"
              flag="underLine"
              type="text"
              name="email"
              value={email.email}
              // isValid={item.isValid}
              isError={item.isError}
              required={item.required}
              placeholder={item.placeholder}
              _onChange={e =>
                setEmailValue({
                  name: e.target.name,
                  value: e.target.value,
                })
              }
            />
          </li>
          <li></li>
          <li>
            <TheDropBox
              $type="underline"
              $padding="0.75rem 0.3rem"
              $width="100%"
              initialOption="직접 입력"
              options={emailList}
              onOptionSelect={e =>
                setEmailValue({
                  name: "email",
                  value: e.value,
                })
              }
            />
          </li>
        </EmailWrapEl>
      </TheInput>
    );
  };

  // useEffect(() => {
  //   try {
  //     dispatch(getServiceContent());
  //   } catch (e) {
  //     console.error(e);
  //     const errorDetails = setErrorhandler(e?.response || e);
  //     SET_ALERT_MSG(errorDetails.message);
  //   }
  // }, [dispatch]);

  return (
    <>
      <TheBox $width="31.25rem" $height="max-contents" $margin="2rem auto">
        <ul>
          {userInfoList.map((item, idx) => (
            <LiEl key={idx}>
              {(() => {
                switch (item.name) {
                  case "userName":
                    return userNameComponent(item);
                  case "userId":
                    return userIdComponent(item);
                  case "userEmail":
                    return userEmailComponent(item);
                  default:
                    return (
                      <TheInput
                        $padding={
                          item.name !== "userPhone"
                            ? "0.75rem 0"
                            : "0.75rem 1.2rem"
                        }
                        flag="underLine"
                        label={item.label}
                        type={item.type ?? "text"}
                        name={item.name}
                        value={item.value}
                        // isValid={item.isValid}
                        showRes={true}
                        errorMsg={item.requiredMsg}
                        isError={item.isError}
                        disabled={item.disabled}
                        required={item.required}
                        placeholder={item.placeholder}
                        _onChange={e =>
                          setInputValue({
                            obj: item,
                            value: e.target.value,
                          })
                        }
                      />
                    );
                }
              })()}
            </LiEl>
          ))}
          {consentList.map((item, idx) => (
            <CheckBoxLiEl key={`${idx}_consent`}>
              <TheCheckBox
                id={item.name}
                label={item.text}
                checked={item.accepted}
                $color="#111111"
                $checkColor="#111111"
                $margin={idx !== 2 ? "0 0 0.5rem" : "0"}
                _onChange={e =>
                  handleCheckBoxClick({
                    e,
                    obj: item,
                    value: true,
                  })
                }
              />
              <button onClick={() => justModalOpen({ obj: item, value: true })}>
                &gt;
              </button>
            </CheckBoxLiEl>
          ))}
        </ul>
        <TheButton
          disabled={isSubmitBtnDisabled()}
          type="button"
          $width="9.75rem"
          $margin="2rem auto 0"
          _onClick={onChangeFormLib}
        >
          다음
        </TheButton>
      </TheBox>
      {showModal && (
        <UserConsentModal
          type="alert"
          authList={authList}
          selectAuth={selectAuth}
          src={authList[selectAuth].src}
          _justClose={() => handleAuthAccept(false)}
          _acceptClose={() => handleAuthAccept(false)}
        />
      )}
      {/*나이스*/}
      <form
        name="nicePopup"
        id="nicePopup"
        action="https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb"
        method="POST"
        style={{ display: "none" }}
      >
        <input type="hidden" id="m" name="m" value="service" />
        <input type="hidden" id="token_version_id" name="token_version_id" />
        <input type="hidden" id="enc_data" name="enc_data" />
        <input type="hidden" id="integrity_value" name="integrity_value" />
      </form>
    </>
  );
};

const LiEl = styled.li`
  margin-bottom: 1.5rem;
`;
const EmailWrapEl = styled.ul.withConfig({
  shouldForwardProp: prop => !["isValid", "isError"].includes(prop),
})`
  display: grid;
  grid-template-columns: 8.5rem 0.938rem 8.5rem 0.062rem 7.75rem;
  gap: 0.25rem;

  & span {
    display: inline-block;
    line-height: 2.375rem;
    /*line-height: 2.688rem;*/
    /* 적절하지 않음 */
    ${({ isError }) =>
      isError &&
      ` color: rgba(220, 0, 0, 1);
    `}
  }
`;
const CheckBoxLiEl = styled.li`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > button {
    border: none;
    background-color: transparent;
    color: #2f2f2f;
    transition: color ease-in-out 0.3s;
  }
  & > button:hover {
    color: #3b4ddd;
  }
`;

const FileWrapEl = styled.ul.withConfig({
  shouldForwardProp: prop => !["isValid", "isError"].includes(prop),
})`
  display: grid;
  grid-template-columns: 19.688rem 6.5rem;
  gap: 0.5rem;

  & li:nth-child(2) {
    align-self: end;
    margin-bottom: 0.2rem;
  }
`;

export default UserForm;
