import { fontSize } from "./fontSize";

export const lightTheme = {
  size: { ...fontSize },
  color: {
    blue: {
      100: "#77B4F0",
      200: "",
      300: "#3B4DD3",
      400: "#3B4DDD",
      500: "#2c48c9",
      600: "",
      700: "#31448d",
      800: "rgba(28, 37, 115, 1)",
      80: "rgba(59, 77, 211, 0.8)",
    },
    gray: {
      100: "#f4f4f5",
      200: "#f5f5f5",
      300: "#E5E5EC",
      400: "#E6E6E6",
      450: "#e1e1e1",
      470: "#E0E0E7",
      500: "#D3D6DC",
      600: "#999999",
      700: "#898F8F",
      800: "#878d96",
      900: "#747982",
      950: "#767676",
      50: "rgba(153, 153, 153, 0.5)",
    },
    white: {
      300: "",
      400: "#ffffff",
      500: "#f7f7fb",
      600: "#F1F1F5",
      700: "",
      800: "#e5e5e5",
      900: "#dbdbe4",
      30: "rgba(255, 255, 255, 0.3)",
      50: "rgba(255, 255, 255, 0.5)",
      70: "rgba(255, 255, 255, 0.7)",
    },
    black: {
      200: "#555555",
      300: "#505050",
      350: "#333333",
      400: "#111111",
      500: "#181818",
      900: "#000000",
    },
    red: {
      200: "#EF8EA3FF",
      300: "#d38b9a",
      400: "#e11d48",
      500: "#dc0000",
      600: "",
      700: "#a81735",
    },
    transparent: {
      300: "transparent",
    },
    shadow: {
      400: "#0000000a",
      500: "#1111110d",
      600: "#0000000f",
      46: "rgba(21, 21, 21, 0.46)",
    },
    checkbox: {
      defaultBorder: "#E5E5ECFF",
      disabledBackground: "F1F1F5FF",
    },
    mainSix: {
      default: "#1C2573;",
    },
  },
};

export const darkTheme = {
  size: { ...fontSize },
  color: {
    blue: {
      100: "",
      200: "",
      300: "",
      400: "",
      500: "",
      600: "",
      700: "",
      80: "",
    },
    gray: {
      100: "",
      200: "",
      300: "",
      400: "",
      450: "",
      470: "",
      500: "",
      600: "",
      700: "",
      800: "",
      900: "",
      950: "",
      50: "",
    },
    white: {
      300: "",
      400: "#111111",
      500: "",
      600: "",
      700: "",
      800: "",
      900: "",
      30: "",
      50: "",
      70: "",
    },
    black: {
      200: "",
      300: "",
      350: "",
      400: "#ffffff",
      500: "",
      900: "",
    },
    red: {
      200: "",
      300: "",
      400: "",
      500: "",
      600: "",
      700: "",
    },
    transparent: {
      300: "transparent",
    },
    shadow: {
      400: "#0000000a",
      500: "#1111110d",
      600: "#0000000f",
      46: "rgba(21, 21, 21, 0.46)",
    },
    checkbox: {
      defaultBorder: "#E5E5ECFF",
      disabledBackground: "F1F1F5FF",
    },
  },
};
