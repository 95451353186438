import { api } from "../index";

export const withdrawApi = async data => {
  try {
    const res = await api.post("/user/api/v1/myaccount/termination", data);
    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const getMyListApi = async data => {
  try {
    const res = await api.post("/api/v1/subscriptions/mylist", data);
    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const unsubscribeApi = async data => {
  try {
    const res = await api.post("/api/v1/subscriptions/cancellation", data);
    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const changePwdApi = async data => {
  try {
    const res = await api.post("/user/api/v1/myaccount/info/auth", data);
    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const changeUserInfoApi = async data => {
  try {
    const res = await api.post("/user/modifyUserInfo\n", data);
    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
