import React from "react";
import styled from "styled-components";

import { TheSection } from "../../components";
import BusinessTosInfo from "./BusinessTosInfo";
import BusinessUsageInfo from "./BusinessUsageInfo";

const BusinessTosPage = () => {
  const companyInfo = {
    companyName: "(주)틸론",
    period: "2025.03.01 ~ 2025.03.31",
    paymentDay: "매월 1일",
    nextPaymentDay: "2025년 4월 1일",
  };

  const usageInfo = [
    { name: "TOS Basic", count: 10 },
    { name: "TOS Standard", count: 5 },
    { name: "TOS Standard", count: 5 },
    { name: "TOS Standard", count: 5 },
    { name: "TOS Standard", count: 5 },
    { name: "TOS Standard", count: 5 },
    { name: "TOS Standard", count: 5 },
  ];

  return (
    <TheSection>
      <BusinessContainer>
        <BusinessTosInfo companyInfo={companyInfo} />
        <BusinessUsageInfo usageInfo={usageInfo} />
      </BusinessContainer>
    </TheSection>
  );
};

const BusinessContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
export default BusinessTosPage;
