import React from "react";
import { useSelector } from "react-redux";

import { ReactComponent as Exclamation } from "../../../assets/svg/exclamationMark.svg";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import styled from "styled-components";

// Chart.js에 필요한 구성 요소
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
);

const IndividualPatternChart = () => {
  const { usagePattern } = useSelector(state => state.mytos);

  // month 값을 기준으로 정렬 (오름차순)
  const sortedUsagePattern = usagePattern
    ? [...usagePattern].sort((a, b) => new Date(a.month) - new Date(b.month))
    : [];

  // 월별 데이터
  const monthLabels = sortedUsagePattern?.map(item => {
    const month = new Date(item.month);
    return month.toLocaleString("default", { month: "short" }).toUpperCase();
  });

  const usageData = sortedUsagePattern?.map(item => item.total);

  // 가장 최근 데이터의 인덱스
  const latestIndex = usageData?.length - 1;

  // 막대 색상 배열 설정 (가장 최근 데이터를 초록색으로 설정)
  const backgroundColors = usageData?.map((_, index) =>
    index === latestIndex ? "#2DD4BF" : "#CACAD7",
  );

  // 차트 데이터
  const data = {
    labels: monthLabels, // 6개월 월 표시
    datasets: [
      {
        label: "횟수", // 데이터 이름
        data: usageData.map(value => (value > 40 ? 40 : value)), // 40을 넘는 값은 40으로 제한
        backgroundColor: backgroundColors, // 각 막대의 색상 배열
        borderRadius: 10, // 막대 끝부분 둥글게
        borderColor: "#CACAD7", // 막대 테두리 색상
        borderWidth: 0, // 막대 테두리 두께
        barThickness: 16, // 막대의 두께
      },
    ],
  };

  // 차트 옵션
  const options = {
    responsive: true, // 반응형 차트
    plugins: {
      legend: {
        display: false, // 범례 숨기기
      },

      tooltip: {
        callbacks: {
          label: tooltipItem => {
            // 툴팁에서 값이 40을 넘으면 '40+'로 표시
            return tooltipItem.raw >= 40 ? "40+" : `${tooltipItem.raw} 회`;
          },
        },
      },
      datalabels: {
        display: true, // 레이블을 항상 표시하도록 설정
        color: "black", // 데이터 레이블 색상
        font: {
          size: 12, // 폰트 크기
          weight: "400", // 폰트 두께
        },
        formatter: value => {
          // 데이터 레이블에서 40 넘는 값은 '40+'으로 표시
          return value >= 40 ? "40+" : `${value}회`;
        },
        anchor: "end", // 레이블을 막대의 끝에 위치시킴
        align: "top", // 막대 위쪽에 표시
        offset: 5, // 막대와 레이블 간의 거리 조정
        padding: 1, // 레이블과 막대 간의 여백
      },
    },
    scales: {
      y: {
        beginAtZero: true, // Y축 0부터 시작
        max: 50, // 최대 값 100으로 설정
        display: false, // Y축 표시
        ticks: {
          stepSize: 10, // Y축 눈금 간격
        },
        grid: {
          display: false, // Y축 그리드 표시
        },
      },
      x: {
        display: true, // X축 표시
        grid: {
          display: false, // X축 그리드 숨기기
        },
        ticks: {
          font: {
            size: 12,
            weight: "400",
            color: "#999999", // X축 눈금 글자 색상
          },
        },
      },
    },
  };

  if (!usagePattern || usagePattern.length === 0) {
    return (
      <NoData>
        <Exclamation />
        <p>데이터가 존재하지 않습니다.</p>
      </NoData>
    );
  }

  return <Bar data={data} options={options} height={191} width={478} />;
};

const NoData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 1rem;
`;

export default IndividualPatternChart;
