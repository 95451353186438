import styled, { css } from "styled-components";
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { ReactComponent as TitleLogo } from "../../assets/svg/titleLogo.svg";
import { ReactComponent as HamburgerIcon } from "../../assets/svg/hamburger.svg";

import TheButton from "../common/TheButton";
import { todayColor } from "../../utils/custom/todayNumber";
import TheNavigation from "./TheNavigation";
import { useClickOutside } from "../../hooks";

const TheHeader = () => {
  const isLogin = useSelector(state => state.auth.userAuth);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const hamburgerRef = useRef(null);
  const navRef = useRef(null);

  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const isHome = location.pathname === "/home";

  useEffect(() => {
    const handleScroll = () => {
      if (isHome) {
        const firstSectionHeight = window.innerHeight;
        if (window.scrollY >= firstSectionHeight) {
          setIsScrolled(true);
        } else {
          setIsScrolled(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isHome]);

  useClickOutside(navRef, () => setIsNavOpen(false), [hamburgerRef]);

  return (
    <HeaderEl $pathname={pathname} $isScrolled={isScrolled}>
      <InnerContainer>
        <Title
          $pathname={pathname}
          $today={todayColor(location?.pathname)}
          $isScrolled={isScrolled}
        >
          <Link to={isLogin ? "/mytos" : "/home"}>
            <TitleLogo />
          </Link>
        </Title>
        <HamburgerWrap $today={todayColor(location?.pathname)}>
          {isHome && (
            <div className="btnWrap">
              <TheButton
                $padding="0.75rem 1rem"
                $width="8.125rem"
                $fontSize="1rem"
                $border="0.125rem solid rgba(255, 255, 255, 0.5)"
                $fontWeight="400"
                $radius="0.375rem"
                style="gap:0.625rem"
                _onClick={() => navigate("/join/user")}
              >
                Register
              </TheButton>
              <TheButton
                $style="tryNow"
                $padding="0.75rem 1rem"
                $width="8.125rem"
                $fontSize="1rem"
                $fontWeight="400"
                $radius="0.375rem"
                style="gap:0.625rem"
                _onClick={() => navigate("/apply")}
              >
                Try Now
              </TheButton>
            </div>
          )}
          <StyledHamburgerIcon
            ref={hamburgerRef}
            $pathname={pathname}
            $isNavOpen={isNavOpen}
            $isScrolled={isScrolled}
            $today={todayColor(location?.pathname)}
            onClick={() => setIsNavOpen(prev => !prev)}
          />
          <TheNavigation
            navLinks={navRef}
            isOpen={isNavOpen}
            onClose={() => setIsNavOpen(false)}
          />
        </HamburgerWrap>
      </InnerContainer>
    </HeaderEl>
  );
};

const HeaderEl = styled.header.withConfig({
  shouldForwardProp: prop => !["$pathname", "$isScrolled"].includes(prop),
})`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  width: 100%;

  ${({ $pathname, $isScrolled }) =>
    $pathname !== "/home" || $isScrolled
      ? css`
          background-color: ${({ theme: { color } }) => color.white[400]};
          box-shadow: 0 1rem 1rem 0
            ${({ theme: { color } }) => color.shadow[400]};
        `
      : css`
          background-color: transparent;
          box-shadow: none;
        `}
`;

const Title = styled.h1`
  & a {
    display: inline-block;
    text-decoration: none;

    ${({ $pathname, $isScrolled }) =>
      $pathname !== "/home" || $isScrolled
        ? css`
            & svg {
              width: 12.406rem;
              height: 1.5rem;
              fill: ${({ theme: { color } }) => color.black[400]};
            }
          `
        : css`
            & svg {
              width: 12.406rem;
              height: 1.5rem;
              fill: ${({ $today }) => $today?.title};
            }
          `}
  }
`;

const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2.25rem;
  height: 5rem;
  position: relative;
  min-width: 75rem;
`;

const HamburgerWrap = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: end;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  & .btnWrap {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    width: 100%;
    justify-content: end;
  }

  & svg {
    width: 2.25rem;
    height: 2.25rem;
  }
`;

const StyledHamburgerIcon = styled(HamburgerIcon).withConfig({
  shouldForwardProp: prop =>
    !["$pathname", "isNavOpen", "$isScrolled", "$today"].includes(prop),
})`
  ${({ $pathname, $isScrolled, $today, theme: { color } }) => {
    if ($pathname !== "/home" || $isScrolled) {
      return css`
        stroke: ${color.black[400]};
      `;
    }
    return css`
      stroke: ${$today ? $today.title : color.black[400]};
    `;
  }}
  width: 2.25rem;
  height: 2.25rem;
  cursor: pointer;
`;

export default TheHeader;
