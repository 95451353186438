import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  dupIdCheckApi,
  serviceContentApi,
  signupApi,
} from "../../../services/controller/signupController";

// initialState
const initialState = {
  isLoading: false,
  authList: [],
};

// Actions(Api)
export const registerUser = createAsyncThunk(
  "signupSlice/registerUser",
  async (signUpInfo, { rejectWithValue }) => {
    try {
      const res = await signupApi(signUpInfo);
      return res.output;
    } catch (error) {
      return rejectWithValue(error.response?.status || "An error occurred");
    }
  },
);

export const checkDuplicateUserId = createAsyncThunk(
  "signupSlice/checkDuplicateUserId",
  async (userId, { rejectWithValue }) => {
    try {
      const res = await dupIdCheckApi({ userId });
      return res.output;
    } catch (error) {
      return rejectWithValue(error.response?.status || "An error occurred");
    }
  },
);

export const getServiceContent = createAsyncThunk(
  "signupSlice/getServiceContent",
  async (_, { rejectWithValue }) => {
    try {
      const res = await serviceContentApi();
      const list = res.output;
      const updatedData = {};

      list.forEach(item => {
        switch (item.agreement_type) {
          case "PRIVACY":
            updatedData["privacyTermsAgreed"] = {
              ...item,
              title: "개인정보처리방침",
            };
            break;
          case "PROMOTION":
            updatedData["promotionTermsAgreed"] = {
              ...item,
              title: "마케팅 동의",
            };
            break;
          case "TERMS":
            updatedData["serviceTermsAgreed"] = {
              ...item,
              title: "이용약관",
            };
            break;
          default:
            break;
        }
      });
      return updatedData;
    } catch (error) {
      return rejectWithValue(error.response?.status || "An error occurred");
    }
  },
);

const signupSlice = createSlice({
  name: "signupSlice",
  initialState,
  // reducers
  reducers: {
    SET_CHECK_USERID: (state, action) => {
      state.isUserId = action.payload;
    },
  },
  // extraReducers: 비동기 상태 처리
  extraReducers: builder => {
    builder
      // 회원가입
      .addCase(registerUser.pending, state => {
        state.isLoading = true;
      })
      .addCase(registerUser.fulfilled, state => {
        state.isLoading = false;
      })
      .addCase(registerUser.rejected, state => {
        state.isLoading = false;
      })

      // 아이디 중복
      .addCase(checkDuplicateUserId.pending, state => {
        state.isLoading = true;
      })
      .addCase(checkDuplicateUserId.fulfilled, state => {
        state.isLoading = false;
      })
      .addCase(checkDuplicateUserId.rejected, state => {
        state.isLoading = false;
      })

      // 약관 불러오기
      .addCase(getServiceContent.pending, state => {
        state.isLoading = true;
      })
      .addCase(getServiceContent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.authList = action.payload;
      })
      .addCase(getServiceContent.rejected, state => {
        state.isLoading = false;
      });
  },
});

// export const { serviceContent } = signupSlice.actions;

export default signupSlice.reducer;
