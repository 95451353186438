import { api } from "../index";

export const getFindIdApi = async data => {
  try {
    const response = await api.post("/user/api/v1/account/find", data);

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getFindPwApi = async data => {
  try {
    const response = await api.post("/user/api/v1/account/reset-auth", data);

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
