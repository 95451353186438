/**
 * 드랍다운 박스
 * @example
 * // 사용 예시
 * // 0 : 일요일
 * // 1 : 월요일
 * // 2 : 화요일
 * // 3 : 수요일
 * // 4 : 목요일
 * // 5 : 금요일
 * // 6 : 토요일
 * */

export const todayNumber = () => {
  const today = new Date();
  return today?.getDay() || 0;
};

export const todayColor = pathname => {
  if (pathname !== "/home") {
    return;
  }

  try {
    const today = new Date();
    const day = today?.getDay() || 0;

    switch (day) {
      case 1:
      case 3:
      case 5:
      case 6:
        return {
          logo: "#ffffff",
          title: "#ffffff",
          sub: "#ffffff",
        };
      case 2:
        return {
          logo: "#111111",
          title: "#111111",
          sub: "#3B4DD3",
        };
      case 4:
        return {
          logo: "#111111",
          title: "#ffffff",
          sub: "#ffffff",
        };
      case 0:
        return {
          logo: "#ffffff",
          title: "#ffffff",
          sub: "#111111",
        };
      // case   6:
      default:
        return {
          logo: "#111111",
          title: "#111111",
          sub: "#111111",
        };
    }
  } catch (error) {
    console.error(error);
  }
};
