import { api } from "../index";

export const getNiceModuleApi = async data => {
  try {
    const response = await api.post("/nice/crypto/token", data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getNiceInfoApi = async data => {
  try {
    const response = await api.post("/nice/success", data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
