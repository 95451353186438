import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { TheBox, TheButton, TheSection } from "../../../components";

import ManualImg from "../../../assets/images/manualBg.png";

const DownloadPage = () => {
  const { t } = useTranslation("download");

  const osList = [
    {
      name: t("osName-1"),
      desc: t("osDesc-1"),
    },
    {
      name: t("osName-2"),
      desc: t("osDesc-2"),
    },
    {
      name: t("osName-3"),
      desc: t("osDesc-3"),
    },
    {
      name: t("osName-4"),
      desc: t("osDesc-4"),
    },
  ];

  const onDownloadClick = type => {
    let url = process.env.REACT_APP_API_URL
      ? `${process.env.REACT_APP_API_URL}`
      : `${window.location.protocol}//${window.location.hostname}/tosapi`;

    if (type === "linker") {
      const downloadUrl = `${url}/webfile/InstallFile`;
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = "";
      link.click();
      link.remove();
    } else {
      const downloadUrl = `${url}/webfile/user-manual`;
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = "";
      link.click();
      link.remove();
    }
  };

  return (
    <TheSection>
      <Container>
        <TitleCont>
          <p>
            {t("title-1")}
            <span>{t("title-2")}</span>
          </p>
        </TitleCont>
        <CardList>
          {osList?.map((item, idx) => (
            <TheBox className="card" key={idx} $padding="2rem 2.25rem">
              <div>
                <strong>{item?.name}</strong>
              </div>
              <p>{item?.desc}</p>
              <TheButton
                $style="primaryLine"
                name={item?.name}
                disabled={item?.name !== "Windows"}
                _onClick={() => onDownloadClick("linker")}
              >
                {t("download")}
              </TheButton>
            </TheBox>
          ))}
        </CardList>
        <ManualCont>
          <p>{t("manualDesc")}</p>
          <TheButton
            $width="9.75rem"
            $padding="0.75rem"
            _onClick={() => onDownloadClick("manual")}
          >
            {t("manualDownload")}
          </TheButton>
        </ManualCont>
      </Container>
    </TheSection>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TitleCont = styled.div`
  text-align: center;
  font-size: 1.75rem;
  line-height: 2.088rem;
  margin-bottom: 2rem;
  & span {
    display: block;
    font-weight: 600;
  }
`;

const CardList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.25rem;

  margin-bottom: 2rem;

  & .card {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 0.062rem solid ${({ theme: { color } }) => color.gray[400]};
    & > div {
      width: 100%;
      text-align: center;
      border-bottom: 0.062rem solid #eee;
      & > strong {
        display: block;
        font-size: 1.375rem;
        font-weight: 500;
        padding-bottom: 0.5rem;
      }
    }
    & p {
      color: ${({ theme: { color } }) => color.gray[950]};
      font-size: 0.875rem;
      font-weight: 500;
      padding: 0.813rem 0;
      margin-bottom: 1.563rem;
    }
  }
`;

const ManualCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(${ManualImg});
  background-size: cover;
  background-repeat: no-repeat;
  padding: 2.25rem 1rem;
  border-radius: 1.25rem;
  overflow: hidden;
  & > p {
    font-size: 1.25rem;
    font-weight: 500;
    color: #fff;
    margin-bottom: 1.5rem;
  }
`;

export default DownloadPage;
