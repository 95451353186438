import axios from "axios";
import store from "../features/store";
import { jwtDecode } from "jwt-decode";

import removeBeforeFirstDelimiter from "./useRemoveBeforeFirstDelimiter";

import { SET_USER_INFO, USER_LOGOUT } from "../features/main/shared/authSlice";
import { SET_SESSION_EXPIRED } from "../features/main/shared/commonSlice";

/*
 * 사용자 토큰의 유효시간이 1분 이하로 남았을 경우
 * refreshToken api 호출-> 토큰 변경
 * privateRoute 설정된 route 진입 시 or 인증 api 호출 시 항시 검사 필요
 **/
export default async function refreshAccessToken(token) {
  const { userInfo } = store.getState().auth;

  const decodedToken = jwtDecode(token);
  const unixTimestamp = decodedToken.exp * 1000;
  const tokenDate = new Date(unixTimestamp);
  const nowDate = new Date();
  const differenceInMinutes = (tokenDate - nowDate) / 1000 / 60;

  if (differenceInMinutes <= 1) {
    try {
      const { protocol, hostname } = window.location;
      const baseUrl =
        process.env.REACT_APP_API_URL || `${protocol}//${hostname}/tosapi`;

      const { UserAlias, UserLevel, refreshToken } = userInfo;

      const { data } = await axios.post(
        `${baseUrl}/user/refresh`,
        { refreshToken },
        {
          headers: {
            "Content-Type": "application/json",
            accessToken: token,
            userid: removeBeforeFirstDelimiter(UserAlias),
            UserLevel,
            Language: "KR",
          },
        },
      );

      switch (data.status) {
        case "0000":
        case "5000": // 새 토큰 발급 성공
          store.dispatch(
            SET_USER_INFO({
              ...userInfo,
              accessToken: data.accessToken,
            }),
          );
          return;
        default: // 실패
          store.dispatch(
            SET_SESSION_EXPIRED({
              show: "true",
              content: "로그인이 만료되었습니다.",
            }),
          );
          store.dispatch(USER_LOGOUT());
          return;
      }
    } catch (e) {
      // 실패
      console.error(e);
      store.dispatch(
        SET_SESSION_EXPIRED({
          show: "true",
          content: "로그인이 만료되었습니다.",
        }),
      );
      store.dispatch(USER_LOGOUT());
    }
  }
}
