const removeBeforeFirstDelimiter = (str, delimiter = "_") => {
  const index = str.indexOf(delimiter);
  // 구분자가 없으면 그대로 반환
  if (index === -1) {
    return str;
  }
  // 구분자 뒤부터 끝까지 슬라이스
  return str.slice(index + 1);
};

export default removeBeforeFirstDelimiter;
