import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { TheBox, TheToggle2 } from "../../../components";
import FindIdStepBox from "./FindIdStepBox";

import useFormValidation from "../../../hooks/useFormValidation";
import { SET_ALERT_MSG } from "../shared/commonSlice";

const FindIdPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { type } = useParams();

  // const [loginType, setLoginType] = useState(
  //   type === "0" ? "Individual" : "Business",
  // );
  // const loginType = type === "0" ? "Individual" : "Business"; 이걸로 나중에 바까줘ㅗ요
  const loginType = type === "0" ? "Individual" : "Individual";

  // 상태 관리
  const [userInfo, setUserInfo] = useState({
    userName: "",
    telNum: "",
    manager: "",
    regNum: "",
  });
  const [currentStep, setCurrentStep] = useState(0);

  // 유효성 훅 사용
  const { validateEmail, isRegNumError, validateRegNum } = useFormValidation();

  // const handleTypeSwitch = newType => {
  //   setLoginType(newType);
  //   const newTypeParam = newType === "Individual" ? "0" : "1";
  //   navigate(`/find/id/${newTypeParam}`);
  //   localStorage.setItem("accountType", newType);
  //   resetAllData();
  // };

  // const resetAllData = () => {
  //   setUserInfo({
  //     userName: "",
  //     telNum: "",
  //     manager: "",
  //     regNum: "",
  //   });
  //   setCurrentStep(0);
  // };

  // 나이스 본인인증
  const onNiceAuth = () => {
    setCurrentStep(1);
  };

  const goFindPw = () => {
    navigate("/find/password/${accountType}");
  };

  return (
    <TheBox $width="31.25rem" $height="max-contents" padding="2.25rem">
      <FindInputWrap>
        {currentStep === 0 && (
          <ToggleDiv>
            <TheToggle2
              options={["Individual", "Business"]}
              selected={loginType}
              onChange={() =>
                dispatch(
                  SET_ALERT_MSG("3월 7일 정식 오픈일까지 \n잠시 기다려 주세요"),
                )
              }
              // onChange={selectedType => handleTypeSwitch(selectedType)}
            />
          </ToggleDiv>
        )}

        <FindIdStepBox
          currentStep={currentStep}
          userInfo={userInfo}
          setUserInfo={setUserInfo}
          isRegNumError={isRegNumError}
          validateEmail={validateEmail}
          validateRegNum={validateRegNum}
          onNiceAuth={onNiceAuth}
          goFindPw={goFindPw}
          type={type}
        />
      </FindInputWrap>
    </TheBox>
  );
};

const FindInputWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2.25rem;
  gap: 1.5rem;
  margin: 0 auto;
  white-space: pre-line;
`;

const ToggleDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export default FindIdPage;
