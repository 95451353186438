import React, { useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

/**
 * 탭 컴포넌트
 * @example
 * <TheTabs
 *   tabs={[
 *     { label: "탭 1", content: <div>탭 1 콘텐츠</div> },
 *     { label: "탭 2", content: <div>탭 2 콘텐츠</div> },
 *   ]}
 *   defaultActiveTab={0}
 *   onTabChange={(activeIndex) => console.log(activeIndex)}
 *   disabled={false}
 *   noContent="다른 문구" // 커스텀 NO CONTENT
 *   $width="400px"
 *   $contentMinHeight="400px" // 탭에 해당하는 콘텐츠 박스 높이
 *   $backgroundColor="none"
 * />
 */
const TheTab = ({
  tabs = [],
  defaultActiveTab = 0,
  onTabChange,
  disabled = false,
  noContent,
  contentUsed = true,
  $width = "31.25rem",
  $contentMinHeight = "31.25rem",
  $backgroundColor,
}) => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(defaultActiveTab);
  const handleTabClick = index => {
    if (disabled) return;
    setActiveTab(index);
    if (onTabChange) onTabChange(index);
  };

  const tabCount = tabs.length;

  const gridTemplateColumns = () => {
    if (tabCount === 2) {
      return "1fr 1fr"; // 탭이 두 개일 때
    } else if (tabCount >= 3) {
      return "1fr 1fr 1fr"; // 탭이 세 개 이상일 때
    }
  };

  return (
    <TabsContainer $width={$width}>
      <TabList
        $backgroundColor={$backgroundColor}
        gridTemplateColumns={gridTemplateColumns()}
      >
        {tabs.map((tab, index) => (
          <li
            key={index}
            className={`${location.pathname.includes(tab.id) ? "active" : ""}`}
            onClick={() => handleTabClick(index)}
          >
            {tab.label}
          </li>
        ))}
      </TabList>
      {contentUsed && (
        <TabContent $contentMinHeight={$contentMinHeight}>
          {tabs[activeTab]?.content || noContent || "콘텐츠 없음"}
        </TabContent>
      )}
    </TabsContainer>
  );
};

const TabsContainer = styled("div").withConfig({
  shouldForwardProp: prop => !["isOpen", "selectedOption"].includes(prop),
})`
  width: ${({ $width }) => $width};
  height: 100%;
`;

const TabList = styled.ul.withConfig({
  shouldForwardProp: prop => !["gridTemplateColumns"].includes(prop),
})`
  display: grid;
  grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns};
  background: ${({ $backgroundColor, theme: { color } }) =>
    $backgroundColor || color.white[400]};

  & > li {
    cursor: pointer;
    color: ${({ theme: { color } }) => color.gray[600]};
    width: 100%;
    height: 2.75rem;
    padding: 1rem 0;
    text-align: center;
    letter-spacing: -0.025rem;
    border-bottom: 0.062rem solid ${({ theme: { color } }) => color.gray[500]};

    &.active {
      font-weight: 600;
      color: ${({ theme: { color } }) => color.black[400]};
      border-bottom: 0.125rem solid
        ${({ theme: { color } }) => color.black[400]};
    }
  }
`;

const TabContent = styled.div`
  width: 100%;
  height: ${({ $contentMinHeight }) => $contentMinHeight};
`;
export default TheTab;
