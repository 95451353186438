import { useDispatch, useSelector } from "react-redux";
import { useLocation, Outlet, useNavigate } from "react-router-dom";

import GlobalStyle from "../../styles/css/GlobalStyle";
import styled, { ThemeProvider } from "styled-components";
import { darkTheme, lightTheme } from "../../styles/css/theme";

import { TheHeader, TheFooter, TheModal } from "../../components";
import { DELETE_ALERT_MSG, SET_SESSION_EXPIRED } from "./shared/commonSlice";

function Root() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { darkMode, alertMsg, sessionExpiration } = useSelector(
    state => state.common,
  );
  const location = useLocation();

  const handleCloseModal = () => {
    dispatch(DELETE_ALERT_MSG());
  };

  const goToHome = () => {
    navigate("/home");
    dispatch(SET_SESSION_EXPIRED({ show: false, content: "" }));
  };

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <Wrap $pathname={location?.pathname}>
        <TheHeader />
        <MainEl>
          <Outlet />
          {alertMsg && (
            <TheModal
              type="alert"
              $width="25rem"
              $padding="1.25rem 1.5rem"
              content={alertMsg}
              _justClose={handleCloseModal}
              _acceptClose={handleCloseModal}
            />
          )}
          {sessionExpiration.show && (
            <TheModal
              type="alert"
              $width="25rem"
              $padding="1.25rem 1.5rem"
              content={sessionExpiration.content}
              _justClose={goToHome}
              _acceptClose={goToHome}
            />
          )}
        </MainEl>
        <GlobalStyle />
        {location?.pathname !== "/home" && <TheFooter />}
      </Wrap>
    </ThemeProvider>
  );
}

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
`;

const MainEl = styled.main`
  flex: 1;
  width: 100%;
  background-color: ${({ theme: { color } }) => color.gray[200]};
`;

export default Root;
