import React, { useRef, useState } from "react";
import styled from "styled-components";

// assets
import { ReactComponent as UpArrow } from "../../assets/svg/upArrow.svg";
import { ReactComponent as DownArrow } from "../../assets/svg/downArrow.svg";
import { ReactComponent as DisabledDownArrow } from "../../assets/svg/disabledDownArrow.svg";
import { useClickOutside } from "../../hooks";

/**
 * 드랍다운 박스
 * @example
 * // 사용 예시
 * <TheDropBox
 *   initialOption="기본값"
 *   options={[
 *     { label: "옵션1", value: 1 },
 *     { label: "옵션2", value: 2 },
 *     { label: "옵션3", value: 3 },
 *   ]}
 *   onOptionSelect={(selectedOption) => console.log(selectedOption)}
 *   disabled={false}
 *   $width="200px"
 *   $height="50px"
 * />
 * */
const TheDropBox = ({
  initialOption = "메뉴 선택",
  options = [],
  onOptionSelect,
  disabled = false,
  $type = "default",
  $width = "18.75rem",
  $height = "2.75rem",
  $mHeight = "15.625rem",
  $padding = "0 1rem",
  $fontSize,
}) => {
  const dropDownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(initialOption);
  const Arrow = isOpen
    ? UpArrow
    : disabled
      ? DisabledDownArrow
      : StyledDownArrow;

  const listHeight = parseFloat($height) * options.length;
  const styles = {
    isOpen,
    selectedOption,
    listHeight,
    disabled,
    $type,
    $width,
    $height,
    $mHeight,
    $padding,
    $fontSize,
  };

  const handleToggle = event => {
    if (disabled) return;
    event.preventDefault();
    setIsOpen(prev => !prev);
  };

  const handleOption = option => {
    setSelectedOption(option.label);
    onOptionSelect(option);
    setIsOpen(false);
  };

  // 외부 클릭 시 드롭다운 닫기
  useClickOutside(dropDownRef, () => {
    setIsOpen(false);
  });

  return (
    <DropDown ref={dropDownRef} {...styles}>
      <div
        onClick={handleToggle}
        className={`dropDownTitle ${$type === "underline" ? "underline" : ""}`}
      >
        <div className="dropDownText">{selectedOption}</div>
        <Arrow />
      </div>
      <ul>
        {options.map((option, index) => (
          <li
            key={index}
            className={selectedOption === option.label ? "selected" : ""}
            onClick={() => handleOption(option)}
          >
            {option.label}
          </li>
        ))}
      </ul>
    </DropDown>
  );
};

const DropDown = styled("div").withConfig({
  shouldForwardProp: prop =>
    !["isOpen", "selectedOption", "disabled", "listHeight", "$type"].includes(
      prop,
    ),
})`
  position: relative;

  & > .dropDownTitle {
    display: flex;
    align-items: center;
    border: 0.062rem solid
      ${({ isOpen, theme: { color } }) =>
        isOpen ? color.black[400] : color.gray[300]};
    border-radius: 0.375rem;
    background: ${({ disabled, theme: { color } }) =>
      disabled ? "#f7f7fb" : color.white[400]};
    width: ${({ $width }) => $width};
    height: ${({ $height }) => $height};
    padding: ${({ $padding }) => $padding};
    font-weight: ${({ isOpen }) => (isOpen ? "600" : "normal")};
    // font-size: ${({ $fontSize }) => $fontSize};
    color: ${({ disabled, theme: { color } }) =>
      disabled ? color.gray[300] : color.black[400]};
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

    &:hover {
      background: #f7f7fb;
      transition: ease-out 0.5s;
    }

    & svg {
      position: absolute;
      right: 0.875rem;
    }

    & > .dropDownText {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: calc(100% - 0.875em);
    }
  }

  & > .underline {
    border: none;
    border-radius: 0;
    border-bottom: 0.062rem solid #e5e5ec;
  }
  & > .underline:hover {
    background: transparent;
    border-bottom: 0.062rem solid ${({ theme: { color } }) => color.black[400]};
  }

  & > ul {
    position: absolute;
    z-index: 1;
    top: 100%;
    margin-top: 0.5rem;
    width: ${({ $width }) => $width};
    border-radius: 0.375rem;
    background: ${({ theme: { color } }) => color.white[400]};
    box-shadow: 0 0.25rem 1.25rem 0 #0000000f;
    height: ${({ isOpen, listHeight }) => (isOpen ? `${listHeight}rem` : "0")};
    max-height: ${({ $mHeight }) => $mHeight};
    overflow-y: scroll;
    /*overflow: hidden;*/
    opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
    transition:
      max-height 0.3s ease,
      opacity 0.3s ease;

    /* ScrollBar Custom */
    &::-webkit-scrollbar {
      width: 0.375rem;
      /*height: 0.5rem;*/
    }
    &::-webkit-scrollbar-thumb {
      background: #cacad7;
      border-radius: 0.625rem;
      border: 0.06rem solid #fff;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    & > li {
      height: ${({ $height }) => $height};
      align-content: center;
      padding: 0 1rem;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.selected {
        font-weight: 600;
      }

      &:hover {
        background: #f7f7fb;
      }
    }
  }
`;

const StyledDownArrow = styled(DownArrow)`
  stroke: ${({ disabled, theme: { color } }) =>
    disabled ? color.gray[300] : color.black[400]};
`;

export default TheDropBox;
