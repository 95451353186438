import { useEffect } from "react";

/**
 * useClickOutside 훅
 *
 * 지정된 ref 요소 외부에서 발생하는 클릭 이벤트를 감지하고,
 * 전달된 핸들러를 호출합니다.
 *
 * @param {React.RefObject<HTMLElement>} ref - 외부 클릭을 감지할 DOM 요소의 참조
 * @param {Function} handler - 외부 클릭 발생 시 호출할 콜백 함수
 * @param exceptionRefs - 외부 클릭 감지를 제외할 DOM 요소의 참조
 *
 * @example
 * // 사용 예시
 * import { useRef, useState } from 'react';
 * import useClickOutside from './useClickOutside';
 *
 * const MyComponent = () => {
 *   const ref = useRef(null);
 *   const [isOpen, setIsOpen] = useState(false);
 *
 *   useClickOutside(ref, () => setIsOpen(false),[exceptionRefs]);
 *
 *   return (
 *     <div>
 *       <button onClick={() => setIsOpen(true)}>Open Menu</button>
 *       {isOpen && (
 *         <div ref={ref}>
 *           <p>Click outside to close this menu.</p>
 *         </div>
 *       )}
 *     </div>
 *   );
 * };
 */
const useClickOutside = (ref, handler, exceptionRefs = []) => {
  useEffect(() => {
    const handleClickOutside = event => {
      const isException = exceptionRefs.some(
        exceptionRef =>
          exceptionRef.current && exceptionRef.current.contains(event.target),
      );

      if (!ref.current && !isException) {
        handler(event);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, handler, exceptionRefs]);
};

export default useClickOutside;
