const errorCode = {
  "0001": "ERROR_0001",
  "0002": "ERROR_0002",
  "0004": "ERROR_0004",
  "0008": "ERROR_0008",
  "0014": "ERROR_0014",
  "0015": "ERROR_0015",
  "0301": "ERROR_0301",
  "0313": "ERROR_0313",
};

const errorMessage = {
  ERROR_0001: "인증에 실패하였습니다.",
  ERROR_0002: "아이디 또는 비밀번호가 일치하지 않습니다.",
  ERROR_0004: "이미 탈퇴한 사용자입니다.",
  ERROR_0008: "탈퇴 후 90일이 지나지 않은 명의는 \n재가입이 불가능합니다.",
  ERROR_0014: "유효하지 않은 번호입니다.\n다시 시도해주세요.",
  ERROR_0015: "비밀번호 변경에 실패하였습니다.",
  ERROR_0301:
    "할당 가능한 VD가 존재하지 않습니다. \n문의하기 페이지에서 문의해주세요.",
  ERROR_0313:
    "할당 가능한 VD가 존재하지 않습니다. \n문의하기 페이지에서 문의해주세요.",
};

export const setErrorhandler = response => {
  const { status, message } = response.data;

  const error = errorCode[status];

  switch (true) {
    case error && message !== undefined:
      return {
        code: errorCode[status],
        message: message,
      };

    case error && errorMessage[error] !== undefined:
      return {
        code: errorCode[status],
        message: errorMessage[error],
      };

    default:
      // console.log("Using default error message");
      return {
        code: "ERROR_0000",
        message: "알 수 없는 오류가 발생하였습니다.",
      };
  }
};
