import styled from "styled-components";

import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { USER_LOGOUT } from "../../features/main/shared/authSlice";
import { SET_ALERT_MSG } from "../../features/main/shared/commonSlice";

const TheNavigation = ({ isOpen, navRef, onClose }) => {
  const { t } = useTranslation("header");
  const isLogin = useSelector(state => state.auth.userAuth);
  const defaultMenu = [
    {
      id: "home",
      menu: t("navi-0"),
      path: isLogin ? "/mytos" : "/home",
    },
    {
      id: "apply",
      menu: t("navi-1"),
      path: "/apply",
    },
    {
      id: "notice",
      menu: t("navi-2"),
      path: "/notice",
    },
    {
      id: "faq",
      menu: t("navi-3"),
      path: "/faq",
    },
    { id: "contact", menu: t("navi-4"), path: "/contact" },
    { id: "download", menu: t("navi-5"), path: "/download" },
  ];
  const logoutMenu = { id: "logout", menu: "로그아웃", path: "/home" };
  const [navMenus, setNavMenus] = useState([...defaultMenu]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const listRef = useRef(null);
  const [listHeight, setListHeight] = useState(
    parseFloat("15rem") * navMenus.length,
  );

  const handleMenuClick = async menu => {
    switch (menu.id) {
      case "logout":
        await dispatch(USER_LOGOUT());
        break;
      case "home":
        if (!isLogin) {
          dispatch(SET_ALERT_MSG("로그인 후 이용 가능한 서비스입니다."));
        }
        break;
      default:
        break;
    }
    await navigate(menu.path);
    await onClose();
  };

  useEffect(() => {
    // 로그인/ 로그아웃 상태 변경시마다 nav 메뉴 변경 진행
    let tempMenuArr = defaultMenu;

    try {
      if (isLogin) {
        const exists = navMenus.some(item => item.menu === logoutMenu.menu);

        // 로그인: 로그아웃 메뉴 미존재 시 추가
        if (!exists) {
          tempMenuArr = [...tempMenuArr, logoutMenu];
        }
      } else {
        // 미로그인: 로그아웃 메뉴 삭제
        tempMenuArr = navMenus.filter(item => item.menu !== logoutMenu.menu);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setNavMenus([...tempMenuArr]);
      setListHeight(parseFloat("15rem") * tempMenuArr.length);
    }
  }, [isLogin]);

  return (
    <Nav ref={navRef} isOpen={isOpen} listHeight={listHeight}>
      <ul>
        {navMenus?.map((item, idx) => (
          <li ref={listRef} key={idx} onClick={() => handleMenuClick(item)}>
            {item.menu}
          </li>
        ))}
      </ul>
    </Nav>
  );
};

const Nav = styled("nav").withConfig({
  shouldForwardProp: prop => !["isOpen", "listHeight"].includes(prop),
})`
  position: absolute;
  top: 4.2rem;
  width: 7rem;
  box-shadow: 0 0.25rem 1.25rem 0 rgba(0, 0, 0, 0.06);
  z-index: 9999; /* 다른 요소보다 위로 올리기 */

  & > ul {
    max-height: ${({ isOpen, listHeight }) =>
      isOpen ? `${listHeight + 100}rem` : "0"};
    width: 100%;
    overflow: hidden;
    opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
    transition:
      max-height 0.3s ease,
      opacity 0.3s ease;

    & li:nth-child(1) {
      border-radius: 0.5rem 0.5rem 0 0;
    }

    & li:nth-last-child(1) {
      border-radius: 0 0 0.5rem 0.5rem;
    }

    & > li {
      height: 3rem;
      text-align: center;
      align-content: center;
      font-weight: 400;
      line-height: 1.125rem;
      background: ${({ theme: { color } }) => color.white[400]};
      color: ${({ theme: { color } }) => color.black[400]};
      cursor: pointer;
      transition:
        background 0.3s,
        color 0.3s,
        font-weight 0.3s;

      &:hover {
        background: ${({ theme: { color } }) => color.white[500]};
        color: ${({ theme: { color } }) => color.blue[400]};
        font-weight: 600;
      }
    }
  }
`;
export default TheNavigation;
