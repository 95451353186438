import styled from "styled-components";

import { useNavigate } from "react-router-dom";

import { TheButton } from "../../../components";

import linkerImg from "../../../assets/images/linkerImg.png";
import right_arrow from "../../../assets/images/right_arrow.png";
import cardImg1 from "../../../assets/images/cardImg1.png";
import cardImg2 from "../../../assets/images/cardImg2.png";
import cardImg3 from "../../../assets/images/cardImg3.png";

const PromotionalBannerContainer = () => {
  const navigate = useNavigate();

  const showComputerPromotion = false;
  const businessPromotions = {
    cardTitle: "TheOtherSpace 기업회원 전용 상품",
    subTitle: "기업 규모에 따라 TheOtherSpace \n도입을 위한 맞춤형 컨설팅 제공",
    desc: "TheOtherSpace는 기업 맞춤 커스터마이징이 가능합니다.\n요구사항에 따라 우리 회사만의 특별한 클라우드 서비스를 만나보세요.",
    btnStr: "도입 문의",
  };
  const allPromotions = {
    subTitle1: "TheOtherSpace 오픈 기념",
    subTitle2: "TheOtherSpace 3년 약정 결제 시 노트북과 태블릿 증정",
    cardTitle:
      "TheOtherSpace를 신청하고 3년 약정 시\n최신 노트북과 삼성 갤럭시 탭 S6를 무료로 드립니다",
    promotionPeriod: "이벤트 기간 : 2025.02.07 ~ (제품 소진시까지)",
    innerCard1: "TheOtherSpace\n월납 서비스 선택",
    innerCard2: "3년 약정\n서비스 결제 완료",
    innerCard3: "최신 노트북과\n태블릿 증정",
    innerCardImg1: "",
    innerCardImg2: "",
    innerCardImg3: "",
    label1: "이벤트 내용",
    label2: "이벤트 대상",
    label3: "이벤트 기간",
    contents1:
      "TheOtherSpace의 월납 서비스 중 한 가지를 선택 후, 36개월 약정 결제 시\n선착순 50분께는 삼성 노트북과 갤럭시 탭 S6 제공, 250분께는 삼성 노트북 제공",
    contents2: "클라우드 업무 환경을 고려하는 모든 TheOtherSpace 개인회원",
    contents3: "2월 7일부터 선착순 300명",
    btnStr: "신청하기",
  };

  return (
    <UlEl>
      <li>
        <article>
          <h3>{businessPromotions.cardTitle}</h3>
          <ul className={"contentsWrap"}>
            <li>
              <strong>
                {businessPromotions.subTitle.split("\n").map((el, idx) => {
                  return <span key={idx}>{el}</span>;
                })}
              </strong>
              <p>
                {businessPromotions.desc.split("\n").map((el, idx) => {
                  return <span key={idx}>{el}</span>;
                })}
              </p>
              <TheButton $width="9.75rem" _onClick={() => navigate("/contact")}>
                {businessPromotions.btnStr}
              </TheButton>
            </li>
            <li>
              <div
                style={{
                  backgroundImage: `url(${linkerImg})`,
                  backgroundSize: "cover",
                  width: "33.25rem",
                  height: "16.438rem",
                }}
              />
            </li>
          </ul>
        </article>
      </li>
      {showComputerPromotion && (
        <li>
          <article>
            <span>{allPromotions.subTitle1}</span>
            <strong>
              TheOtherSpace 3년 약정 결제 시 <span>노트북과 태블릿 증정</span>
            </strong>
            <ul className="boxEl">
              <li>
                <h3>
                  <span>TheOtherSpace</span>를 신청하고 3년 약정 시<br />
                  <strong>
                    최신 노트북과 삼성 갤럭시 탭 S6를 무료로 드립니다
                  </strong>
                </h3>
              </li>
              <li>
                <span>
                  이벤트 기간 : <span>2025.02.07 ~ </span>
                </span>
                (제품 소진시까지)
              </li>
              <li>
                <ul className="innerCardEl">
                  <li>
                    <div />
                    {allPromotions.innerCard1}
                  </li>
                  <li>
                    <div />
                  </li>
                  <li>
                    <div />
                    {allPromotions.innerCard2}
                  </li>
                  <li>
                    <div />
                  </li>
                  <li>
                    <div />
                    {allPromotions.innerCard3}
                  </li>
                </ul>
              </li>
              <li>
                <dl className={"eventContentsWrap"}>
                  <dt>{allPromotions.label1}</dt>
                  <dd>
                    TheOtherSpace의 월납 서비스 중 한 가지를 선택 후, 36개월
                    약정 결제 시<br />
                    선착순 50분께는 <span>삼성 노트북과 갤럭시 탭 S6</span>{" "}
                    제공, 250분께는 <span>삼성 노트북</span> 제공
                  </dd>
                  <dt>{allPromotions.label2}</dt>
                  <dd>
                    클라우드 업무 환경을 고려하는
                    <span>모든 TheOtherSpace 개인회원</span>
                  </dd>
                  <dt>{allPromotions.label3}</dt>
                  <dd>
                    2월 7일부터 <span>선착순 300명</span>
                  </dd>
                </dl>
              </li>
              <li>
                <TheButton
                  $margin={"0 auto"}
                  $width="14.5rem"
                  $fontSize={"1.5rem"}
                  $padding={"1.125rem 0"}
                >
                  {allPromotions.btnStr}
                </TheButton>
              </li>
            </ul>
          </article>
        </li>
      )}
    </UlEl>
  );
};

const UlEl = styled.ul`
  width: 100%;
  text-align: center;
  color: #fff;

  & > li > article {
    padding: 3.75rem 0;
    width: 70rem;
    margin: 0 auto;
  }

  & > li:nth-child(1) {
    position: relative;
    width: 100%;
    background: linear-gradient(180deg, #0b1935 0%, #1f489b 100%);
    z-index: 0;

    &::before {
      content: "";
      width: 100%;
      height: 14.063rem;
      position: absolute;
      bottom: 0;
      left: 0;
      background: linear-gradient(
        90deg,
        rgba(102, 102, 102, 0) 0%,
        rgba(0, 0, 0, 0.5) 100%
      );
      z-index: -1;
    }

    & h3 {
      color: white;
      font-size: 2rem;
      font-weight: 600;
      line-height: 2.387rem;
      letter-spacing: -0.025em;
      margin-bottom: 3rem;
      z-index: 1;
    }

    & .contentsWrap {
      width: 100%;
      margin: 0 1.25rem;
      display: flex;
      gap: 2rem;
      z-index: 1;

      & > li:nth-child(1) {
        text-align: left;
        padding: 2rem 1.25rem;

        & strong {
          display: inline-block;
          font-size: 1.75rem;
          font-weight: 600;
          line-height: 2.088rem;
          letter-spacing: -0.025em;
          margin-bottom: 1rem;
        }

        & p {
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5rem;
          margin-bottom: 2rem;
        }

        & span {
          display: block;
          color: white;
        }
      }
    }
  }

  & > li:nth-child(2) {
    width: 100%;
    background-color: #5678e6;

    & > article {
      & > span {
        display: block;
        width: 14.313rem;
        padding: 0.875rem 1.75rem;
        border-radius: 6.25rem;
        background-color: #fff;
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.193rem;
        letter-spacing: -0.025em;
        color: #5678e6;
        margin: 0 auto 1rem;
      }

      & > strong {
        color: #fff;
        display: inline-block;
        font-size: 2rem;
        font-weight: 600;
        line-height: 2.387rem;
        letter-spacing: -0.025em;
        margin-bottom: 3rem;

        & > span {
          color: #fff;
          font-size: 2rem;
          font-weight: 800;
          line-height: 2.387rem;
          letter-spacing: -0.025em;
        }
      }
    }

    & .boxEl {
      background-color: #fff;
      width: 100%;
      padding: 3.5rem 8.5rem;
      border-radius: 1.25rem;

      & h3 {
        font-family: Paperlogy;
        font-size: 2rem;
        font-weight: 800;
        line-height: 2.875rem;
        letter-spacing: -0.025em;
        margin-bottom: 1.344rem;

        & span {
          color: #3b4dd3;
        }
        & strong {
          font-size: 2.5rem;
          position: relative;
          z-index: 0;
        }
        & strong::before {
          position: absolute;
          content: "";
          width: 102%;
          height: 1.25rem;
          display: inline-block;
          background: rgba(86, 120, 230, 0.3);
          bottom: 0;
          left: -1%;
          z-index: -1;
        }
      }

      & > li:nth-child(2) {
        width: 22.125rem;
        height: 2.938rem;
        padding: 0.875rem 1.75rem;
        border-radius: 6.25rem;
        border: 0.062rem solid #3b4dd3;
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.178rem;
        letter-spacing: -0.025em;
        color: #555555;
        margin: 0 auto 3rem;

        & span {
          color: #3b4dd3;

          & span {
            font-weight: 800;
          }
        }
      }

      & .innerCardEl {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1.5rem;
        margin-bottom: 3rem;
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 1.5rem;
        letter-spacing: -0.025em;

        & li:nth-child(2n - 1) {
          width: 22rem;
          height: 22.75rem;
          padding: 2.5rem 42px;
          border-radius: 1.25rem;
          & > div {
            margin: 0 auto 1rem;
            background-size: 3rem 2.75rem;
            background-repeat: no-repeat;
            background-position: center center;
            width: 6.25rem;
            height: 6.25rem;
            background-color: white;
            border-radius: 6.25rem;
          }
        }
        & li:nth-child(2n) {
          & > div {
            background-image: url(${right_arrow});
            background-size: cover;
            width: 1.75rem;
            height: 1.75rem;
          }
        }
        & li:nth-child(1) {
          height: max-content;
          background-color: rgba(86, 120, 230, 0.3);
          & > div {
            background-image: url(${cardImg1});
          }
        }
        & li:nth-child(3) {
          height: max-content;
          background-color: rgba(86, 120, 230, 0.6);
          & > div {
            background-image: url(${cardImg2});
          }
        }
        & li:nth-child(5) {
          height: max-content;
          background-color: rgba(86, 120, 230, 0.9);
          & > div {
            background-image: url(${cardImg3});
          }
        }
      }

      & .eventContentsWrap {
        display: grid;
        grid-template-columns: 8.563rem 1fr;
        grid-template-rows: repeat(3, minmax(0, 1fr));
        gap: 1.219rem 1.75rem;
        align-items: center;
        margin-bottom: 2rem;

        & dt {
          width: 8.563rem;
          height: 3.063rem;
          padding: 0.875rem 1.75rem;
          border-radius: 6.25rem;
          background-color: #111111;
          color: #fff;
          font-size: 1.125rem;
          font-weight: 600;
          line-height: 1.325rem;
          letter-spacing: -0.025em;
        }
        & dd {
          text-align: left;
          font-size: 1.25rem;
          font-weight: 400;
          line-height: 1.75rem;
          letter-spacing: -0.025em;

          & span {
            font-size: 1.25rem;
            font-weight: 600;
            line-height: 1.75rem;
            letter-spacing: -0.025em;
          }
        }
      }
    }
  }
`;

export default PromotionalBannerContainer;
