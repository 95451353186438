import { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { ReactComponent as Calendar } from "../../assets/svg/calendar.svg";
import { TheButton, TheDropBox, TheInput } from "../index";

/**
 * 검색창
 * @description DatePicker 참고 사이트: https://reactdatepicker.com/#example-holiday-dates
 * */
const TheSearchBox = ({
  onDate = true,
  onFilter = true,
  showMonthYearDropdown = false,
  searchFilter = [],
  onOptionSelect,
  $height = "2.75rem",
  $inputWidth = "37rem",
  _handleDate,
  _searchWord,
  _search,
}) => {
  const { t } = useTranslation("common");
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;

  const search = e => {
    e.preventDefault();
    _search && _search();
  };
  const handleDate = e => {
    setDateRange(e);
    _handleDate && _handleDate(e);
  };
  const handleSearchWord = e => {
    _searchWord && _searchWord(e.target);
  };

  return (
    <SearchBoxWrapper onSubmit={search}>
      {onDate && (
        <DatePickerWrapper>
          <DatePicker
            showIcon
            selectsRange
            closeOnScroll
            toggleCalendarOnIconClick
            dateFormat="yyyy-MM-dd"
            icon={<Calendar />}
            startDate={startDate}
            maxDate={new Date()}
            endDate={endDate}
            onChange={update => {
              handleDate(update);
            }}
            showMonthYearDropdown={showMonthYearDropdown}
          />
        </DatePickerWrapper>
      )}
      <SearchBox>
        {onFilter && (
          <TheDropBox
            $width="5.25rem"
            $height={$height}
            $padding="0.625rem 0.75rem"
            $fontSize="0.938rem"
            onOptionSelect={onOptionSelect}
            initialOption={t("search-filter-placeholder")}
            options={searchFilter}
          />
        )}
        <TheInput
          type={"search"}
          $width={$inputWidth}
          $height={$height}
          $padding="0 1.25rem"
          placeholder={t("search-input-placeholder")}
          _onChange={e => handleSearchWord(e)}
        />
        <TheButton $width="7.625rem" type="submit">
          {t("search")}
        </TheButton>
      </SearchBox>
    </SearchBoxWrapper>
  );
};

const SearchBoxWrapper = styled.form`
  width: 70rem;
  height: 5.75rem;
  border-radius: 0.375rem;
  border: 0.063rem solid ${({ theme: { color } }) => color.gray[400]};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  background: ${({ theme: { color } }) => color.white[400]};
`;

const DatePickerWrapper = styled.div`
  width: 14.063rem;

  // input
  & .react-datepicker__view-calendar-icon input {
    color: ${({ theme: { color } }) => color.gray[950]};
    /*padding: 0.5rem 1rem 0.5rem 2rem;*/
    width: 14.063rem;
  }
`;

const SearchBox = styled.div`
  display: flex;
  column-gap: 0.5rem;
`;

export default TheSearchBox;
