import { createSlice } from "@reduxjs/toolkit";
// import {
//   getFaqListApi,
//   getNoticeListApi,
// } from "../../../services/controller/homeController";

// initialState
const initialState = {
  currentPath: "/home",
  isLoading: false,
  noticeList: {},
  faqList: {},
  activeCircle: 0,
};

// Actions(Api)
// export const getHomeList = createAsyncThunk(
//   "homeSlice/getHomeList",
//   async (request, { rejectWithValue }) => {
//     try {
//       const [noticeResponse, faqResponse] = await Promise.all([
//         getNoticeListApi(request),
//         getFaqListApi(request),
//       ]);
//       return {
//         noticeList: noticeResponse,
//         faqList: faqResponse,
//       };
//     } catch (error) {
//       // 오류 발생 시
//       return rejectWithValue(error.response?.status || "An error occurred");
//     }
//   },
// );

const homeSlice = createSlice({
  name: "homeSlice",
  initialState,
  // mutating
  reducers: {
    setCurrentPath: (state, action) => {
      state.currentPath = action.payload;
    },
    setActiveCircle(state, action) {
      state.activeCircle = action.payload;
      console.log(state.activeCircle);
    },
    nextCircle: state => {
      state.activeCircle = (state.activeCircle + 1) % 6;
      console.log(state.activeCircle);
    },
    prevCircle: state => {
      state.activeCircle = (state.activeCircle - 1 + 6) % 6;
    },
  },
  // extraReducers: builder => {
  // builder
  //   .addCase(getHomeList.pending, state => {
  //     state.isLoading = true;
  //     state.error = null;
  //   })
  //   .addCase(getHomeList.fulfilled, (state, action) => {
  //     state.isLoading = false;
  //     state.noticeList = action.payload.noticeList;
  //     state.faqList = action.payload.faqList;
  //   })
  //   .addCase(getHomeList.rejected, (state, action) => {
  //     state.isLoading = false;
  //     state.error = action.payload;
  //   });
  // },
});

export const { setCurrentPath, setActiveCircle, nextCircle, prevCircle } =
  homeSlice.actions;
export default homeSlice.reducer;
