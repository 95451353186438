import styled from "styled-components";
import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import EventPopup from "../home/EventPopup";

import {
  getCookie,
  generateUUID,
  getUUID,
} from "../../../utils/custom/setCookie";

// 이미지
import { ReactComponent as MainCopy } from "../../../assets/svg/titleCopy.svg";
import defaultBg from "../../../assets/images/bg_0.png";
import ErrorVideo from "../../../assets/images/error_video.png";

import { todayColor, todayNumber } from "../../../utils/custom/todayNumber";

import HomeLoadingScreen from "./HomeLoadingScreen";
import LoginContainer from "./LoginContainer";
import SixWsContainer from "./SixWsContainer";
import { TheFooter } from "../../../components";

const HomePage = () => {
  const location = useLocation();
  const { t } = useTranslation("home");

  const [showEPopup, setShowEPopup] = useState(true);

  const [isLoading, setIsLoading] = useState(true);

  const [bgImageUrl, setBgImageUrl] = useState("");
  const [videoError, setVideoError] = useState(false);

  const circleItems = ["When", "Where", "What", "How", "Who", "Why"];
  const [activeSection, setActiveSection] = useState(0);
  const [activeCircle, setActiveCircle] = useState(0);
  // const isThrottlingRef = useRef(false);

  const isAssetsUrl = path => {
    const protocol = window.location.protocol;
    const hostName = window.location.hostname;
    // const port = 15959;

    return process.env.REACT_APP_ASSETS_URL
      ? `${process.env.REACT_APP_ASSETS_URL}/${path}`
      : `${protocol}//${hostName}/tosassets/${path}`;
  };

  // 동그라미 누르면 섹션이동!
  const handleActiveSection = index => {
    setActiveCircle(index);

    // when, where일때, what,how일때, who,why일때 묶어서 섹션 이동
    const currentActiveSection = Math.min(Math.floor(index / 2) + 1, 3);
    setActiveSection(currentActiveSection);

    const targetSection = document.getElementById(
      `section-${currentActiveSection}`,
    );
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  // const handleWheelScroll = event => {
  //   if (isThrottlingRef.current) return;
  //   isThrottlingRef.current = true;
  //
  //   const delta = event.deltaY;
  //   let newActiveSection = activeSection;
  //
  //   // 스크롤 섹션 계산
  //   if (delta > 0) {
  //     if (activeSection < 4) {
  //       newActiveSection += 1;
  //     }
  //   } else if (delta < 0) {
  //     if (activeSection > 0) {
  //       newActiveSection -= 1;
  //     }
  //   }
  //
  //   // 섹션은 0~4개 (푸터포함)
  //   if (newActiveSection < 0) {
  //     newActiveSection = 0;
  //   } else if (newActiveSection > 4) {
  //     newActiveSection = 4;
  //   }
  //
  //   // 스크롤 에니메이션
  //   if (newActiveSection !== activeSection) {
  //     setActiveSection(newActiveSection);
  //     const targetSection = document.getElementById(
  //       `section-${newActiveSection}`,
  //     );
  //     if (targetSection) {
  //       targetSection.scrollIntoView({ behavior: "smooth", block: "start" });
  //     }
  //   }
  //
  //   setTimeout(() => {
  //     isThrottlingRef.current = false;
  //   }, 500); // 스크롤 두개씩 넘어가는거 방지!
  // };

  const getBgPath = async () => {
    try {
      await setIsLoading(false);
      const idx = await todayNumber();
      const apiUrl = isAssetsUrl(`image/bg_${idx}.png`);
      const res = await axios.get(apiUrl, {
        responseType: "blob",
      });
      const imageUrl = URL.createObjectURL(res.data);
      setBgImageUrl(imageUrl);
    } catch (error) {
      console.error(error);
      setBgImageUrl(defaultBg);
    } finally {
      setIsLoading(false);
    }
  };

  const BackgroundVideo = useMemo(() => {
    return (
      <video
        autoPlay
        loop
        muted
        playsInline
        className="background-video"
        src={isAssetsUrl("video/main_video.mp4")}
        onError={() => setVideoError(true)}
      >
        Your browser does not support the video tag.
      </video>
    );
  }, []);

  // eventPopup 띄우기
  const checkShowPopupModal = () => {
    const lUUIDKey = "mainPopup";
    // uuid 생성 및 가져오기
    let uuid = getUUID(lUUIDKey);

    if (!uuid) {
      generateUUID(lUUIDKey);
    }
    uuid = getUUID(lUUIDKey);

    // cookie 확인
    let res = getCookie(lUUIDKey, uuid);

    setShowEPopup(!res);
    // setShowEPopup(true);
  };

  // 백그라운드 이미지 & 동영상
  useEffect(() => {
    getBgPath();
    // 추후 더이상의 배너 팝업이 필요하지 않을 때 localstorage mainPopup 삭제 필요
    checkShowPopupModal();
  }, []);

  // useEffect(() => {
  //   window.addEventListener("wheel", handleWheelScroll, { passive: true });
  //
  //   return () => {
  //     window.removeEventListener("wheel", handleWheelScroll);
  //   };
  // }, [activeSection]);

  // 로딩 중일 때
  if (isLoading) {
    return (
      <SectionEl $image={defaultBg}>
        <HomeLoadingScreen />
      </SectionEl>
    );
  }

  return (
    <SectionWrap>
      {showEPopup && <EventPopup _justClose={() => setShowEPopup(false)} />}
      <SectionEl $image={bgImageUrl} id="section-0">
        <Container>
          <TitleWrap $today={todayColor(location?.pathname)}>
            <div className="theOtherSpace">
              <MainCopy />
            </div>
            <MiddleBox>
              <LoginContainer />
              <div className="videoWrap">
                {videoError ? (
                  <img src={ErrorVideo} alt="error video" />
                ) : (
                  <div>{BackgroundVideo}</div>
                )}
              </div>
            </MiddleBox>
          </TitleWrap>
          <CircleWrap>
            {circleItems?.map((item, idx) => (
              <CircleItem
                key={idx}
                $isActive={activeCircle === idx}
                onClick={() => handleActiveSection(idx)}
              >
                <span>{item}</span>
                <div className="filler" />
              </CircleItem>
            ))}
          </CircleWrap>
        </Container>
      </SectionEl>
      <SixWsContainer t={t} activeSection={activeSection} />
      <TheFooter id="section-4" />
    </SectionWrap>
  );
};

const SectionWrap = styled.div`
  display: flex;
  flex-direction: column;
`;
const SectionEl = styled.section`
  width: 100%;
  height: 100vh;
  padding: calc(5rem + 3rem) 2.5rem 3rem;
  background-image: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)),
    url(${({ $image }) => $image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 9.5rem;
  position: relative;
`;

const TitleWrap = styled.div`
  width: 100%;
  padding: 0 1.25rem 11rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;

  & .theOtherSpace {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    & svg {
      width: 78.1875rem;
      height: 5.25rem;
      fill: ${({ $today }) => $today?.title};
    }
  }

  & .tryBtnWrap {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const MiddleBox = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 24.625rem;
  gap: 7.5rem;
  padding: 0 1.25rem;
  align-items: center;
  justify-content: center;

  & .videoWrap {
    width: 41.25rem;
    height: 100%;
    background-color: black;
    border-radius: 1.25rem;
    /*min-width: 20.5rem;*/

    & video,
    & img {
      width: 41.25rem;
      height: 100%;
      border-radius: 1.25rem;
      object-fit: cover;
      overflow: hidden;
    }
  }
`;

const CircleWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  justify-content: end;
  align-items: center;
  position: absolute;
  bottom: 0;
  padding: 0 1.25rem;
`;

const CircleItem = styled.div`
  position: relative;
  height: 5.5rem;
  width: 5.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${({ theme: { color } }) => color.white[30]};
  cursor: pointer;
  overflow: hidden;
  transition: background 0.5s ease;
  box-shadow: 0 1rem 1rem 0 rgba(0, 0, 0, 0.05);

  & > span {
    color: ${({ theme: { color } }) => color.white[400]};
    text-shadow: 0 0 0.938rem rgba(17, 17, 17, 0.3);
    font-size: 1.25rem;
    z-index: 1;
    line-height: 1.25rem;
    font-weight: 600;
  }

  & .filler {
    position: absolute;
    top: 50%;
    left: 50%;
    width: ${({ $isActive }) => ($isActive ? "100%" : "0")};
    height: ${({ $isActive }) => ($isActive ? "100%" : "0")};
    background-color: ${({ theme: { color } }) => color.blue[80]};
    border-radius: 50%;
    transform: translate(-50%, -50%);
    transition: ${({ $modalOpen }) => ($modalOpen ? "" : "all 0.5s ease")};
  }

  &:hover .filler {
    width: 100%;
    height: 100%;
  }
`;

export default HomePage;
