import React, { useState, useEffect } from "react";
import styled from "styled-components";
import IndividualPatternChart from "./IndividualPatternChart";

import { ReactComponent as Exclamation } from "../../../assets/svg/exclamationMark.svg";
import { TheBox } from "../../../components";

const IndividualUsageInfo = ({ isVdInfo }) => {
  // ------------날짜 차이계산------------------------
  const calculateRemainingDays = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const today = new Date();

    // 종료일에서 1일 더하기
    end.setDate(end.getDate() + 1);

    // 종료일이 오늘보다 이전이라면 남은 기간은 0
    if (today > end) {
      return 0;
    }

    // 시작일이 오늘보다 이전이라면 오늘부터 종료일까지의 차이 계산
    if (today >= start) {
      return Math.ceil((end - today) / (1000 * 60 * 60 * 24));
    }

    // 시작일부터 종료일까지 남은 일수 계산
    return Math.ceil((end - start) / (1000 * 60 * 60 * 24));
  };

  // 전체 남은 일수 계산
  const remainingDays = calculateRemainingDays(
    isVdInfo()?.SubscriptionStartDateForTheMonth,
    isVdInfo()?.SubscriptionEndDateForTheMonth,
  );

  // 오늘 날짜로부터 사용한 일수 계산 (첫날부터 오늘까지)
  const today = new Date();
  const usedDaysFromStart = Math.ceil(
    (today - new Date(isVdInfo()?.SubscriptionStartDateForTheMonth)) /
      (1000 * 60 * 60 * 24),
  );

  // 남은 일수 = 전체 남은 일수 - 사용된 일수
  const remainingDaysFromUsage = remainingDays - usedDaysFromStart;

  //-------------------------------------------

  //----------------ssd 계산--------------------
  const totalSSD = (isVdInfo()?.TotalDisk / 1024).toFixed(2);
  const usedSSD = (
    (isVdInfo()?.TotalDisk - isVdInfo()?.FreeDisk) /
    1024
  ).toFixed(2);
  const remainSSD = (isVdInfo()?.FreeDisk / 1024).toFixed(2);
  //--------------------------------------------

  // 퍼센트 계산
  const dayPercentage = (usedDaysFromStart / remainingDays) * 100;
  const hddPercentage = (usedSSD / totalSSD) * 100;

  // 마운트 시 0%에서 목표값까지 애니메이션으로 채워지도록 상태 관리
  const [animatedDayPercent, setAnimatedDayPercent] = useState(0);
  const [animatedHddPercent, setAnimatedHddPercent] = useState(0);

  useEffect(() => {
    // 컴포넌트가 마운트될 때 실제 퍼센트로 변경
    setAnimatedDayPercent(dayPercentage);
    setAnimatedHddPercent(hddPercentage);
  }, []);

  // -------------[첫번째 박스]사용정보 영역
  const useInfo = () => {
    // vd정보 있을때
    if (isVdInfo()) {
      return (
        <UsageInfo>
          <div className="infoTitle">사용 정보 </div>
          <ul className="infoDetail">
            <li>
              <Label>요금제</Label>
              <p>{isVdInfo()?.ProductName}</p>
            </li>
            <li>
              <Label>사용 기간</Label>
              <p>
                {isVdInfo()?.SubscriptionStartDateForTheMonth} ~{" "}
                {isVdInfo()?.SubscriptionEndDateForTheMonth}
              </p>
            </li>
            <li className="progressBar">
              <Label>
                <div>
                  남은 기간 <span>{remainingDaysFromUsage}일</span>
                </div>
                <p>
                  {usedDaysFromStart}일 / {remainingDays}일
                </p>
              </Label>
              <div className="barWrapper">
                <FilledBar percentage={animatedDayPercent} />
              </div>
            </li>
            <li className="progressBar">
              <Label>
                <div>
                  남은 SSD
                  <span>
                    {" "}
                    {remainSSD}
                    GB
                  </span>
                </div>
                <p>
                  {usedSSD}GB / {totalSSD}GB
                </p>
              </Label>
              <div className="barWrapper">
                <FilledBar percentage={animatedHddPercent} />
              </div>
            </li>
          </ul>
        </UsageInfo>
      );
    } else {
      return (
        // vd정보 없을때
        <UsageInfo>
          <div className="infoTitle">사용 정보 </div>
          <div className="noData">
            <Exclamation />
            <p>데이터가 존재하지 않습니다.</p>
          </div>
        </UsageInfo>
      );
    }
  };

  return (
    <UsageWrap>
      <TheBox $width="34.375rem" $height="19.75rem" $padding="2.25rem">
        {useInfo()}
      </TheBox>
      <TheBox $width="34.375rem" $height="19.75rem" $padding="2.25rem">
        <PatternInfo>
          <div className="infoTitle">사용 패턴</div>
          <div className="barContent">
            <IndividualPatternChart isVdInfo={isVdInfo} />
          </div>
        </PatternInfo>
      </TheBox>
    </UsageWrap>
  );
};

const UsageWrap = styled.div`
  width: 70rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

const UsageInfo = styled.div`
  width: 100%;
  min-width: 16.5625rem;
  height: 100%;

  & .infoTitle {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.25rem;
    padding: 0 1rem 0.625rem 0;
    border-bottom: 0.0625rem solid ${({ theme: { color } }) => color.gray[500]};
  }

  & .infoDetail {
    margin-top: 1rem;
    font-size: 1rem;
    color: ${({ theme: { color } }) => color.gray[700]};
  }

  & .noData {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 1rem;
  }

  .infoDetail {
    width: 100%;
    & li {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      border-bottom: 0.0625rem solid
        ${({ theme: { color } }) => color.white[600]};
      padding: 0.625rem;

      & p {
        font-weight: 600;
      }
    }

    .progressBar {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    .barWrapper {
      width: 100%;
      height: 1.125rem;
      background-color: #e0e0e0;
      border-radius: 0.5rem;
      position: relative;
      overflow: hidden;
    }
  }
`;

const Label = styled.div`
  font-size: 0.875rem;
  display: flex;
  justify-content: space-between;

  & span {
    color: ${({ theme: { color } }) => color.blue[400]};
    font-weight: 600;
  }

  & p {
    font-weight: 600;
  }
`;

const FilledBar = styled.div.withConfig({
  shouldForwardProp: prop => prop !== "percentage",
})`
  height: 100%;
  width: ${props => props.percentage}%;
  background-color: #2196f3;
  transition: width 0.5s ease;
`;

const PatternInfo = styled.div`
  width: 100%;
  height: 100%;
  min-width: 16.5625rem;

  & .infoTitle {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.25rem;
    padding: 0 1rem 0.625rem 0;
    border-bottom: 0.0625rem solid ${({ theme: { color } }) => color.gray[500]};
  }

  & .barContent {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
export default IndividualUsageInfo;
