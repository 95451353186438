import { api } from "../index";

export const loginApi = async data => {
  try {
    const response = await api.post("/user/tos/login", data);

    return response.data;
  } catch (error) {
    console.error("로그인 API 호출 오류:", error.message);
    throw error;
  }
};

export const corpLoginApi = async data => {
  try {
    const response = await api.post("/api/tos/login", data);

    return response.data;
  } catch (error) {
    console.error("로그인 API 호출 오류:", error);
    throw error;
  }
};

export const getUserInfoApi = async data => {
  try {
    const response = await api.post("/user/api/v1/myaccount/info", data);

    return response.data;
  } catch (error) {
    console.error("로그인 API 호출 오류:", error);
    throw error;
  }
};
