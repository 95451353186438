import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { TheBox, TheButton } from "../../../components";
import { ReactComponent as SquareCheck } from "../../../assets/svg/squareCheck.svg";

import { getProductListApi } from "../../../services/controller/applyController";

const ServiceCard = ({ term, onProgressStep }) => {
  const vmInfoArr = [
    {
      label: "CPU",
      key: "cpu",
      unit: "vCPU",
    },
    {
      label: "램용량",
      key: "ram",
      unit: "GB",
    },
    {
      label: "SSD",
      key: "storage",
      unit: "G",
    },
  ];
  // 서비스 선택
  const [services, setServices] = useState([]);

  const getProductsList = async () => {
    try {
      let res = await getProductListApi();

      switch (res.status) {
        case "0000":
          setServices(res.output.filter(item => item.term === term));
          return;
        default:
          return;
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getProductsList();
  }, [term]);

  return (
    <SelectServiceContent>
      <div className="services">
        {services.map((service, index) => (
          <TheBox key={index} $height="32rem" $padding="1.5rem 0 2.25rem 0">
            <div className="cardHeader" title={service.description}>
              {service.product_name}
            </div>
            <div className="cardContent" title={service.description}>
              <div className="priceBox">
                {service.product_name !== "Flex VD" || term !== "monthly" ? (
                  <p style={{ color: "grey" }}>추후공개</p>
                ) : (
                  <p>
                    {service.price.toLocaleString()}
                    <span className="won">원</span>
                    <span className="month">
                      /{term === "monthly" ? "월" : "년"}
                    </span>
                  </p>
                )}
                {term === "yearly" ? (
                  <span className="discount">
                    (월 {service.percentage_discount}% 할인)
                  </span>
                ) : (
                  <span className="discount">{""}</span>
                )}
              </div>
              <div className="descBox">
                <p>{service.description}</p>
              </div>
              <div className="infoBox">
                {vmInfoArr.map((item, idx) => (
                  <p key={idx}>
                    <span className="label">
                      <SquareCheck />
                      {item.label}
                    </span>
                    <span className="text">{service[item.key]}</span>
                  </p>
                ))}
              </div>
            </div>
            <div className="cardBottom">
              <TheButton
                $width="11.75rem"
                $fontSize="0.9375rem"
                $fontWeight="600"
                $padding="0"
                $margin="0 auto"
                disabled={
                  service.product_name !== "Flex VD" || term !== "monthly"
                }
                _onClick={() =>
                  onProgressStep({
                    type: "goForward",
                    serviceType: service,
                  })
                }
              >
                {service.product_name !== "Flex VD" || term !== "monthly"
                  ? "준비중"
                  : "신청하기"}
              </TheButton>
            </div>
          </TheBox>
        ))}
      </div>
      {/*<aside>*/}
      {/*  <p>*/}
      {/*    ※ 베타테스트 기간동안 불편 사항이나 개선의견이 있으시면 피드백을*/}
      {/*    남겨주세요.*/}
      {/*    <br />{" "}*/}
      {/*    <span>*/}
      {/*      베타 테스트 종료 후, 기업 및 단체도 가입하여 서비스를 이용하실 수*/}
      {/*      있습니다.*/}
      {/*    </span>*/}
      {/*  </p>*/}
      {/*</aside>*/}
    </SelectServiceContent>
  );
};

const SelectServiceContent = styled.div`
  display: grid;
  justify-content: center;

  & .services {
    width: 70rem;
    display: flex;
    justify-content: center;
    column-gap: 1.25rem;

    & article {
      display: grid;
      row-gap: 1.75rem;
    }

    &.cardHeader,
    .cardContent,
    .cardBottom {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    & .cardHeader {
      height: 3.75rem;
      font-size: 1.75rem;
      font-weight: 600;
      line-height: 1.875rem;
      text-align: center;
      align-content: center;
      border-bottom: 0.062rem solid ${({ theme: { color } }) => color.gray[400]};
    }

    & .cardBottom {
      height: 2.75rem;
    }

    & .cardContent {
      /*width: 100%;*/
      width: calc(100% - 4rem);
      margin: 0 auto;
      display: grid;
      align-items: center;
      row-gap: 0.5rem;

      & .priceBox {
        text-align: center;

        & p {
          font-size: 2rem;
          line-height: 2rem;
          font-weight: 700;
          padding: 0.25rem 0.625rem;
        }
        & .won {
          font-size: 1.375rem;
          font-weight: 700;
        }
        & .month {
          font-size: 1.375rem;
          font-weight: 400;
        }
        & .discount {
          font-size: 1.25rem;
          font-weight: 600;
          color: ${({ theme: { color } }) => color.blue[400]};
          padding: 0.25rem 0.625rem;
        }
        & .desc {
          display: inline-block;
          width: 208px;
          font-size: 0.75rem;
          font-weight: 500;
          color: ${({ theme: { color } }) => color.gray[600]};
          padding: 0.25rem 0.625rem;
        }
      }

      & .descBox {
        /*width: calc(100% - 4rem);*/
        margin: 0 auto 1.7rem;

        & p {
          word-break: keep-all;
          font-weight: 400;
          font-size: 0.875rem;
          line-height: 1.125rem;
          text-align: center;
          color: #767676;
        }
      }

      & .infoBox {
        display: grid;
        grid-template-rows: repeat(3, 1fr);
        gap: 0.5rem;

        & p {
          height: 2rem;
          /*width: 11.75rem;*/
          width: 100%;
          font-weight: 500;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 6.25rem;
          padding: 0.25rem 0.625rem;
          background-color: ${({ theme: { color } }) => color.gray[400]};

          & .label {
            width: 8rem;
            display: flex;
            font-size: 0.875rem;
            gap: 0.5rem;
            color: ${({ theme: { color } }) => color.black[200]};
            align-items: center;

            & svg {
              width: 1rem;
              height: 1rem;
            }
          }

          & .text {
            width: 3.75rem;
            font-size: 0.875rem;
          }
        }
      }
    }
  }

  & aside {
    display: flex;
    justify-content: center;
    padding: 0 1.25rem;

    & > p,
    p > span {
      width: 46.25rem;
      line-height: 1.25rem;
      font-size: 0.875rem;
      letter-spacing: -0.025rem;
      color: ${({ theme: { color } }) => color.black[300]};

      & > span {
        padding-left: 0.5rem;
      }
    }
  }
`;
export default ServiceCard;
