import { createSlice } from "@reduxjs/toolkit";

// initialState
const initialState = {
  isLoading: false,
  userInfo: sessionStorage.getItem("tos_")
    ? JSON.parse(decodeURIComponent(atob(sessionStorage.getItem("tos_"))))
    : {}, // userInfo
  userAuth: sessionStorage.getItem("tos_a")
    ? JSON.parse(decodeURIComponent(atob(sessionStorage.getItem("tos_a"))))
    : null, // userAuthToken
  failCount: 0,
  failType: null,
};

const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    SET_LOADING: (state, action) => {
      state.isLoading = action.payload;
    },
    SET_DARK_MODE: state => {
      if (state.darkMode) {
        localStorage.removeItem("darkMode");
        state.darkMode = false;
      } else {
        localStorage.setItem("darkMode", "On");
        state.darkMode = true;
      }
    },
    SET_USER_INFO: (state, action) => {
      state.userInfo = action.payload;
      state.userAuth = action.payload.accessToken;
      sessionStorage.setItem(
        "tos_",
        btoa(encodeURIComponent(JSON.stringify(action.payload))),
      );
      sessionStorage.setItem(
        "tos_a",
        btoa(encodeURIComponent(JSON.stringify(action.payload.accessToken))),
      );
    },
    USER_LOGOUT: state => {
      sessionStorage.removeItem("tos_");
      sessionStorage.removeItem("tos_a");

      state.userInfo = {};
      state.userAuth = null;

      // return initialState; // 전체 리셋
      // 부분 리셋
      // Object.assign(state, {
      //   userInfo: initialState.userInfo,
      //   userAuth: initialState.userAuth,
      // });
    },
  },
});

export const { SET_DARK_MODE, SET_USER_INFO, USER_LOGOUT } = authSlice.actions;
export default authSlice.reducer;
