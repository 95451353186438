import "../fonts/pretendard.css";
import "../fonts/pretendard-subset.css";

import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed,
    figure, figcaption, footer, header, hgroup,
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }

    /* HTML5 display-role reset for older browsers */
    article, aside, details, figcaption, figure,
    footer, header, hgroup, menu, nav, section {
        display: block;
    }

    body {
        width: 100%;
        height: 100%;
        font-family: Pretendard, "Pretendard Variable",  -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
        line-height: 1;
        color: ${({ theme: { color } }) => color.black[400]};
    }

    ol, ul, li {
        list-style: none;
    }

    blockquote, q {
        quotes: none;
    }

    blockquote:before, blockquote:after,
    q:before, q:after {
        /*content: '';*/
        content: none;
    }

    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    button {
        /*all: unset;*/
        font-size: 1rem;
        line-height: 100%;
        padding: 0;
        cursor: pointer;
    }

    select::-ms-expand {
        display: none;
    }

    input,
    textarea {
        border: none;
        background-color: inherit;
        font-size: inherit;
        outline: none;
        color: inherit;
    }

    :root {
        /*font-size: 16px;*/
        font-size: clamp(12px, calc(16 / 1080 * 100vh), calc(16 / 1080 * 100vh));
    }

    html, body {
        width: 100%;
        height: 100%;
    }

    *, *::before, *::after {
        box-sizing: border-box;
        color: #111;
    }

    #root {
        width: 100%;
        height: 100%;
        /*min-width: 64rem;*/
        min-width: 1024px;
    }

    /*#root {*/
    /*  width: 100%;*/
    /*  min-width: 1024px;*/
    /*  height: 100%;*/
    /*  overflow: auto;*/
    /*  -ms-overflow-style: none;*/
    /*  scrollbar-width: none;*/
    /*}*/
    
    /*#root::-webkit-scrollbar {*/
    /*    display: none;*/
    /*}*/
    

    body {
        /*overflow: hidden;*/
    }

     /*ScrollBar Custom */
    ::-webkit-scrollbar{
        width: 8px;
        height: 8px;
    }
    
    ::-webkit-scrollbar-thumb {
        background: #B1B1B1;
        border-radius: 10px;
    }
    
    ::-webkit-scrollbar-track {
        background: #F9FAFB;
    }

    @media (max-width: 1024px) {
        :root {
            font-size: 12px;
        }
    }
`;

export default GlobalStyle;
