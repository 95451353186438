import React from "react";
import styled from "styled-components";

import { TheBox } from "../../components";

const BusinessUsageInfo = ({ usageInfo }) => {
  return (
    <UsageWrap>
      <TheBox $width="34.375rem" $height="19.75rem" $padding="2.25rem">
        <UsageInfo>
          <div className="infoTitle">이용 중인 서비스</div>
          <div className="scrollBox">
            {usageInfo.map((item, index) => (
              <ul key={index}>
                <li>
                  <p>{item.name}</p>
                </li>
                <li>
                  <span>{item.count} 개</span>
                </li>
              </ul>
            ))}
          </div>
        </UsageInfo>
      </TheBox>
      <TheBox $width="34.375rem" $height="19.75rem" $padding="2.25rem">
        <PayMentInfo>
          <div className="infoTitle">결제수단</div>
        </PayMentInfo>
      </TheBox>
    </UsageWrap>
  );
};

const UsageWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
`;

const UsageInfo = styled.div`
  width: 100%;
  height: 100%;

  & .infoTitle {
    width: 100%;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.25rem;
    padding: 0.625rem 1rem;
    border-bottom: 0.0625rem solid ${({ theme: { color } }) => color.gray[500]};
    margin-bottom: 1rem;
  }

  & .scrollBox {
    width: 100%;
    height: 9.875rem;
    overflow-y: auto;
  }

  & ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border-bottom: 0.0625rem solid ${({ theme: { color } }) => color.white[600]};
    overflow-y: auto;

    & li {
      padding: 0.625rem;

      & p {
        font-weight: 600;
        line-height: 1.25rem;
      }
      & span {
        font-weight: 600;
        line-height: 1.25rem;
        color: ${({ theme: { color } }) => color.blue[500]};
      }
    }
  }
`;
const PayMentInfo = styled.div`
  & .infoTitle {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.25rem;
    padding: 0.625rem 1rem;
    border-bottom: 0.0625rem solid ${({ theme: { color } }) => color.gray[500]};
  }
`;
export default BusinessUsageInfo;
