import { api } from "../index";

export const signupApi = async data => {
  try {
    const res = await api.post("/user/tos/registration", data);
    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const dupIdCheckApi = async data => {
  try {
    const res = await api.post("/user/tos/registration/dupcheck", data);
    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const serviceContentApi = async () => {
  try {
    const res = await api.post("/terms/service");
    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
