import styled from "styled-components";

import { ReactComponent as Exclamation } from "../../assets/svg/exclamationMark.svg";
import ThePagination from "./ThePagination";

/**
 * 헤더 컬럼 테이블
 * @example
 * // 사용 예시 - tbody > tr > td의 경우 기본값이 중앙정렬
 * <TheBoardTable
 *  colgroupList = ['10%', '10%', '10%', '10%'],
 *   theadList = ['헤더1', '헤더2', '헤더3', '헤더14'],
 *   showDataCnt = 0,
 *   isNoData = false,
 *   $width = "70rem",
 *   $height = "max-content",
 * >
 * {children}
 * </TheBoardTable>
 * */
const TheTable = ({
  children,
  type = "board",
  colgroupList = [],
  theadList = [],
  showDataCnt = 0,
  isNoData = false,
  totalPages = 33,
  pageBtnCnt = 10,
  noDataStr = "게시글이 존재하지 않습니다.사용 내역이",
  $tdFSize = "0.938rem",
  $width = "70rem",
  $height = "max-content",
  $margin = "0 auto",
  $tdPd = "0.75rem 1.5rem",
  _handlePagination, // active 페이지 넘버 return
}) => {
  const styles = {
    $width,
    $height,
    $margin,
    $tdFSize,
    $tdPd,
  };

  const handlePagination = e => {
    _handlePagination && _handlePagination(e);
  };

  switch (type) {
    case "details":
      return (
        <DetailTableEl {...styles}>
          <colgroup>
            {colgroupList.map((item, idx) => (
              <col key={idx} style={{ width: item }} />
            ))}
          </colgroup>
          <tbody>{children}</tbody>
        </DetailTableEl>
      );
    case "board":
    default:
      return (
        <ArticleEl {...styles}>
          {isNoData ? (
            <span className="dataCntWrap">
              총 <span className="cnt">{showDataCnt.toLocaleString()}</span>건
            </span>
          ) : (
            showDataCnt && (
              <span className="dataCntWrap">
                총 <span className="cnt">{showDataCnt.toLocaleString()}</span>건
              </span>
            )
          )}
          <div className="tableWrap">
            <TableEl>
              <colgroup>
                {colgroupList.map((item, idx) => (
                  <col key={idx} style={{ width: item }} />
                ))}
              </colgroup>
              <thead>
                <tr>
                  {theadList.map((item, idx) => (
                    <th key={idx}>{item}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {isNoData ? (
                  <tr>
                    <td colSpan={colgroupList.length}>
                      <div className="noData">
                        <Exclamation />
                        <p>{noDataStr}</p>
                      </div>
                    </td>
                  </tr>
                ) : (
                  children
                )}
              </tbody>
            </TableEl>
          </div>
          {!isNoData && (
            <ThePagination
              totalPages={totalPages}
              pageBtnCnt={pageBtnCnt}
              _btnClick={e => handlePagination(e)}
            />
          )}
        </ArticleEl>
      );
  }
};

const ArticleEl = styled.article`
  width: 100%;

  & .dataCntWrap {
    display: inline-block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.19rem;
    letter-spacing: -0.025em;
    text-align: left;
    margin-bottom: 0.5rem;
  }
  & .cnt {
    font-weight: 600;
  }
  & .tableWrap {
    width: max-content;
    max-height: calc(100vh - 20rem);
    height: ${({ $height }) => $height};
    overflow: auto;
    border-radius: 0.375rem;
    margin-bottom: 1.5rem;
  }
  /* 스크롤 */
  & .tableWrap::-webkit-scrollbar {
    width: 0;
  }
  & .tableWrap::-webkit-scrollbar-thumb {
    height: 0;
    background: transparent;
  }
  & .tableWrap::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;
const TableEl = styled.table`
  min-width: 70rem;
  width: ${({ $width }) => $width};
  margin: ${({ $margin }) => $margin};
  border-collapse: collapse;
  border-style: hidden;

  & > thead > tr > th {
    background-color: ${({ theme: { color } }) => color.gray[450]};
    position: sticky;
    top: 0;
    text-align: center;
    padding: 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: -0.025em;
  }
  & > tbody > tr {
    background-color: ${({ theme: { color } }) => color.white[400]};
    border-bottom: 0.062rem solid
      ${({ theme: { color } }) => color.checkbox.disabledBackground};
  }
  & > tbody > tr > td {
    padding: 1.375rem 1rem;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  & .noData {
    height: 7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
`;

const DetailTableEl = styled.table`
  width: ${({ $width }) => $width};
  margin: ${({ $margin }) => $margin};
  border-collapse: collapse;
  border-style: hidden;
  background-color: ${({ theme: { color } }) => color.white[400]};
  font-size: 0.938rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: -0.025em;
  border-top: 0.1rem solid ${({ theme: { color } }) => color.black[400]};

  & tbody tr:not(:last-child) {
    border-bottom: 0.062rem solid ${({ theme: { color } }) => color.white[600]};
    // ${({ theme: { color } }) => color.checkbox.disabledBackground};
  }
  & tbody tr > td {
    padding: ${({ $tdPd }) => $tdPd};
  }
  & tbody tr > td:nth-child(odd) {
    vertical-align: middle;
    font-size: ${({ $tdFSize }) => $tdFSize};
    text-align: left;
    background-color: ${({ theme: { color } }) => color.gray[450]};
  }
`;

export default TheTable;
