import styled from "styled-components";

import { ReactComponent as ArrowRight } from "../../assets/svg/arrow_right.svg";
import { ReactComponent as DoubleArrowRight } from "../../assets/svg/double_arrow_right.svg";
import { ReactComponent as ArrowLeft } from "../../assets/svg/arrow_left.svg";
import { ReactComponent as DoubleArrowLeft } from "../../assets/svg/double_arrow_left.svg";
import { useState, useEffect } from "react";

/**
 * 헤더 컬럼 테이블
 * @example
 * // 현재 prev, next 버튼 클릭 시 한칸씩 움직이는데,,
 * // 걍 페이지가 이동되게끔 하는게 낫지 않나 하는 생각도...;
 * <ThePagination
 *   pageBtnCnt = 10, // 화면에 출력할 페이지네이션 버튼 수
 *   totalPages = 19, // 총 페이지 수
 *   _btnClick={(activePage) => console.log(activePage)}
 * />
 * */
const ThePagination = ({
  pageBtnCnt = 5, // 화면에 출력할 버튼 개수
  totalPages = 0, // 총 페이지 수
  _btnClick,
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [pages, setPages] = useState([]);

  const handleClick = e => {
    // 페이지네이션 클릭 시 클릭된 페이지 return
    _btnClick && _btnClick(e);
  };
  const updatePagination = ({ num, type }) => {
    // sideBtn 클릭 시 페이지네이션 새로 그리는 로직
    let tmp = 0;
    let start = 0;
    let end = 0;

    switch (type) {
      case "first":
        start = 1;
        end =
          Number(totalPages) <= Number(pageBtnCnt) ? totalPages : pageBtnCnt;
        break;
      case "prev":
        start = num - pageBtnCnt + 1;
        end = num;
        break;
      case "next":
        start = num;
        end =
          totalPages <= num + pageBtnCnt - 1
            ? totalPages
            : num + pageBtnCnt - 1;
        break;
      case "last":
        tmp = totalPages % pageBtnCnt;
        start = totalPages - tmp + 1;
        end = totalPages;
        break;
    }
    setPages(
      Array(end - start + 1)
        .fill()
        .map((_, index) => start + index),
    );
  };
  const sideBtnAction = type => {
    // sideBtn 클릭 시 active 이동 + 페이지네이션 update 로직
    let num = 0;
    let listUpdateFlag = false;

    switch (type) {
      case "first": // 맨 처음으로 이동
        num = 1;
        listUpdateFlag = Number(pages[0]) !== 1;
        break;
      case "prev": // 한 칸 이전으로 이동
        num = currentPage - 1;
        listUpdateFlag = Number(pages[0]) === Number(currentPage);
        break;
      case "next": // 한 칸 다음으로 이동
        num = currentPage + 1;
        listUpdateFlag =
          Number(pages[pages.length - 1]) === Number(currentPage);
        break;
      case "last": // 맨 마지막으로 이동
        num = totalPages;
        listUpdateFlag = Number(pages[pages.length - 1]) !== Number(totalPages);
        break;
    }
    if (listUpdateFlag) {
      // 리스트 새로 그림
      updatePagination({ num, type });
    }
    clickPagination({ num });
  };
  const clickPagination = ({ num }) => {
    // 일반 페이지 클릭 시
    setCurrentPage(num);
    handleClick(num);
  };
  const isBtnDisabled = ({ type }) => {
    switch (type) {
      case "first":
      case "prev":
        return Number(currentPage) === 1;
      case "next":
      case "last":
        return Number(currentPage) === Number(totalPages);
    }
  };

  useEffect(() => {
    if (currentPage === 0) {
      setCurrentPage(1);
      updatePagination({ num: 1, type: "first" });
      return;
    }
    if (currentPage !== 0 && currentPage !== 0) {
      updatePagination({ num: currentPage, type: "first" });
    }
  }, [currentPage, totalPages]);

  // useEffect(() => {
  //   updatePagination({ num: 1, type: "first" });
  // }, []);

  return (
    <PaginationWrap>
      <li>
        <ButtonEl
          disabled={isBtnDisabled({ type: "first" })}
          onClick={() => sideBtnAction("first")}
        >
          <DoubleArrowLeft />
        </ButtonEl>
      </li>
      <li>
        <ButtonEl
          disabled={isBtnDisabled({ type: "first" })}
          onClick={() => sideBtnAction("prev")}
        >
          <ArrowLeft />
        </ButtonEl>
      </li>
      <li>
        {pages.map((item, idx) => (
          <ButtonEl
            key={idx}
            isActive={Number(currentPage) === Number(item)}
            onClick={() => clickPagination({ num: item })}
          >
            {item}
          </ButtonEl>
        ))}
      </li>
      <li>
        <ButtonEl
          disabled={isBtnDisabled({ type: "next" })}
          onClick={() => sideBtnAction("next")}
        >
          <ArrowRight />
        </ButtonEl>
      </li>
      <li>
        <ButtonEl
          disabled={isBtnDisabled({ type: "last" })}
          onClick={() => sideBtnAction("last")}
        >
          <DoubleArrowRight />
        </ButtonEl>
      </li>
    </PaginationWrap>
  );
};

const PaginationWrap = styled.ul`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  font-size: 0.812rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: -0.025em;
  text-align: center;
`;
const ButtonEl = styled.button.withConfig({
  shouldForwardProp: prop => !["isActive"].includes(prop),
})`
  width: 2rem;
  height: 2rem;
  padding: 0.625rem 0;
  border: none;
  background-color: transparent;
  color: ${({ theme: { color } }) => color.gray[950]};

  &:disabled {
    cursor: not-allowed;
  }

  ${({ isActive }) =>
    isActive &&
    `color: #111111
    `}
`;

export default ThePagination;
