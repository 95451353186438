import styled from "styled-components";

import ThePagination from "./ThePagination";
import { ReactComponent as UpArrow } from "../../assets/svg/upArrow.svg";
import { ReactComponent as DownArrow } from "../../assets/svg/downArrow.svg";
import { ReactComponent as Exclamation } from "../../assets/svg/exclamationMark.svg";
import React from "react";

const TheAccordionTable = ({
  list = [],
  isNoData = false,
  totalPages = 33,
  pageBtnCnt = 10,
  expandedIdx = null, // 선택항목 번호
  $tdFSize = "0.938rem",
  $width = "70rem",
  $height = "max-content",
  $margin = "0 auto",
  $tdPd = "0.75rem 1.5rem",
  _handlePagination, // active 페이지 넘버 return
  _handleDetailClick, // 선택 항목 클릭
}) => {
  const styles = {
    $width,
    $height,
    $margin,
    $tdFSize,
    $tdPd,
  };

  const handlePagination = e => {
    _handlePagination && _handlePagination(e);
  };

  return (
    <ArticleEl {...styles}>
      <div className="tableWrap">
        {isNoData && (
          <div className="noData">
            <Exclamation />
            <p>데이터가 존재하지 않습니다.</p>
          </div>
        )}
        <AccordionEl>
          {list.map((item, index) => (
            <div key={index}>
              <AccordionBox
                $isActive={expandedIdx === item.idx}
                onClick={() => _handleDetailClick(item.idx)}
              >
                <div className="faqCategory">[ {item.category} ]</div>
                <div className="faqTitle">{item.title}</div>
                <div className="arrowIcon">
                  {expandedIdx === item.idx ? <UpArrow /> : <DownArrow />}
                </div>
              </AccordionBox>

              <DetailActiveBox $isActive={expandedIdx === item.idx}>
                {expandedIdx === item.idx && (
                  <FaqDetail>
                    <div className="faqContent">
                      <p>{item.content}</p>
                    </div>
                  </FaqDetail>
                )}
              </DetailActiveBox>
            </div>
          ))}
        </AccordionEl>
      </div>
      {!isNoData && (
        <ThePagination
          totalPages={totalPages}
          pageBtnCnt={pageBtnCnt}
          _btnClick={e => handlePagination(e)}
        />
      )}
    </ArticleEl>
  );
};

const ArticleEl = styled.article`
  width: 100%;

  & .tableWrap {
    width: 100%;
    max-height: calc(100vh - 20rem);
    height: ${({ $height }) => $height};
    overflow: auto;
    border-radius: 0.375rem;
    margin-bottom: 1.5rem;
  }

  & .tableWrap::-webkit-scrollbar {
    width: 0;
  }
  & .tableWrap::-webkit-scrollbar-thumb {
    height: 0;
    background: transparent;
  }
  & .tableWrap::-webkit-scrollbar-track {
    background-color: transparent;
  }

  & .noData {
    height: 7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
`;
const AccordionEl = styled.div`
  min-width: 70rem;
  width: ${({ $width }) => $width};
  border-collapse: collapse;
  border-style: hidden;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const AccordionBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 3.25rem;
  background-color: ${({ theme: { color }, $isActive }) =>
    $isActive ? color.gray[400] : color.white[400]};
  border-radius: 0.375rem;
  padding: 1.5rem;
  gap: 1rem;
  font-size: 1rem;
  line-height: 1.25rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme: { color } }) => color.gray[300]};
  }

  & .faqCategory {
    width: 10rem;
    color: ${({ theme: { color } }) => color.blue[400]};
    font-weight: 600;
    text-align: center;
  }

  & .faqTitle {
    flex: 1;
    font-weight: 500;
    cursor: pointer;
  }

  & .arrowIcon {
    width: 1rem;
    height: 1rem;

    & svg {
      width: 1rem;
      height: 1rem;
    }
  }
`;

const DetailActiveBox = styled.div`
  transition:
    transform 1s ease-out,
    opacity 0.8s ease;

  transform: ${({ $isActive }) =>
    $isActive ? "translateY(0)" : "translateY(-1rem)"};
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0)};
`;

const FaqDetail = styled.div`
  padding: 1.5rem;
  border-radius: 0.375rem;
  .faqContent {
    p {
      font-size: 0.875rem;
      line-height: 1.5rem;
      font-weight: 400;
    }
  }
`;

export default TheAccordionTable;
