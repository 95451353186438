import React from "react";
import styled from "styled-components";

import { TheBox, TheButton } from "../../components";

const BusinessTosInfo = ({ companyInfo }) => {
  return (
    <TheBox $width="70rem" $height="17rem" $padding="2.25rem">
      <Container>
        {companyInfo ? (
          <VdInfoWrap>
            <div className="title">
              {companyInfo?.companyName}님, TheOtherSpace를 이용해 주셔서
              감사합니다.
            </div>
            <div className="corInfoWrap">
              <div className="vdInfo">
                <div className="InfoLabel">이용기간</div>
                {companyInfo?.period}
              </div>
              <div className="vdInfo">
                <div className="InfoLabel">결제일</div>
                {companyInfo?.paymentDay}
              </div>
              <div className="vdInfo">
                <div className="InfoLabel">다음 결제 예정일</div>
                {companyInfo?.nextPaymentDay}
              </div>
            </div>
          </VdInfoWrap>
        ) : (
          <div>d</div>
        )}
        <VdBtnWrap>
          <TheButton
            $width="13rem"
            $radius="0.375rem"
            $padding="1rem 2.5rem"
            $fontSize="1.25rem"
            $fontWeight="600"
            $lineHeight="1.25rem"
          >
            기업포털 접속
          </TheButton>
          <TheButton
            $style="primaryLine"
            $width="13rem"
            $radius="0.375rem"
            $padding="1rem 2.5rem"
            $fontSize="1.25rem"
            $lineHeight="1.25rem"
            $fontWeight="600"
          >
            마이페이지
          </TheButton>
        </VdBtnWrap>
      </Container>
    </TheBox>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

const VdInfoWrap = styled.div`
  width: 44rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 1.5rem;

  & .title {
    font-size: 1.25rem;
    line-height: 1.25rem;
    font-weight: 600;
  }

  & .corInfoWrap {
    & .vdInfo {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: 0.062rem solid
        ${({ theme: { color } }) => color.white[600]};
      padding: 0.75rem 1rem;

      & .InfoLabel {
        width: 12.5rem;
      }
    }
  }
`;

const VdBtnWrap = styled.div`
  width: 22.5rem;
  padding: 2.25rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
`;

export default BusinessTosInfo;
