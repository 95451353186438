import { api } from "../index";

export const contactSubmitApi = async data => {
  try {
    const response = await api.post("/user/inquiry/submit", data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
