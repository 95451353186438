import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  mytosUsagePatternApi,
  mytosVdIdApi,
  mytosVdInfoApi,
} from "../../../services/controller/mytosController";

// initialState
const initialState = {
  vdInfo: [],
  vdId: "",
  usagePattern: [],
  isLoading: false,
};

// Actions(Api)
export const getMyTosVdInfo = createAsyncThunk(
  "signupSlice/getMyTosVdInfo",
  async (UserID, { rejectWithValue }) => {
    try {
      const res = await mytosVdInfoApi(UserID);

      return res.output;
    } catch (error) {
      return rejectWithValue(error.response?.status || "An error occurred");
    }
  },
);

export const getUsagePattern = createAsyncThunk(
  "signupSlice/getUsagePattern",
  async (UserID, { rejectWithValue }) => {
    try {
      const res = await mytosUsagePatternApi(UserID);

      return res.output;
    } catch (error) {
      return rejectWithValue(error.response?.status || "An error occurred");
    }
  },
);

export const getVMID = createAsyncThunk(
  "signupSlice/getVMID",
  async (data, { rejectWithValue }) => {
    try {
      const res = await mytosVdIdApi(data);

      return res.output.VMID;
    } catch (error) {
      return rejectWithValue(error.response?.status || "An error occurred");
    }
  },
);

const mytosSlice = createSlice({
  name: "mytosSlice",
  initialState,

  reducers: {},
  extraReducers: builder => {
    builder
      // vdid 가져오기
      .addCase(getVMID.pending, state => {
        state.isLoading = true;
      })
      .addCase(getVMID.fulfilled, (state, action) => {
        state.vdId = action.payload;

        state.isLoading = false;
      })
      .addCase(getVMID.rejected, state => {
        state.isLoading = false;
      })

      // 사용패턴
      .addCase(getUsagePattern.pending, state => {
        state.isLoading = true;
      })
      .addCase(getUsagePattern.fulfilled, (state, action) => {
        state.usagePattern = action.payload;
        state.isLoading = false;
      })
      .addCase(getUsagePattern.rejected, state => {
        state.isLoading = false;
      })

      // vd정보 가져오기
      .addCase(getMyTosVdInfo.pending, state => {
        state.isLoading = true;
      })
      .addCase(getMyTosVdInfo.fulfilled, (state, action) => {
        state.vdInfo = action.payload;
        state.isLoading = false;
      })
      .addCase(getMyTosVdInfo.rejected, state => {
        state.isLoading = false;
      });
  },
});

// export const {} = mytosSlice.actions;
export default mytosSlice.reducer;
