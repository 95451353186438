import styled from "styled-components";
import React from "react";

import { TheButton, TheTable } from "../../../components";
import usePeriodOfUse from "../../../hooks/usePeriodOfUse";

const PaymentCompletedCard = ({ payInfo, onMovePage }) => {
  return (
    <PaymentCompletedContent>
      <h1>
        {payInfo.product_name} 결제가
        <br />
        완료되었습니다.
      </h1>
      <div className="productInfoTable">
        <TheTable
          type="details"
          colgroupList={["6.25rem", "40rem"]}
          $width="46.25rem"
        >
          <tr>
            <td>상품명</td>
            <td>{`${payInfo.product_name} (${payInfo.term})`}</td>
          </tr>
          <tr>
            <td>상품 금액</td>
            <td>{payInfo.price.toLocaleString()}원</td>
          </tr>
          <tr>
            <td>할인 금액</td>
            <td>{payInfo.price.toLocaleString()}원</td>
          </tr>
          <tr>
            <td>결제 금액</td>
            <td className="textHighlighting">0원</td>
          </tr>
          <tr>
            <td>이용기간</td>
            <td>{usePeriodOfUse(payInfo.term)}</td>
          </tr>
        </TheTable>
      </div>
      <div className="buttonWrap">
        {/*<TheButton*/}
        {/*  $style="primaryLine"*/}
        {/*  $padding="0.75rem 1.25rem"*/}
        {/*  $width="7.781rem"*/}
        {/*  $fontSize="0.938rem"*/}
        {/*  $fontWeight={600}*/}
        {/*  _onClick={() => onMovePage("/home")}*/}
        {/*>*/}
        {/*  홈으로*/}
        {/*</TheButton>*/}
        <TheButton
          $padding="0.75rem 1.25rem"
          $width="7.781rem"
          $fontSize="0.938rem"
          $fontWeight={600}
          $margin="0 auto"
          _onClick={() => onMovePage("/home")}
        >
          My TOS
        </TheButton>
      </div>
    </PaymentCompletedContent>
  );
};
const PaymentCompletedContent = styled.article`
  display: grid;
  justify-items: center;
  row-gap: 2rem;

  & h1 {
    width: 46.25rem;
    height: 4.25rem;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2.125rem;
    text-align: center;
    align-content: center;
  }

  & .buttonWrap {
    width: 16.063rem;
    /*display: flex;*/
    /*gap: 0.5rem;*/
  }

  // 테이블
  & tbody {
    & tr {
      & td {
        height: 3.75rem;
        padding: 1.25rem 1rem;
        text-align: left !important;
        font-size: 0.938rem;
        font-weight: 400;
        line-height: 1.25rem;
        letter-spacing: -0.025rem;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }

      & td:nth-child(1) {
        color: ${({ theme: { color } }) => color.black[300]};
      }
    }
  }

  & .couponCodeWrap {
    display: flex;
    justify-content: space-between;
    gap: 0.25rem;
    padding: 0.75rem 1rem;

    & button {
    }
  }

  & .textHighlighting {
    color: ${({ theme: { color } }) => color.blue[400]};
  }
`;

export default PaymentCompletedCard;
