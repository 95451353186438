import React, { useState, useEffect } from "react";
import styled from "styled-components";

function TheLoadingBar() {
  const [style, setStyle] = useState({ width: "0" });
  const [count, setCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (count < 100) {
        setStyle({ width: `${count + 1}%` });
        setCount(prev => prev + 1);
      } else {
        setCount(0);
        setStyle({ width: "0%" });
      }
    }, 50);

    return () => clearInterval(interval);
  }, [count]);

  return (
    <Progress>
      <ProgressLoading style={style} />
    </Progress>
  );
}

const Progress = styled.div`
  width: 11.25rem;
  background: ${({ theme: { color } }) => color.white[400]};
  height: 0.375rem;
`;

const ProgressLoading = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme: { color } }) => color.blue[400]};
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`;

export default TheLoadingBar;
