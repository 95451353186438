import React from "react";
import styled from "styled-components";

const TheLoader = () => {
  return (
    <Wrap>
      <Container>
        <LoaderIcon />
        <Desc>Loading...</Desc>
      </Container>
    </Wrap>
  );
};

const Wrap = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.35);
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1.875rem;
  width: 18.75rem;
  height: 12.5rem;
  border-radius: 0.5rem;
  background-color: rgba(0, 0, 0, 0.65);
`;

const LoaderIcon = styled.span`
  display: block;
  height: 3.125rem;
  width: 3.125rem;
  border: 0.375rem solid ${({ theme: { color } }) => color.white[400]};
  border-left-color: ${({ theme: { color } }) => color.blue[400]};
  border-radius: 50%;
  animation: spin 1.2s infinite linear;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

const Desc = styled.p`
  color: ${({ theme: { color } }) => color.white[400]};
  font-size: 1.5rem;
  font-weight: 600;
`;

export default TheLoader;
