import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import { ReactComponent as TitleLogo } from "../../../assets/svg/titleLogo.svg";

import TheLoadingBar from "../../../components/common/TheLoadingBar";
import { todayColor } from "../../../utils/custom/todayNumber";

function HomeLoadingScreen() {
  const { pathname } = useLocation();
  return (
    <LoadingContainer>
      <Title $today={todayColor(pathname)?.logo}>
        <TitleLogo />
      </Title>
      <TheLoadingBar />
      <LoadingContent $today={todayColor(pathname)?.logo}>
        <p>TheOtherSpace에 접속중입니다.</p>
        <p>잠시만 기다려 주십시오.</p>
      </LoadingContent>
    </LoadingContainer>
  );
}

const Title = styled.h1`
  & svg {
    width: 24.813rem;
    height: 3rem;
    fill: ${({ $today }) => $today};
  }
`;
const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
`;

const LoadingContent = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1.5rem;
  font-weight: 400;
  font-size: 1.125rem;
  text-align: center;
  & p {
    color: ${({ $today }) => $today};
  }
`;
export default HomeLoadingScreen;
