import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  TheButton,
  TheDropBox,
  TheInput,
  TheModal,
  TheTable,
} from "../../../components";

import { getUserInfoApi } from "../../../services/controller/loginController";
import { changeUserInfoApi } from "../../../services/controller/mypageController";
import useFormValidation from "../../../hooks/useFormValidation";

import { withdrawAccount } from "./mypageSlice";
import { USER_LOGOUT } from "../shared/authSlice";
import { SET_ALERT_MSG } from "../shared/commonSlice";
import removeBeforeFirstDelimiter from "../../../hooks/useRemoveBeforeFirstDelimiter";
import { useTranslation } from "react-i18next";
import {
  getNiceInfoApi,
  getNiceModuleApi,
} from "../../../services/controller/commonController";

const UserInfoEditTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // 훅
  const { t } = useTranslation("mypage");
  const { validateEmail, validatePhone } = useFormValidation();

  // 전역
  const { userInfo } = useSelector(state => state.auth);
  const { isLoading } = useSelector(state => state.mypage);

  const showAddr = false;
  const [requestNo, setRequestNo] = useState(false);
  const emailList = [
    { label: t("directInput"), value: "" },
    { label: "naver.com", value: "naver.com" },
    { label: "daum.net", value: "daum.net" },
    { label: "hanmail.net", value: "hanmail.net" },
    { label: "gmail.com", value: "gmail.com" },
    { label: "nate.com", value: "nate.com" },
  ];
  const [checkPw, setCheckPw] = useState("");
  const [modal, setModal] = useState({
    withdrawModal: false,
    isVDModal: false,
  });
  const [personalInfo, setPersonalInfo] = useState({
    USERID: "",
    USER_EMAIL: "",
    USER_NAME: "",
    USER_PHONE: "",
  });

  const handleInputChange = ({ field, e }) => {
    // 이메일 수정 처리
    if (field.includes("email")) {
      let value = e.trim(); // 공백 제거

      if (field.includes("id")) {
        // 이메일 아이디를 수정하는 경우
        setPersonalInfo(prevState => ({
          ...prevState,
          USER_EMAIL: prevState.USER_EMAIL
            ? `${value}@${prevState.USER_EMAIL.split("@")[1]}` // 아이디만 수정
            : `${value}@`, // 처음 입력 시 아이디가 없으면 @ 뒤에 도메인 빈값 추가
        }));
      } else {
        // 이메일 도메인 수정하는 경우
        setPersonalInfo(prevState => ({
          ...prevState,
          USER_EMAIL: prevState.USER_EMAIL
            ? `${prevState.USER_EMAIL.split("@")[0]}@${value}` // 도메인 수정
            : `@${value}`, // 처음 입력 시 @ 앞부분이 없으면 기본값 추가
        }));
      }
      return;
    }

    // 다른 필드 처리
    setPersonalInfo(prevState => ({
      ...prevState,
      [field]: e,
    }));
  };

  // 회원탈퇴시 비번확인
  const setCheckPwChange = e => {
    let newVal = e.target.value;
    setCheckPw(newVal);
  };

  // 비번 넣은 후 회원탈퇴 api 요청
  const acceptWidthDraw = async () => {
    try {
      const removeTilon = removeBeforeFirstDelimiter(userInfo.UserAlias);
      let res = await dispatch(
        withdrawAccount({
          UserID: removeTilon,
          Password: checkPw,
        }),
      );
      await checkWithdrow(res.payload);
    } catch (error) {
      dispatch(SET_ALERT_MSG(error.message));
    }
  };

  // 회원탈퇴 api 요청 확인 후 회원 탈퇴 / 탈퇴 실패 처리
  const checkWithdrow = async flag => {
    try {
      switch (Number(flag)) {
        case 0:
          setModal({ withdrawModal: false });
          await dispatch(SET_ALERT_MSG(t("notCorrectPw")));
          break;
        case 1:
          setModal({ withdrawModal: false });
          await dispatch(SET_ALERT_MSG(t("withdrawSuccess")));
          await dispatch(USER_LOGOUT());
          setCheckPw("");
          navigate("/");
          break;
        case 14:
          setModal({ withdrawModal: false });
          setModal({ isVDModal: true });
          break;
        default:
          setModal({ withdrawModal: false });
          await dispatch(SET_ALERT_MSG(t("withdrawFail")));
      }
    } catch (e) {
      console.error(e);
    }
  };
  const setModalOpen = ({ name, value }) => {
    setModal(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const getUserInfo = async () => {
    try {
      let UserID = removeBeforeFirstDelimiter(userInfo.UserAlias);

      let data = await getUserInfoApi({
        UserID,
      });

      switch (data.status) {
        case "0000":
          setPersonalInfo({ ...data.output });
          return;
        default:
          return;
      }
    } catch (error) {
      console.error(error);
      dispatch(SET_ALERT_MSG(error.message));
    }
  };

  const changeUserInfo = async e => {
    e.preventDefault();

    let res = validateEmail(personalInfo.USER_EMAIL);
    if (res.error) {
      dispatch(SET_ALERT_MSG(t("emailFail")));
      return;
    }
    res = validatePhone(personalInfo.USER_PHONE);
    if (res.error) {
      dispatch(SET_ALERT_MSG(t("telFail")));
      return;
    }

    try {
      let data = await changeUserInfoApi({
        userId: personalInfo.USERID,
        email: personalInfo.USER_EMAIL,
        phone: personalInfo.USER_PHONE,
        reqNo: requestNo,
      });
      switch (data.message) {
        case "Success":
          if (data.status === "0000") {
            dispatch(SET_ALERT_MSG(t("successUserInfoChange")));
          }
          return;
        default:
          return;
      }
    } catch (error) {
      console.error(error);
      if (error.code === "ERROR_0014") {
        dispatch(SET_ALERT_MSG("본인인증에 실패하였습니다."));
      } else {
        dispatch(SET_ALERT_MSG(error.message));
      }
    }
  };

  // 회원탈퇴 submit 처리
  const submitWithdraw = async e => {
    e.preventDefault();
    if (checkPw.trim() === "") {
      dispatch(SET_ALERT_MSG(t("nullFailWithdrawPW")));
      return;
    }
    setModalOpen({ name: "withdrawModal", value: true });
  };

  const getNiceModule = async () => {
    try {
      const left = screen.width / 2 - 500 / 2;
      const top = screen.height / 2 - 800 / 2;
      const option = `status=no, menubar=no, toolbar=no, resizable=no, width=500, height=600, left=${left}, top=${top}`;

      const toUrl = "/mypage/userinfoedit";

      let returnUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ""}/blank?toUrl=${toUrl}`;

      const data = await getNiceModuleApi({
        returnUrl,
      });

      if (data) {
        const { enc_data, integrity, token_version_id } = data.output;

        window.open("", "nicePopup", option);

        const formEl = document.getElementById("nicePopup");

        formEl.target = "nicePopup";

        formEl.enc_data.value = enc_data;
        formEl.token_version_id.value = token_version_id;
        formEl.integrity_value.value = integrity;

        formEl.submit();
      }
    } catch (error) {
      console.error(error);
      dispatch(SET_ALERT_MSG(error.message));
    }
  };

  // 컴포넌트가 처음 마운트될 때, 전역 함수 등록
  useEffect(() => {
    // 자식 창에서 phoneNumber 전달 시 호출할 함수
    window.setNice = async data => {
      try {
        const res = await getNiceInfoApi({
          ...data,
        });

        setRequestNo(res.output.requestno);

        setPersonalInfo(prevState => ({
          ...prevState,
          USER_PHONE: res.output?.mobileno,
        }));

        // 자식 창에서 부모 창의 화면을 바꿀 때 호출할 함수(새로고침 없이)
        window.navigateTo = path => {
          navigate(path);
        };
      } catch (error) {
        console.error(error);
        dispatch(SET_ALERT_MSG(error.message));
      }
    };
  }, [navigate]);

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <TableWrap>
      <TableContainer onSubmit={changeUserInfo}>
        <div className="title">{t("basicInfo")}</div>
        <TheTable type="details" colgroupList={["15%", "85%"]}>
          <tr>
            <td>{t("name")}</td>
            <td>{personalInfo.USER_NAME}</td>
          </tr>
          <tr>
            <td>{t("id")}</td>
            <td>{personalInfo.USERID}</td>
          </tr>
          <tr>
            <td>{t("email")}</td>
            <td className="inputWrap">
              <TheInput
                type="text"
                $width="8.375rem"
                $padding="0.44rem 1rem"
                value={personalInfo.USER_EMAIL?.split("@")[0] || ""}
                _onChange={e =>
                  handleInputChange({ field: "email_id", e: e.target.value })
                }
              />
              <span style={{ paddingTop: 0, textAlign: "center" }}>@</span>
              <TheInput
                type="text"
                name="email"
                $width="8.375rem"
                $padding="0.44rem 1rem"
                value={personalInfo.USER_EMAIL?.split("@")[1] || ""}
                // isValid={item.isValid}
                // isError={item.isError}
                // required={item.required}
                // placeholder={item.placeholder}
                _onChange={e =>
                  handleInputChange({ field: "email_addr", e: e.target.value })
                }
              />
              <TheDropBox
                $padding="0 1.25rem;"
                $width="8.375rem"
                $height="2.25rem"
                initialOption="직접 입력"
                options={emailList}
                onOptionSelect={e =>
                  handleInputChange({ field: "email_addr", e: e.value })
                }
              />
            </td>
          </tr>
          <tr>
            <td>{t("tel")}</td>
            <td className="inputWrap">
              <TheInput
                type="text"
                $width="100%"
                $padding="0.44rem 1rem"
                disabled={true}
                value={personalInfo.USER_PHONE}
                // _onChange={e =>
                //   handleInputChange({ field: "USER_PHONE", e: e.target.value })
                // }
              />
              <TheButton
                $width="7rem"
                $style="blackLine"
                $padding="0.5rem"
                _onClick={getNiceModule}
              >
                본인인증
              </TheButton>
            </td>
          </tr>
          {showAddr && (
            <tr>
              <td>{t("addr")}</td>
              <td className="inputWrap">
                <TheInput
                  type="text"
                  $width="100%"
                  value={personalInfo.addr}
                  $padding="0.44rem 1rem"
                  _onChange={e =>
                    handleInputChange({ field: "addr", e: e.target.value })
                  }
                />
              </td>
            </tr>
          )}
        </TheTable>
        <div className="btnWrap">
          <TheButton $width="9.75rem" type="submit">
            {t("edit")}
          </TheButton>
        </div>
      </TableContainer>
      <TableContainer onSubmit={submitWithdraw}>
        <div className="title">{t("withdraw")}</div>
        <TheTable type="details" colgroupList={["15%", "85%"]}>
          <tr>
            <td>{t("id")}</td>
            <td>{personalInfo.USERID}</td>
          </tr>
          <tr>
            <td>{t("pw")}</td>
            <td className="inputWrap">
              <TheInput
                name="checkPw"
                // label="비밀번호"
                flag="input"
                type="password"
                $width="20rem"
                $padding="0.44rem 1rem"
                placeholder={t("pwP")}
                value={checkPw}
                _onChange={e => setCheckPwChange(e)}
              />
            </td>
          </tr>
        </TheTable>
        <ul className="withdrawInfo">
          <li>{t("withDrawCaution1")}</li>
          <li>{t("withDrawCaution2")}</li>
          <li>{t("withDrawCaution3")}</li>
        </ul>
        <div className="btnWrap">
          <TheButton
            $width="9.75rem"
            $style="primaryLine"
            type="submit"
            _onClick={submitWithdraw}
          >
            {t("withdraw")}
          </TheButton>
        </div>
      </TableContainer>
      {modal.withdrawModal && (
        <TheModal
          type="confirm"
          $width="30rem"
          title="회원탈퇴"
          content={t("confirmContent")}
          _justClose={() =>
            setModalOpen({ name: "withdrawModal", value: false })
          }
          _acceptClose={() => acceptWidthDraw()}
          useLoading={isLoading}
        ></TheModal>
      )}
      {modal.isVDModal && (
        <TheModal
          type="confirm"
          $width="30rem"
          title={t("failWithdraw")}
          content={t("endFirst")}
          _justClose={() => setModalOpen({ name: "isVDModal", value: false })}
          _acceptClose={() => navigate("/mypage/usagedetail")}
          useLoading={isLoading}
        ></TheModal>
      )}
      {/*나이스*/}
      <form
        name="nicePopup"
        id="nicePopup"
        action="https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb"
        method="POST"
        style={{ display: "none" }}
      >
        <input type="hidden" id="m" name="m" value="service" />
        <input type="hidden" id="token_version_id" name="token_version_id" />
        <input type="hidden" id="enc_data" name="enc_data" />
        <input type="hidden" id="integrity_value" name="integrity_value" />
      </form>
    </TableWrap>
  );
};
const TableWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
const TableContainer = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  & .title {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2.125rem;
  }
  & tbody {
    & tr {
      & td {
        height: 3.75rem;
        padding: 0 1rem;
        text-align: left;
        font-size: 0.938rem;
        font-weight: 400;
        line-height: 1.25rem;
        letter-spacing: -0.025rem;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        vertical-align: middle;
      }

      & td:nth-child(odd) {
        color: ${({ theme: { color } }) => color.black[300]};
      }
    }
  }

  & .btnWrap {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 0.75rem;
  }

  & .inputWrap {
    display: flex;
    flex-direction: row;
    gap: 0.625rem;
    padding: 0.2rem 1rem;
    align-items: center;
  }

  & .withdrawInfo {
    background-color: ${({ theme: { color } }) => color.gray[400]};
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    padding: 1.5rem;
    border-radius: 0.375rem;

    & li {
      padding: 0.3125rem;
      color: ${({ theme: { color } }) => color.gray[800]};
    }
  }
`;

export default UserInfoEditTable;
