import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import { TheButton, TheDropBox, TheInput, TheTable } from "../../../components";
// import { ReactComponent as Plus } from "../../../assets/svg/plus.svg";
// import { ReactComponent as Minus } from "../../../assets/svg/minus.svg";
import usePeriodOfUse from "../../../hooks/usePeriodOfUse";
import {
  checkCouponCodeApi,
  getCouponListApi,
} from "../../../services/controller/paymentController";

import { SET_ALERT_MSG } from "../shared/commonSlice";

const PaymentPriceTable = ({ payInfo, handlevouchersCode }) => {
  const dispatch = useDispatch();
  const [couponCode, setCouponCode] = useState(["", "", "", ""]);
  const [finalPrice, setFinalPrice] = useState();
  const [couponList, setCouponList] = useState([]);
  const inputRefs = useRef([]);

  const getCouponList = async () => {
    try {
      let res = await getCouponListApi({});
      switch (res.status) {
        case "0000":
          setCouponList(
            res.output.map(item => ({
              ...item,
              label: item.coupon_code,
            })),
          );
          return;
        default:
          return;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const checkCouponCode = async e => {
    e.preventDefault();

    try {
      let res = await checkCouponCodeApi({
        couponCode: couponCode.join("-"),
        productId: payInfo.product_id,
      });

      switch (res.status) {
        case "0000":
          await getCouponList();
          setCouponCode(["", "", "", ""]);
          dispatch(SET_ALERT_MSG("쿠폰 등록에 성공하였습니다."));
          return;
        default:
          return;
      }
    } catch (error) {
      console.error(error);
      dispatch(SET_ALERT_MSG(error.message));
    }
  };

  const handleCouponCode = (e, index) => {
    const inputValue = e.target.value;

    // 최대 4글자까지만 허용 (추가 처리 가능)
    if (inputValue.length <= 4) {
      const newValues = [...couponCode];
      newValues[index] = inputValue;
      setCouponCode(newValues);

      // 만약 4글자가 꽉 찼다면 다음 input으로 포커스 이동
      if (inputValue.length === 4 && index < couponCode.length - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleLastPrice = e => {
    handlevouchersCode(e.coupon_code);
    setFinalPrice(0);
  };

  useEffect(() => {
    getCouponList();
  }, []);

  return (
    <PaymentContainer>
      <div className="productTitleTable">
        <div className="title">결제정보</div>
        <TheTable
          type="details"
          colgroupList={["15%", "85%"]}
          $width="46.25rem"
        >
          <tr>
            <td>상품명</td>
            <td>
              {payInfo.product_name} ({payInfo.term})
            </td>
            {/*<td className="tdBg">수량</td>*/}
            {/*<td className="countWrap">*/}
            {/*  <Minus onClick={() => handleCount("minus")} />*/}
            {/*  <TheInput*/}
            {/*    type="text"*/}
            {/*    $width="8.375rem"*/}
            {/*    maxLength="4"*/}
            {/*    value={payInfo.count}*/}
            {/*    _onChange={e =>*/}
            {/*      handleInputChange({ name: "payInfo", field: "count", e })*/}
            {/*    }*/}
            {/*    $padding="0.75rem 1rem"*/}
            {/*  />*/}
            {/*  <Plus onClick={() => handleCount("plus")} />*/}
            {/*</td>*/}
          </tr>
          <tr>
            <td>이용기간</td>
            <td>{usePeriodOfUse(payInfo.term)}</td>
          </tr>
          <tr>
            <td>쿠폰 등록</td>
            <td>
              <form className="couponCodeWrap" onSubmit={checkCouponCode}>
                {couponCode.map((coupon, index) => (
                  <TheInput
                    key={index}
                    name="couponCode"
                    value={coupon}
                    placeholder="----"
                    maxLength={4}
                    inputRef={el => (inputRefs.current[index] = el)}
                    $width="100%"
                    $padding="0.5rem 1rem"
                    _onChange={e => handleCouponCode(e, index)}
                  />
                ))}
                <TheButton
                  type="submit"
                  $style="blackLine"
                  $width="7.5rem"
                  $padding="0.5rem 1rem"
                  $fontSize="0.938rem"
                  $fontWeight={400}
                  disabled={
                    couponCode.filter(item => item.trim() === "").length >= 1
                  }
                >
                  등록
                </TheButton>
              </form>
            </td>
          </tr>
          <tr>
            <td>쿠폰 적용</td>
            <td>
              <TheDropBox
                $padding="0.75rem 1rem"
                $width="100%"
                initialOption="적용할 쿠폰 선택"
                options={couponList}
                onOptionSelect={e => handleLastPrice(e)}
              />
            </td>
          </tr>
          <tr>
            <td>총 결제금액</td>
            <td>
              <span style={{ color: "#3B4DDD" }}>
                {finalPrice?.toLocaleString() ?? payInfo.price.toLocaleString()}
                원
              </span>
            </td>
          </tr>
        </TheTable>
      </div>
    </PaymentContainer>
  );
};

const PaymentContainer = styled.div`
  & .productTitleTable {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    & .title {
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 1.25rem;
    }

    & .radioWrap {
      display: flex;
      flex-direction: row;
      gap: 1rem;
    }
  }

  & tbody {
    & tr {
      & td {
        height: 3.75rem;
        padding: 0 1rem;
        text-align: left !important;
        font-size: 0.938rem;
        font-weight: 400;
        line-height: 1.25rem;
        letter-spacing: -0.025rem;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        vertical-align: middle;
      }

      & td:nth-child(odd) {
        color: ${({ theme: { color } }) => color.black[300]};
      }
    }
  }

  & .inputWrap {
    display: flex;
    flex-direction: row;
    gap: 0.625rem;
    align-items: center;
  }
  & .countWrap {
    display: flex;
    flex-direction: row;
    gap: 0.625rem;
    padding: 0.75rem 1rem;
    align-items: center;
    justify-content: center;

    & svg {
      cursor: pointer;
      width: 0.75rem;
      height: 0.75rem;
    }
  }
  & .couponCodeWrap {
    display: flex;
    flex-direction: row;
    gap: 0.625rem;
    align-items: center;
  }
`;

export default PaymentPriceTable;
