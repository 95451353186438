import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

// 로그인 상태인 사용자가 /home에 강제 접근 시 /mytos로 리다이렉트
function PublicRoute({ children }) {
  const isLogin = useSelector(state => state.auth.userAuth);

  if (isLogin) {
    return <Navigate to="/mytos" replace />;
  }
  return children;
}

export default PublicRoute;
