import React, { useEffect, useState } from "react";
import { TheButton, TheSection } from "../../../components";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getNoticeDetailApi } from "../../../services/controller/noticeController";

const NoticeDetailPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const notice = location.state;

  const [noticeDetail, setNoticeDetail] = useState({
    idx: 8,
    category: "test",
    title: "testtitle",
    content: "testcontent",
    author: "관리자",
    createdDate: "2025-01-06T19:56:51",
    exposureDate: null,
    view: 1,
    isFixed: false,
    pagNum: 1,
    pageSize: 10,
    offset: 0,
  });

  const getNoticeDetail = async () => {
    try {
      const data = await getNoticeDetailApi({
        NoticeIdx: notice.NoticeIdx,
      });
      switch (data.result) {
        case true:
        case "true":
          if (data.status === "0000") {
            setNoticeDetail(data.output);
          }
          break;
        default:
          setNoticeDetail({});
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // 노티스 리스트 호출 api
    getNoticeDetail();
  }, []);

  return (
    <TheSection>
      <DetailContainer>
        <DetailContent>
          <h1>
            <span className="division">[{noticeDetail.Category}] </span>
            <span className="subject">{noticeDetail.Title}</span>
          </h1>
          <p>
            <span>작성자: {noticeDetail.Author}</span>
            <span>작성일: {noticeDetail.CreatedDate?.replace(/T.*$/, "")}</span>
          </p>
          <div className="textWrap">
            <div className="text">{noticeDetail.Content}</div>
          </div>
        </DetailContent>
        <ButtonWrap>
          <TheButton $width="9.75rem" _onClick={() => navigate("/notice")}>
            목록
          </TheButton>
        </ButtonWrap>
      </DetailContainer>
    </TheSection>
  );
};

const DetailContainer = styled.article`
  display: grid;
  row-gap: 1.5rem;
  width: 70rem;
  min-width: 20rem;
  max-width: 70rem;
  margin-top: 3rem;
`;

const DetailContent = styled.div`
  & h1 {
    height: 4.75rem;
    border-top: 0.063rem solid #878d96;
    align-content: center;
    font-size: 1.5rem;
    font-weight: 600;

    & .division {
      color: ${({ theme: { color } }) => color.blue[300]};
    }
  }

  & p {
    font-size: 1rem;
    font-weight: 400;
    height: 3.75rem;
    display: flex;
    align-items: center;
    gap: 1.125rem;
    border-top: 0.063rem solid #878d9680;
  }

  & .textWrap {
    display: flex;
    justify-content: center;

    & .text {
      width: 100%;
      min-height: 19.5rem;
      height: 100%;
      background-color: ${({ theme: { color } }) => color.white[400]};
      padding: 2rem 2rem 2.5rem 2rem;
      border-radius: 0.375rem;
      color: ${({ theme: { color } }) => color.black[300]};
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
      letter-spacing: -0.025rem;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      white-space: pre-line;
      word-wrap: break-word;
      word-break: break-all;
    }
  }
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
`;
export default NoticeDetailPage;
