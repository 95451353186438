import React from "react";
import styled from "styled-components";
import userSignupImg from "../../../assets/images/userSignupImg.png";
import { TheButton } from "../../../components";
import { useNavigate } from "react-router-dom";

const SignupSuccessBox = () => {
  const navigate = useNavigate();
  return (
    <InfoContainer>
      <div className="mainBox">
        <img src={userSignupImg} alt="userSignup" />
        <div className="titleCopy">
          <h6>환영합니다!</h6>
          <h6>회원가입이 완료되었습니다.</h6>
          <p>지금 바로 TheOtherSpace를 시작해보세요.</p>
        </div>
        <div>
          <TheButton $width="10.625rem" _onClick={() => navigate("/")}>
            로그인
          </TheButton>
        </div>
      </div>
    </InfoContainer>
  );
};

const InfoContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & .mainBox {
    width: 24.625rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    & img {
      width: 10.625rem;
      height: 6.246875rem;
    }
    & .titleCopy {
      & h6 {
        font-size: 2rem;
        font-weight: 600;
        line-height: 2.375rem;
        text-align: center;
      }

      & p {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.193125rem;
        text-align: center;
        padding: 0.5rem 0;
      }
    }
  }
`;

export default SignupSuccessBox;
