import React from "react";
import styled, { css } from "styled-components";

/**
 * alert, confirm 모달
 * @example
 * // 사용 예시
 * <TheButton
 *   name = "",
 *   type = "button",
 *   disabled = false,
 *   $style = "gray", // 버튼 스타일 타입
 *   _onClick={handleClick},
 *   $margin = "0 0",
 *   $padding = "0.75rem 2.5rem",
 *   $width = "100%",
 *   $fontSize = "0.938rem",
 *   $fontWeight = 600,
 *   $radius = "0.375rem",
 * >
 * { children}
 * </TheButton>
 * */
const TheButton = ({
  children,
  name = "",
  type = "button",
  disabled = false,
  _onClick,
  $margin = "0 0",
  $padding = "0.75rem 2.5rem",
  $width = "100%",
  $fontSize = "0.938rem",
  $fontWeight = 600,
  $radius = "0.375rem",
  $border = "none",
  $style = "default",
}) => {
  const styles = {
    $margin: $margin,
    $padding: $padding,
    $width: $width,
    $fontSize: $fontSize,
    $fontWeight: $fontWeight,
    $radius: $radius,
    $border: $border,
    $style: $style,
  };

  const handleClick = e => {
    _onClick && _onClick(e);
  };

  switch ($style) {
    case "gray":
      return (
        <GrayBtn
          name={name}
          disabled={disabled}
          type={type}
          {...styles}
          onClick={e => handleClick(e)}
        >
          {children}
        </GrayBtn>
      );
    case "primary":
      return (
        <PrimaryBtn
          name={name}
          disabled={disabled}
          type={type}
          {...styles}
          onClick={e => handleClick(e)}
        >
          {children}
        </PrimaryBtn>
      );
    case "primaryLine":
      return (
        <ColorLineBnt
          name={name}
          disabled={disabled}
          type={type}
          {...styles}
          onClick={e => handleClick(e)}
        >
          {children}
        </ColorLineBnt>
      );
    case "blackLine":
      return (
        <BlackLineBnt
          name={name}
          disabled={disabled}
          type={type}
          {...styles}
          onClick={e => handleClick(e)}
        >
          {children}
        </BlackLineBnt>
      );
    case "whiteText":
      return (
        <WhiteTextBtn
          name={name}
          disabled={disabled}
          type={type}
          {...styles}
          onClick={e => handleClick(e)}
        >
          {children}
        </WhiteTextBtn>
      );
    case "primaryText":
      return (
        <PrimaryTextBtn
          name={name}
          disabled={disabled}
          type={type}
          {...styles}
          onClick={e => handleClick(e)}
        >
          {children}
        </PrimaryTextBtn>
      );
    case "blackText":
      return (
        <BlackTextBtn
          name={name}
          disabled={disabled}
          type={type}
          {...styles}
          onClick={e => handleClick(e)}
        >
          {children}
        </BlackTextBtn>
      );
    case "popupDefault":
      return (
        <PopupDefaultBtn
          name={name}
          disabled={disabled}
          type={type}
          {...styles}
          onClick={e => handleClick(e)}
        >
          {children}
        </PopupDefaultBtn>
      );
    case "popupCancel":
      return (
        <PopupCancelBtn
          name={name}
          disabled={disabled}
          type={type}
          {...styles}
          onClick={e => handleClick(e)}
        >
          {children}
        </PopupCancelBtn>
      );
    case "tryNow":
      return (
        <TryNowBtn
          name={name}
          disabled={disabled}
          type={type}
          {...styles}
          onClick={e => handleClick(e)}
        >
          {children}
        </TryNowBtn>
      );
    case "default":
    default:
      return (
        <DefaultBnt
          name={name}
          disabled={disabled}
          type={type}
          {...styles}
          onClick={e => handleClick(e)}
        >
          {children}
        </DefaultBnt>
      );
  }
};

// hover 시 bg transition mixin
const fillFromBottom = hoverColor => css`
  position: relative;
  overflow: hidden;
  z-index: 0;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0;
    background-color: ${hoverColor};
    z-index: -1;
    transition: all 0.3s ease;
  }
  &:hover:not(:disabled)::after {
    height: 100%;
  }
`;

const ButtonEl = styled.button`
  display: block;
  width: 100%;
  max-width: ${({ $width }) => $width};
  border: none;
  border-radius: ${({ $radius }) => $radius};
  margin: ${({ $margin }) => $margin};
  padding: ${({ $padding }) => $padding};
  text-align: center;
  font-size: ${({ $fontSize }) => $fontSize};
  font-weight: ${({ $fontWeight }) => $fontWeight};
  border: ${({ $border }) => $border};
  line-height: 1.25rem;
  letter-spacing: -0.025em;
  cursor: pointer;
  transition: color 0.3s ease 0s;
  color: ${({ theme: { color } }) => color.white[400]};

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme: { color } }) => color.white[600]};
    color: ${({ theme: { color } }) => color.gray[600]};
  }
`;
const DefaultBnt = styled(ButtonEl)`
  ${({ theme }) => fillFromBottom(theme.color.blue[700])}

  background-color: ${({ theme: { color } }) => color.blue[400]};
`;
const GrayBtn = styled(ButtonEl)`
  ${({ theme }) => fillFromBottom(theme.color.gray[900])}

  background-color: ${({ theme: { color } }) => color.gray[800]};
`;
const PrimaryBtn = styled(ButtonEl)`
  ${({ theme }) => fillFromBottom(theme.color.blue[400])}

  background-color: ${({ theme: { color } }) => color.white[400]};
  border: 0.062rem solid ${({ theme: { color } }) => color.blue[400]};
  color: ${({ theme: { color } }) => color.blue[400]};

  &:disabled {
    border: 0.062rem solid ${({ theme: { color } }) => color.gray[300]};
  }
`;
const ColorLineBnt = styled(ButtonEl)`
  background-color: ${({ theme: { color } }) => color.white[400]};
  border: 0.062rem solid ${({ theme: { color } }) => color.blue[400]};
  color: ${({ theme: { color } }) => color.blue[400]};

  &:hover:not(:disabled) {
    border: 0.062rem solid ${({ theme: { color } }) => color.blue[700]};
    background-color: ${({ theme: { color } }) => color.white[400]};
  }
  &:disabled {
    border: 0.062rem solid ${({ theme: { color } }) => color.gray[300]};
  }
`;
const BlackLineBnt = styled(ButtonEl)`
  background-color: ${({ theme: { color } }) => color.white[400]};
  border: 0.062rem solid ${({ theme: { color } }) => color.black[400]};
  color: ${({ theme: { color } }) => color.black[400]};

  &:hover:not(:disabled) {
    background-color: ${({ theme: { color } }) => color.white[400]};
    border: 0.062rem solid ${({ theme: { color } }) => color.black[400]};
    color: ${({ theme: { color } }) => color.black[400]};
  }
  &:disabled {
    border: 0.062rem solid ${({ theme: { color } }) => color.gray[300]};
  }
`;

const TextButtonEl = styled.button`
  display: block;
  width: 100%;
  max-width: ${({ $width }) => $width};
  margin: ${({ $margin }) => $margin};
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: -0.025em;
  text-align: center;
  text-decoration-skip-ink: none;
  text-decoration: underline;
  text-underline-position: under;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: color 0.3s ease 0s;

  &:disabled {
    cursor: not-allowed;
    color: ${({ theme: { color } }) => color.gray[600]};
    text-decoration-color: ${({ theme: { color } }) => color.gray[600]};
    -webkit-text-decoration-color: ${({ theme: { color } }) => color.gray[600]};
  }
`;
const WhiteTextBtn = styled(TextButtonEl)`
  color: ${({ theme: { color } }) => color.white[400]};
  text-decoration-color: ${({ theme: { color } }) => color.white[400]};
  -webkit-text-decoration-color: ${({ theme: { color } }) => color.white[400]};

  &:hover:not(:disabled) {
    color: ${({ theme: { color } }) => color.gray[400]};
    text-decoration-color: ${({ theme: { color } }) => color.gray[400]};
    -webkit-text-decoration-color: ${({ theme: { color } }) => color.gray[400]};
  }
  &:disabled {
  }
`;
const PrimaryTextBtn = styled(TextButtonEl)`
  color: ${({ theme: { color } }) => color.blue[400]};
  text-decoration-color: ${({ theme: { color } }) => color.blue[400]};
  -webkit-text-decoration-color: ${({ theme: { color } }) => color.blue[400]};

  &:hover:not(:disabled) {
    color: ${({ theme: { color } }) => color.blue[700]};
    text-decoration-color: ${({ theme: { color } }) => color.blue[700]};
    -webkit-text-decoration-color: ${({ theme: { color } }) => color.blue[700]};
  }
  &:disabled {
  }
`;
const BlackTextBtn = styled(TextButtonEl)`
  color: ${({ theme: { color } }) => color.black[400]};
  text-decoration-color: ${({ theme: { color } }) => color.black[400]};
  -webkit-text-decoration-color: ${({ theme: { color } }) => color.black[400]};

  &:hover:not(:disabled) {
    color: ${({ theme: { color } }) => color.black[200]};
    text-decoration-color: ${({ theme: { color } }) => color.black[200]};
    -webkit-text-decoration-color: ${({ theme: { color } }) =>
      color.black[200]};
  }
  &:disabled {
  }
`;

const PopupButtonEl = styled.button`
  display: block;
  width: 100%;
  max-width: ${({ $width }) => $width};
  border: none;
  border-radius: ${({ $radius }) => $radius};
  margin: ${({ $margin }) => $margin};
  padding: 0.562rem 1.562rem;
  text-align: center;
  font-size: 0.812rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: -0.025em;
  cursor: pointer;
  transition: color 0.3s ease 0s;
  color: ${({ theme: { color } }) => color.white[400]};
  background-color: transparent;

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme: { color } }) =>
      color.checkbox.disabledBackground};
    color: ${({ theme: { color } }) => color.gray[600]};
  }
`;
const PopupDefaultBtn = styled(PopupButtonEl)`
  ${({ theme }) => fillFromBottom(theme.color.black[350])}

  background-color: ${({ theme: { color } }) => color.black[400]};

  &:disabled {
  }
`;
const PopupCancelBtn = styled(PopupButtonEl)`
  ${({ theme }) => fillFromBottom(theme.color.gray[100])}

  border: 0.062rem solid ${({ theme: { color } }) => color.gray[300]};
  color: ${({ theme: { color } }) => color.black[400]};

  &:hover:not(:disabled) {
    border: 0.062rem solid ${({ theme: { color } }) => color.gray[300]};
  }
  &:disabled {
    background-color: ${({ theme: { color } }) =>
      color.checkbox.disabledBackground};
    border: 0.062rem solid ${({ theme: { color } }) => color.gray[300]};
    font-weight: 700;
    color: ${({ theme: { color } }) => color.gray[600]};
  }
`;

const TryNowButtonEl = styled.button`
  display: block;
  width: 100%;
  max-width: ${({ $width }) => $width};
  border: 0.125rem solid ${({ theme: { color } }) => color.white[50]};
  border-radius: ${({ $radius }) => $radius};
  margin: ${({ $margin }) => $margin};
  padding: 0.75rem 1rem;
  text-align: center;
  font-size: 1rem;
  font-weight: ${({ $fontWeight }) => $fontWeight};
  line-height: 1.25rem;
  letter-spacing: -0.025em;
  cursor: pointer;
  transition: color 0.3s ease 0s;
  color: ${({ theme: { color } }) => color.white[400]};
  background-color: transparent;

  &:disabled {
    cursor: not-allowed;
  }
`;
const TryNowBtn = styled(TryNowButtonEl)`
  ${({ theme }) => fillFromBottom(theme.color.red[700])}

  background-color: ${({ theme: { color } }) => color.red[400]};

  &:hover:not(:disabled) {
    border-color: ${({ theme: { color } }) => color.red[300]};
  }
  &:disabled {
    background-color: ${({ theme: { color } }) =>
      color.checkbox.disabledBackground};
    border-color: ${({ theme: { color } }) => color.gray[300]};
    color: ${({ theme: { color } }) => color.gray[50]};
    font-size: 0.938rem;
  }
`;

export default TheButton;
