import React from "react";
import styled from "styled-components";
import whiteCheck from "../../assets/images/whiteCheck.png";
import disableCheck from "../../assets/images/disableCheck.png";

/**
 * 체크박스
 * @example
 * // 사용 예시
 * <TheCheckBox
 *    id="아이디"
 *    label={"보여질 라벨"}
 *    checked={체크여부 true 시 체크}
 *    _onChange={체크 상태 변경 처리}
 *    $checkColor={"체크된 상태의 박스  내부 색"}
 *    $size="1.125rem"
 * />
 * */

const TheCheckBox = ({
  id,
  label,
  checked,
  _onChange,
  disabled = false,
  $width = "100%",
  $margin = "0 0",
  $padding = "1rem 0 0 1rem",
  $size = "1.25rem",
  $color,
  $borderColor = "#E5E5ECFF",
  $backgroundColor = "#fff",
  $checkColor = "black",
  $fontWeight = "400",
  $fontSize = "0.875rem",
}) => {
  const handleChange = event => {
    _onChange && _onChange(event);
  };
  const styles = {
    $margin: $margin,
    $padding: $padding,
    $width: $width, // 라벨 너비
    $size: $size, // checkbox 가로 세로 크기
    $color: $color, // 라벨색
    $borderColor: $borderColor, // 테두리
    $backgroundColor: $backgroundColor, // 아무것도 아닐때 배경색
    $checkColor: $checkColor, // 체크 되었을 때 배경 색
    $fontWeight: $fontWeight,
    $fontSize: $fontSize,
  };

  return (
    <CheckboxWrapper>
      <CheckboxStyle
        type="checkbox"
        id={id}
        name={id}
        checked={checked}
        onChange={handleChange}
        disabled={disabled}
        {...styles}
      />
      <StyledLabel htmlFor={id} {...styles}>
        {label}
      </StyledLabel>
    </CheckboxWrapper>
  );
};

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const CheckboxStyle = styled.input`
  appearance: none;
  width: ${({ $size }) => $size};
  height: ${({ $size }) => $size};
  margin: ${({ $margin }) => $margin};
  border: 0.062rem solid
    ${({ theme: { color } }) => color.checkbox["defaultBorder"]};
  background-color: ${({ theme: { color } }) => color.white[400]};
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;

  // 체크
  &:checked {
    border-color: ${({ $checkColor }) => $checkColor};
    background-color: ${({ $checkColor }) => $checkColor};
    background-image: url(${whiteCheck});
    background-repeat: no-repeat;
    background-position: center;
  }

  // 체크된 비활성화
  &:checked:disabled {
    border: 0.062rem solid
      ${({ theme: { color } }) => color.checkbox["defaultBorder"]};
    background-image: url(${disableCheck});
    background-repeat: no-repeat;
    background-position: center;
    cursor: not-allowed;
  }

  // 체크 안된 비활성화
  &:disabled {
    background-color: ${({ theme: { color } }) =>
      color.checkbox["disabledBackground"]};
    cursor: not-allowed;
  }
`;

const StyledLabel = styled.label`
  color: ${({ $color, theme: { color } }) => $color || color.white[400]};
  font-weight: ${({ $fontWeight }) => $fontWeight};
  margin: ${({ $margin }) => $margin};
  font-size: ${({ $fontSize }) => $fontSize};
  line-height: 1.25rem;
  max-width: ${({ $width }) => $width};
  letter-spacing: -0.021875rem;
  cursor: pointer;
`;

export default TheCheckBox;
