import { configureStore } from "@reduxjs/toolkit";

// Reducer
import homeSlice from "./main/home/homeSlice";
import commonSlice from "./main/shared/commonSlice";
import authSlice from "./main/shared/authSlice";
import contactSlice from "./main/contact/contactSlice";
import signUpSlice from "./main/signup/signupSlice";
import mytosSlice from "./main/mytos/mytosSlice";
import mypageSlice from "./main/mypage/mypageSlice";

const store = configureStore({
  reducer: {
    common: commonSlice,
    home: homeSlice,
    auth: authSlice,
    contact: contactSlice,
    sign: signUpSlice,
    mytos: mytosSlice,
    mypage: mypageSlice,
  },
});

export default store;
