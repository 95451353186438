import { useEffect } from "react";

export const BlankPage = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const token_version_id = queryParams.get("token_version_id");
  const enc_data = queryParams.get("enc_data");
  const integrity_value = queryParams.get("integrity_value");
  const toUrl = queryParams.get("toUrl");

  useEffect(() => {
    if (window.opener) {
      // 1. 먼저 setPhoneNumber 호출
      if (typeof window.opener.setNice === "function") {
        window.opener.setNice({
          token_version_id,
          enc_data,
          integrity_value,
        });
      }

      // 2. SPA 내부 라우팅 (부모 창에서 새로고침 없이 화면만 전환)
      if (typeof window.opener.navigateTo === "function") {
        if (!toUrl) {
          window.opener.navigateTo("/join/user");
        } else {
          window.opener.navigateTo(`${toUrl}`);
        }
      }

      // 3. 자식 창 닫기
      window.close();
    }
  }, []);

  return <div></div>;
};
