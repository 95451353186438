// library
import styled from "styled-components";
import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// components
import {
  TheButton,
  TheCheckBox,
  TheInput,
  TheToggle2,
} from "../../../components";
import {
  loginApi,
  // corpLoginApi,
} from "../../../services/controller/loginController";
// store
import { SET_USER_INFO } from "../shared/authSlice";
import { SET_ALERT_MSG } from "../shared/commonSlice";

const LoginContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("home");

  const codeInputEl = useRef(null);
  const idInputEl = useRef(null);
  const pwdInputEl = useRef(null);

  const [accountType, setAccountType] = useState(
    localStorage.getItem("accountType") || "Individual",
  );
  const [loginInfo, setLoginInfo] = useState({
    id: localStorage.getItem("saveId") ? localStorage.getItem("saveId") : "",
    password: "",
    code: "",
  });
  const [saveId, setSaveId] = useState(!!localStorage.getItem("saveId"));
  const [inputList, setInputList] = useState([
    {
      display: accountType && accountType !== "Individual" ? "block" : "none",
      type: "text",
      name: "code",
      placeholder: "CODE",
      inputRef: codeInputEl,
    },
    {
      display: "block",
      type: "text",
      name: "id",
      placeholder: "ID",
      inputRef: idInputEl,
    },
    {
      display: "block",
      type: "password",
      name: "password",
      placeholder: "PASSWORD",
      inputRef: pwdInputEl,
    },
  ]);

  const initLoginInfo = ({
    id = localStorage.getItem("saveId") ? localStorage.getItem("saveId") : "",
    password = "",
    code = "",
  }) => {
    setLoginInfo({ id, password, code });
  };

  const handleInputFocus = type => {
    switch (type) {
      case "Individual":
        if (localStorage.getItem("saveId")) {
          pwdInputEl && pwdInputEl.current.focus();
          return;
        }
        idInputEl && idInputEl.current.focus();
        return;
      case "Business":
      default:
        codeInputEl && codeInputEl.current.focus();
        return;
    }
  };

  const handleAccountSwitch = () => {
    const newAccountType =
      accountType === "Individual" ? "Business" : "Individual";

    setAccountType(newAccountType);
    localStorage.setItem("accountType", newAccountType);

    // inputList display value 변경
    setInputList(prev => {
      return prev.map((item, idx) => {
        // code input
        if (idx === 0) {
          return {
            ...item,
            display:
              localStorage.getItem("accountType") !== "Individual"
                ? "block"
                : "none",
          };
        }
        return item;
      });
    });

    // 기업/개인 탭 변경 시 기존 입력 데이터값 reset
    initLoginInfo({});

    // input focus 이동
    handleInputFocus(localStorage.getItem("accountType"));
  };

  const handleChange = e => {
    setLoginInfo({ ...loginInfo, [e.target.name]: e.target.value });
  };

  const handleCheckChange = event => {
    setSaveId(event.target.checked);
    if (!event.target.checked) {
      localStorage.removeItem("saveId");
    }
  };

  const onLogin = async e => {
    e.preventDefault();

    try {
      if (saveId) {
        localStorage.setItem("saveId", loginInfo.id);
      }

      const { /*code,*/ id, password } = loginInfo;
      let res = {};

      switch (accountType) {
        case "Individual":
          res = await loginApi({
            UserID: id,
            Password: password,
          });
          break;
        case "Business":
          // res = await corpLoginApi({
          //   Code: code,
          //   UserID: id,
          //   Password: password,
          // });
          dispatch(SET_ALERT_MSG("기업코드와 회원정보가 일치하지 않습니다."));
          break;
      }
      switch (res.status) {
        case "0000":
          // 1 사용자 개인정보 저장
          dispatch(SET_USER_INFO(res.output[0]));
          // 2 페이지 이동
          navigate("/mytos");
          return;
        default:
          initLoginInfo({});
      }
    } catch (error) {
      // console.error(error.message);
      dispatch(SET_ALERT_MSG(error.message));
      initLoginInfo({});
    }
  };

  useEffect(() => {
    handleInputFocus(accountType);
  }, []);

  return (
    <LoginWrap $isIndividual={accountType === "Individual"}>
      <form className="loginForm" onSubmit={onLogin}>
        <TheToggle2
          options={["Individual", "Business"]}
          selected={accountType}
          onChange={handleAccountSwitch}
        />
        <div className="loginBox">
          <div className="inputs">
            {inputList.map((item, idx) => (
              <TheInput
                flag="login"
                key={idx}
                type={item.type}
                name={item.name}
                placeholder={item.placeholder}
                value={loginInfo[item.name]}
                required={item.display === "block"}
                inputRef={item.inputRef}
                $display={item.display}
                _onChange={e => handleChange(e)}
              />
            ))}
          </div>
          <div className="rememberWrap">
            <div className="checkboxWrap">
              <TheCheckBox
                id="remember"
                label="Remember ID"
                checked={saveId}
                _onChange={handleCheckChange}
                $checkColor="black"
                $size="1.25rem"
                $fontSize="1.125rem"
              />
            </div>
            <div className="findId">
              <button
                type="button"
                style={{ whiteSpace: "nowrap" }}
                onClick={() =>
                  navigate(
                    `/find/id/${accountType === "Individual" ? "0" : "1"}`,
                  )
                }
              >
                {t("find")}
              </button>
            </div>
          </div>
        </div>
        <div className="loginBtnWrap">
          <TheButton
            $color="blue"
            $padding="0.938rem 1rem"
            $width="8.125rem"
            $height="3rem"
            $fontSize="1rem"
            $fontWeight="400"
            $radius="0.375rem"
            type="submit"
          >
            LOGIN
          </TheButton>
        </div>
      </form>
    </LoginWrap>
  );
};

const LoginWrap = styled.div`
  width: 38.75rem;
  min-width: 18.75rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /*padding: 1.25rem;*/
  align-items: center;
  gap: 1.5rem;
  /*box-shadow: 0 1rem 1rem rgba(17, 17, 17, 0.05);*/

  & .loginForm {
    width: 100%;
    min-width: 22.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // gap: ${({ $isIndividual }) => ($isIndividual ? "3rem" : "1.5rem")};
  }

  & input[type="password"]::-ms-reveal,
  & input[type="password"]::-ms-clear {
    display: none;
  }

  & .loginBox {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  & .inputs {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  & .rememberWrap {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    justify-content: space-between;
  }
  & .checkboxWrap {
    display: flex;
    align-items: center;
  }
  & .checkboxLabel {
    color: ${({ theme: { color } }) => color.white[400]};
    font-size: 0.875rem;
  }
  & .findId {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: 1rem;

    & button {
      background-color: transparent;
      border: none;
      color: ${({ theme: { color } }) => color.white[400]};
      font-size: 1.125rem;
      font-weight: 400;
    }
  }
  & .loginBtnWrap {
    margin-top: 1.5rem;
    display: flex;
    justify-content: center;
  }
`;

export default LoginContainer;
