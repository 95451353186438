import { api } from "../index";

export const getProductListApi = async () => {
  try {
    const response = await api.post("/tos/products/list", {});

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
