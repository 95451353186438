import styled from "styled-components";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import {
  TheSection,
  TheInput,
  TheCheckBox,
  TheButton,
} from "../../../components";
import UserConsentModal from "../signup/UserConsentModal";

import { SET_ALERT_MSG } from "../shared/commonSlice";
import useFormValidation from "../../../hooks/useFormValidation";
import { contactSubmitApi } from "../../../services/controller/contactController";

const ContactPage = () => {
  const dispatch = useDispatch();
  const { validateEmail, validatePhone, isEmailError, isPhoneError } =
    useFormValidation();

  const [contactInfoList, setContactInfoList] = useState([
    {
      label: "이름",
      placeholder: "이름을 입력해주세요.",
      name: "name",
      value: "",
    },
    {
      label: "회사명",
      placeholder: "회사 이름을 입력해주세요.",
      name: "companyName",
      value: "",
    },
    {
      label: "연락처",
      placeholder: "'-' 없이 숫자만 입력해 주세요.",
      name: "tel",
      value: "",
    },
    {
      label: "이메일",
      placeholder: "이메일을 입력해주세요.",
      name: "email",
      value: "",
    },
    {
      label: "문의내용",
      placeholder: "문의내용을 입력해주세요.",
      name: "content",
      value: "",
      type: "textarea",
    },
  ]);

  const [consentList, setConsentList] = useState({
    type: "checkbox",
    id: "agree",
    label: "개인정보 수집 및 이용에 동의합니다.",
    name: "agree",
    checked: false,
  });

  const authList = {
    agree: {
      content: "",
      title: "개인정보처리방침",
      src: `${window.location.origin}/policy/privacyPolicy.html`,
    },
  };

  const [showModal, setShowModal] = useState(false);

  const setInputValue = ({ value, obj }) => {
    // 이메일, 전화번호 유효성 검사
    if (obj.name === "email") {
      validateEmail(value);
    }
    if (obj.name === "tel") {
      validatePhone(value);
    }

    setContactInfoList(prevItems =>
      prevItems.map(item => {
        if (item.name === obj.name) {
          item.value = value;
        }
        return item;
      }),
    );
  };

  // const handleCheckBoxClick = ({ value }) => {
  const handleCheckBoxClick = () => {
    // 체킹 안 되어있을때 박스 클릭하면 동의 모달 띄움
    // if (!consentList.checked) {
    //   getPolicy();
    //   setShowModal(value);
    // } else {
    // 만약 해당 정책이 이미 동의 상태일 땐? 그냥 동의만 풀어줌.
    setConsentList(prevState => ({
      ...prevState,
      checked: !prevState.checked,
      isValid: !prevState.checked,
    }));
    // }
  };
  const justModalOpen = ({ value }) => {
    // 클릭된 정책 내용을 담은 모달을 엶
    setShowModal(value);
  };
  const handleAuthAccept = value => {
    // 개인정보 수집 동의 모달 안에서 동의 여부 선택
    setConsentList(prevState => ({
      ...prevState,
      checked: value,
      isValid: value,
    }));
    setShowModal(false);
  };

  const isSubmitBtnDisabled = () => {
    // 회사명 제외 빈값 체크 (회사명은 필수값 아님)
    const invalidFields = contactInfoList.map(item => {
      if (item.name === "companyName") return false;
      return !(item.value.trim() !== "");
    });

    return invalidFields.includes(true) || !consentList.checked;
  };

  const emptyValueValidation = () => {
    contactInfoList.forEach(item => {
      if (item.name !== "companyName" && item.value.trim() === "") {
        dispatch(SET_ALERT_MSG("필수값에 공백값은 입력 불가합니다. "));
        return false;
      }
    });
    return true;
  };

  const onSubmitForm = async e => {
    e.preventDefault();

    const res = await emptyValueValidation();
    if (!res) return;

    const contactInfo = {};
    contactInfoList.forEach(item => {
      contactInfo[item.name] = item.value;
    });
    contactInfo[consentList.name] = consentList.checked;

    if (isPhoneError) {
      dispatch(SET_ALERT_MSG("올바른 전화번호 형식을 입력해주세요"));
      return;
    }

    if (isEmailError) {
      dispatch(SET_ALERT_MSG("올바른 이메일 주소를 입력해주세요"));
      return;
    }

    if (!isEmailError && !isPhoneError) {
      try {
        let tmp = {};
        contactInfoList.forEach(item => {
          tmp = { ...tmp, [item.name]: item.value };
        });

        let data = await contactSubmitApi({
          UserName: tmp.name.trim(),
          CompanyName: tmp.companyName.trim(),
          UserPhone: tmp.tel.trim(),
          UserEmail: tmp.email.trim(),
          Content: tmp.content.trim(),
          Agree: true,
        });

        switch (data.result) {
          case true:
          case "true":
            if (data.status === "0000") {
              dispatch(
                SET_ALERT_MSG(
                  "문의 등록이 완료되었습니다. \n답변은 입력해주신 이메일로 발송됩니다.",
                ),
              );

              // 값 비우기
              setContactInfoList(prevItems =>
                prevItems.map(item => ({ ...item, value: "" })),
              );
              setConsentList(prevState => ({
                ...prevState,
                checked: false,
              }));
            }
            return;
          default:
            dispatch(SET_ALERT_MSG("문의 등록에 실패하였습니다."));
            return;
        }
      } catch (error) {
        console.log(error);
        dispatch(SET_ALERT_MSG(error.message));
      }
    }
  };

  return (
    <TheSection>
      <PageWrapEl>
        <li>
          <strong>
            도움이 필요하신가요? <br />
            TheOtherSpace가 도와드리겠습니다
          </strong>
          <img
            src={require("../../../assets/images/contactImage.png")}
            alt="theOtherSpaceImage"
          />
        </li>
        <li>
          <form onSubmit={onSubmitForm}>
            <ul>
              {contactInfoList.map((item, idx) => (
                <li key={idx}>
                  {item.type === "textarea" ? (
                    <TheInput
                      flag="label_custom"
                      label={item.label}
                      required={item.required}
                    >
                      <TheInput
                        flag="textarea"
                        name={item.name}
                        value={item.value}
                        placeholder={item.placeholder}
                        $padding="1.25rem"
                        _onChange={e =>
                          setInputValue({
                            value: e.target.value,
                            obj: item,
                          })
                        }
                      />
                    </TheInput>
                  ) : (
                    <TheInput
                      flag="label"
                      label={item.label}
                      type={item.type ?? "text"}
                      name={item.name}
                      value={item.value}
                      placeholder={item.placeholder}
                      $padding="0.75rem"
                      _onChange={e =>
                        setInputValue({
                          value: e.target.value,
                          obj: item,
                        })
                      }
                    />
                  )}
                </li>
              ))}
            </ul>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "2rem",
              }}
            >
              <TheCheckBox
                id={consentList.id}
                label={consentList.label}
                checked={consentList.checked}
                $color="#111111"
                $checkColor="#111111"
                _onChange={e =>
                  handleCheckBoxClick({
                    e,
                    obj: consentList,
                    value: true,
                  })
                }
              />
              <button
                type="button"
                className="tempBtn"
                onClick={() => justModalOpen({ value: true })}
              >
                &gt;
              </button>
            </div>
            <TheButton
              disabled={isSubmitBtnDisabled()}
              type="submit"
              $width="9.75rem"
              $margin="2rem auto 0"
            >
              제출
            </TheButton>
          </form>
        </li>
      </PageWrapEl>
      {showModal && (
        <UserConsentModal
          authList={authList}
          selectAuth="agree"
          src={authList.agree.src}
          _justClose={() => handleAuthAccept(false)}
          _acceptClose={() => handleAuthAccept(true)}
        />
      )}
    </TheSection>
  );
};

const PageWrapEl = styled.ul`
  width: 100%;
  height: 100%;
  margin: auto 0;
  /*height: max-content;*/
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 1rem;

  & > li {
    padding: 2rem;
    height: 37.96875rem;
    margin: auto 0;
    /*align-self: center;*/
  }
  & > li:nth-child(1) > strong {
    display: inline-block;
    font-size: 1.75rem;
    font-weight: 600;
    line-height: 2.088rem;
    letter-spacing: -0.025em;
    margin-bottom: 3.25rem;
  }
  & > li:nth-child(1) > img {
    width: 30rem;
    height: 17.953rem;
    border-radius: 0.375rem;
  }
  & > li:nth-child(2) ul li:not(:last-child) {
    margin-bottom: 0.5rem;
  }
  & > li:nth-child(2) ul li:last-child {
    margin-bottom: 1.5rem;
  }
  & .tempBtn {
    border: none;
    background-color: transparent;
    color: #2f2f2f;
    transition: color ease-in-out 0.3s;
  }
  & .tempBtn:hover {
    color: #3b4ddd;
  }
`;
export default ContactPage;
