import React from "react";
import styled from "styled-components";

const TheInput = ({
  children,
  flag = "input", // input, label, textarea, login, underLine
  label,
  name,
  type = "text",
  placeholder,
  value,
  required = false,
  disabled = false,
  max = "",
  min = "",
  showRes = false,
  inputRef,
  maxLength,
  _onChange,
  _onKeyDown,
  _onFocus,
  _onBlur,
  $margin = "0 0",
  $height,
  $padding = "0 1.25rem",
  $width = "100%",
  $fontSize = "0.938rem",
  $fontWeight = 400,
  $radius = "0.375rem",
  $display = "block",
  isValid = false,
  isError = false,
  errorMsg = "사용 불가능합니다.",
}) => {
  const styles = {
    $width: $width,
    $padding: $padding,
    $margin: $margin,
    $fontSize: $fontSize,
    $fontWeight: $fontWeight,
    $radius: $radius,
    $height: $height,
    $display: $display,
  };

  const handleChange = e => {
    _onChange && _onChange(e);
  };

  const handleKeyDown = e => {
    _onKeyDown && _onKeyDown(e);
  };

  const handleFocus = e => {
    _onFocus && _onFocus(e);
  };

  const handleBlur = e => {
    _onBlur && _onBlur(e);
  };

  switch (flag) {
    case "textarea":
      return (
        <TextareaEl
          name={name}
          ref={inputRef}
          placeholder={placeholder}
          value={value}
          required={required}
          disabled={disabled}
          max={max}
          min={min}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          onBlur={handleBlur}
          isValid={isValid}
          isError={isError}
          autoComplete="new-password"
          {...styles}
        />
      );
    case "label":
      return (
        <div style={{ minHeight: label ? "4.425rem" : "max-content" }}>
          {label && (
            <TitleEl>
              {label} {required && <RequiredDotEl />}
            </TitleEl>
          )}
          <div style={{ position: "relative" }}>
            <InputEl
              name={name}
              ref={inputRef}
              type={type ?? "text"}
              placeholder={placeholder}
              value={value}
              required={required}
              disabled={disabled}
              max={max}
              min={min}
              maxLength={maxLength}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              onFocus={handleFocus}
              onBlur={handleBlur}
              isValid={isValid}
              isError={isError}
              {...styles}
            />
            {showRes && (
              <InputResultEl
                isValid={isValid}
                isError={isError}
                errorMsg={errorMsg}
              >
                {isValid ? "사용 가능 합니다." : isError ? errorMsg : ""}
              </InputResultEl>
            )}
          </div>
        </div>
      );
    case "label_custom":
      return (
        <div>
          {label && (
            <TitleEl>
              {label} {required && <RequiredDotEl />}
            </TitleEl>
          )}
          <div style={{ position: "relative" }}>
            {children}
            {showRes && (
              <InputResultEl
                isValid={isValid}
                isError={isError}
                errorMsg={errorMsg}
              >
                {isValid ? "사용 가능 합니다." : isError ? errorMsg : ""}
              </InputResultEl>
            )}
          </div>
        </div>
      );
    case "login":
      return (
        <LoginInputEl
          name={name}
          ref={inputRef}
          type={type}
          placeholder={placeholder}
          value={value}
          required={required}
          disabled={disabled}
          max={max}
          min={min}
          maxLength={maxLength}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          onBlur={handleBlur}
          autoComplete="off"
          {...styles}
        />
      );
    case "underLine":
      return (
        <div style={{ minHeight: label ? "4.425rem" : "max-content" }}>
          {label && (
            <TitleEl>
              {label} {required && <RequiredDotEl />}
            </TitleEl>
          )}
          <div style={{ position: "relative" }}>
            <UnderLineInputEl
              name={name}
              ref={inputRef}
              type={type ?? "text"}
              placeholder={placeholder}
              value={value}
              required={required}
              disabled={disabled}
              max={max}
              min={min}
              maxLength={maxLength}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              onFocus={handleFocus}
              onBlur={handleBlur}
              isValid={isValid}
              isError={isError}
              {...styles}
            />
            {showRes && (
              <InputResultEl
                isValid={isValid}
                isError={isError}
                errorMsg={errorMsg}
              >
                {isValid ? "사용 가능 합니다." : isError ? errorMsg : ""}
              </InputResultEl>
            )}
          </div>
        </div>
      );
    case "input":
    default:
      return (
        <InputEl
          name={name}
          ref={inputRef}
          type={type}
          placeholder={placeholder}
          value={value}
          required={required}
          disabled={disabled}
          max={max}
          min={min}
          maxLength={maxLength}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          onBlur={handleBlur}
          isValid={isValid}
          isError={isError}
          autoComplete="new-password"
          {...styles}
        />
      );
  }
};

// input
const InputEl = styled.input.withConfig({
  shouldForwardProp: prop => !["isValid", "isError"].includes(prop),
})`
  display: ${({ $display }) => $display};
  font-family: inherit;
  width: ${({ $width }) => $width};
  max-width: ${({ $width }) => $width};
  font-size: ${({ $fontSize }) => $fontSize};
  font-weight: ${({ $fontWeight }) => $fontWeight};
  border: 0.063rem solid ${({ theme: { color } }) => color.gray[400]};
  border-radius: ${({ $radius }) => $radius};
  margin: ${({ $margin }) => $margin};
  padding: ${({ $padding }) => $padding};
  line-height: 1.25rem;
  background-color: ${({ theme: { color } }) => color.white[400]};
  transition: border 0.3s ease color 0.3s ease;

  /* 포커스가 아닌 상태에서만 적용 */
  &:not(:focus) {
    /* 잘못 입력한 경우 */
    ${({ isError }) =>
      isError &&
      `border: 0.063rem solid rgba(220, 0, 0, 1);
      color: rgba(220, 0, 0, 1);
    `}

    /* 적절한 경우 */
    ${({ isValid }) =>
      isValid &&
      `border: 0.063rem solid rgba(67, 127, 236, 1);
      color: rgba(67, 127, 236, 1);
    `}
  }
  &:hover {
    background: ${({ theme: { color } }) => color.white[500]};
    transition: ease-out 0.5s;
    cursor: pointer;
  }
  &:focus {
    /*border: 0.063rem solid ${({ theme: { color } }) => color.blue[400]};*/
    border: 0.062rem solid ${({ theme: { color } }) => color.black[400]};
  }
  &:disabled {
    color: ${({ disabled, theme: { color } }) =>
      disabled ? color.gray[300] : color.black[400]};
    background: ${({ disabled, theme: { color } }) =>
      color.white[disabled ? 500 : 400]};
    border: 0.063rem solid ${({ theme: { color } }) => color.gray[300]};
    cursor: not-allowed;
  }
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-weight: ${({ $fontWeight }) => $fontWeight};
    color: ${({ theme: { color } }) => color.gray[600]};
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    font-weight: ${({ $fontWeight }) => $fontWeight};
    color: ${({ theme: { color } }) => color.gray[600]};
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    font-weight: ${({ $fontWeight }) => $fontWeight};
    color: ${({ theme: { color } }) => color.gray[600]};
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    font-weight: ${({ $fontWeight }) => $fontWeight};
    color: ${({ theme: { color } }) => color.gray[600]};
  }
`;

// underLine
const UnderLineInputEl = styled(InputEl)`
  border-radius: 0;
  border: none;
  border-bottom: 0.062rem solid #e5e5ec;
  background-color: transparent;

  &:hover {
    background: none;
    border-bottom: 0.062rem solid ${({ theme: { color } }) => color.black[400]};
  }
  &:focus {
    border: none;
    border-bottom: 0.062rem solid ${({ theme: { color } }) => color.black[400]};
  }
  &:disabled {
    border: none;
    border-radius: 0.5rem;
    color: ${({ theme: { color } }) => color.gray[600]};
    background: ${({ theme: { color } }) => color.white[500]};
    cursor: not-allowed;
  }
  /* 포커스가 아닌 상태에서만 적용 */
  &:not(:focus) {
    /* 잘못 입력한 경우 */
    ${({ isError }) =>
      isError &&
      `border: none;
      border-bottom: 0.063rem solid rgba(220, 0, 0, 1);
      color: rgba(220, 0, 0, 1);
    `}

    /* 적절한 경우 */
    ${({ isValid }) =>
      isValid &&
      `border: none;
      border-bottom: 0.063rem solid rgba(67, 127, 236, 1);
      color: rgba(67, 127, 236, 1);
    `}
  }
  &:disabled {
    /* 잘못 입력한 경우 */
    ${({ isError }) =>
      isError &&
      `border: none;
      color: rgba(220, 0, 0, 1);
    `}

    /* 적절한 경우 */
    ${({ isValid }) =>
      isValid &&
      `border: none;
      color: rgba(67, 127, 236, 1);
    `}
  }
`;

// textarea
const TextareaEl = styled(InputEl).attrs({ as: "textarea" })`
  resize: none;
  height: ${({ $height }) => $height};
`;

// label input
const TitleEl = styled.label`
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: -0.025em;
  text-align: right;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 0.3rem;
`;
const RequiredDotEl = styled.span`
  &::after {
    content: "*";
    margin-left: 0.05rem;
    color: ${({ theme: { color } }) => color.red[500]};
  }
`;
const InputResultEl = styled.span.withConfig({
  shouldForwardProp: prop => !["isValid", "isError", "errorMsg"].includes(prop),
})`
  position: absolute;
  bottom: calc(-0.35rem - 0.812rem);
  right: 0;
  display: inline-block;
  width: 100%;
  text-align: right;
  font-size: 0.812rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: -0.025em;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  /* 적절하지 않음 */
  ${({ isError }) =>
    isError &&
    ` color: rgba(220, 0, 0, 1);
    `}
  /* 적절한 경우 */
  ${({ isValid }) =>
    isValid &&
    ` color: rgba(67, 127, 236, 1);
    `}
`;

// LoginInput
const LoginInputEl = styled.input`
  display: ${({ $display }) => $display};
  border-bottom: 0.063rem solid ${({ theme: { color } }) => color.white[400]};
  color: ${({ theme: { color } }) => color.white[400]};
  background-color: transparent;
  padding: 0.75rem 0.5rem;
  gap: 0.625rem;
  font-size: 1.25rem;

  &::placeholder {
    color: ${({ theme: { color } }) => color.white[70]};
  }

  & &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-weight: ${({ $fontWeight }) => $fontWeight};
    color: ${({ theme: { color } }) => color.gray[600]};
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    font-weight: ${({ $fontWeight }) => $fontWeight};
    color: ${({ theme: { color } }) => color.gray[600]};
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    font-weight: ${({ $fontWeight }) => $fontWeight};
    color: ${({ theme: { color } }) => color.gray[600]};
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    font-weight: ${({ $fontWeight }) => $fontWeight};
    color: ${({ theme: { color } }) => color.gray[600]};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-text-fill-color: ${({ theme: { color } }) => color.white[400]};
    -webkit-box-shadow: 0 0 0 62.5rem transparent inset;
    box-shadow: 0 0 0 62.5rem transparent inset;
    background-color: transparent !important;
    transition: background-color 5000s ease-in-out 0s;
  }
`;
export default TheInput;
