import React from "react";
import ReactDOM from "react-dom/client";

import { Provider } from "react-redux";
import store from "./features/store";

import { RouterProvider } from "react-router-dom";

import { Router } from "./router/Router";
import "./utils/i18n/index";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <RouterProvider router={Router} />
  </Provider>,
);
