import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import UserConsentModal from "../../features/main/signup/UserConsentModal";
// import { serviceContentApi } from "../../services/controller/signupController";

const TheFooter = () => {
  const { t } = useTranslation("footer");
  const useLegacy = false;
  const authList = {
    termsOfUse: {
      // TOS 이용약관
      content: "",
      title: t("termsOfUse"),
      src: `${window.location.origin}/policy/termsOfUse.html`,
    },
    privacyPolicy: {
      // TOS 개인정보 처리방침
      content: "",
      title: t("privacyPolicy"),
      src: `${window.location.origin}/policy/privacyPolicy.html`,
    },
  };
  const [showModal, setShowModal] = useState(false);
  const [selectPolicy, setSelectPolicy] = useState("termsOfUse");

  // const getPolicy = async type => {
  //   try {
  //     let data = await serviceContentApi();
  //
  //     switch (data.status) {
  //       case "0000":
  //         data.output.filter(item => {
  //           if (item.agreement_type === type) {
  //             setAuthList({
  //               agree: {
  //                 src: '',
  //                 content: item.content,
  //                 title:
  //                   type === "PRIVACY" ? t("privacyPolicy") : t("termsOfUse"),
  //               },
  //             });
  //           }
  //         });
  //         setShowModal(true);
  //         return;
  //       default:
  //         return;
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const openModal = key => {
    setSelectPolicy(key);
    setShowModal(true);
  };

  return (
    <SubFooterEl>
      <ul>
        <li>
          <strong className="strong">{t("company")}</strong>
        </li>
        <li>
          <PipeUlEl>
            <li>
              {t("ceo")}
              <span>{t("ceoName")}</span>
            </li>
            <li>
              {t("tel")}
              <a href={"tel:02-2627-9000"}>02-2627-9000</a>
            </li>
            <li>
              {t("fax")}
              <a href={"fax:02-2627-9000"}>02-2627-9099</a>
            </li>
            <li>
              {t("registration")}
              <span> 589-86-02669</span>
            </li>
            <li>
              {t("businessReport")}
              <span>{t("businessReportNum")}</span>
            </li>
            <li>
              {t("jeju")}
              <span>{t("jejuAddr")}</span>
            </li>
            {useLegacy && (
              <>
                <li>
                  {t("registration")}
                  <span>119-81-49757(07789)</span>
                </li>
                <li>
                  {t("seoul")}
                  <span>{t("seoulAddr")}</span>
                </li>
              </>
            )}
          </PipeUlEl>
        </li>
      </ul>
      <ul>
        <li>
          <PipeUlEl>
            <li
              style={{ cursor: "pointer" }}
              onClick={() => openModal("termsOfUse")}
            >
              <WhiteSpan>{t("termsOfUse")}</WhiteSpan>
            </li>
            <li
              style={{ cursor: "pointer" }}
              onClick={() => openModal("privacyPolicy")}
            >
              <WhiteSpan>{t("privacyPolicy")}</WhiteSpan>
            </li>
          </PipeUlEl>
        </li>
        <li>
          <PipeUlEl>
            <li>{t("copy")}</li>
          </PipeUlEl>
        </li>
      </ul>
      {showModal && (
        <UserConsentModal
          type="alert"
          authList={authList}
          selectAuth={selectPolicy}
          src={authList[selectPolicy].src}
          _justClose={() => setShowModal(false)}
          _acceptClose={() => setShowModal(false)}
        />
      )}
    </SubFooterEl>
  );
};

const SubFooterEl = styled.footer`
  width: 100%;
  /*height: 9rem;*/
  background-color: #21272a;
  padding: 1.75rem 2.25rem;
  text-align: left;
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 0.5rem;
  font-size: 0.812rem;
  line-height: 1.25rem;
  letter-spacing: -0.025em;

  & .strong {
    color: ${({ theme: { color } }) => color.white[400]};
    font-weight: 600;
  }
`;
const PipeUlEl = styled.ul`
  display: flex;
  justify-content: flex-start;
  align-content: center;

  li,
  span,
  a {
    color: ${({ theme: { color } }) => color.gray[600]};
  }

  span,
  a {
    margin-left: 0.3rem;
  }

  li:not(:last-child)::after {
    content: "|";
    display: inline-block;
    margin: 0 0.3rem;
    color: ${({ theme: { color } }) => color.gray[600]};
  }
`;
const WhiteSpan = styled.span`
  color: ${({ theme: { color } }) => color.white[400]} !important;
`;
export default TheFooter;
