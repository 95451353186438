import styled from "styled-components";

/**
 * 토글 버튼
 * @example
 * // 사용 예시
 * <TheToggle
 *   name="이름"
 *   value="값"
 *   checked="체크 여부 true 시 체크"
 *   disabled="disabled 여부 true 시 disabled"
 *   handleChange={(event) => console.log(event.value, event.checked)}
 * />
 * */
const TheToggle = ({
  children,
  name = "",
  value = "",
  checked = false,
  disabled = false,
  _onChange,
}) => {
  const handleChange = event => {
    _onChange && _onChange(event);
  };
  return (
    <LabelEl>
      <ToggleEl
        type="checkbox"
        role="switch"
        checked={checked}
        disabled={disabled}
        name={name}
        value={value}
        onChange={event => handleChange(event.target)}
      />
      <span>{children}</span>
    </LabelEl>
  );
};

const LabelEl = styled.label`
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
`;
const ToggleEl = styled.input`
  appearance: none;
  position: relative;
  background-color: ${({ theme: { color } }) =>
    color.checkbox.disabledBackground};
  border-radius: 1.25em;
  width: 2.25rem;
  height: 0.625rem;
  cursor: pointer;
  transition: 0.2s ease-in-out background-color;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    transition: left 250ms linear;
    cursor: pointer;
    width: 1.125rem;
    height: 1.125rem;
    top: 50%;
    transform: translateY(-50%);
    gap: 0;
    border-radius: 6.25rem;
    background-color: ${({ theme: { color } }) => color.white[400]};
    box-shadow: 0.062rem 0.062rem 0.188rem 0
      ${({ theme: { color } }) => color.shadow[400]};
  }
  &:not(:disabled) + span {
    cursor: pointer;
  }
  &:checked::before {
    background-color: ${({ theme: { color } }) => color.black[400]};
    left: 1.125rem;
  }
  &:disabled {
    cursor: not-allowed;
  }
  &:disabled::before {
    cursor: not-allowed;
    background-color: ${({ theme: { color } }) => color.white[900]};
  }
  &:disabled + span {
    opacity: 0.7;
    cursor: not-allowed;
  }
  &:disabled:checked + span {
    cursor: not-allowed;
  }
  &:disabled:checked::before {
    left: 1.125rem;
  }
`;
export default TheToggle;
