import styled from "styled-components";
import React from "react";

// import removeBeforeFirstDelimiter from "../../../hooks/useRemoveBeforeFirstDelimiter";
import { TheInput, TheRadioBox, TheTable } from "../../../components";

const PaymentUserTable = ({
  loginInfo,
  shippingInfo,
  isSameAsLogin,
  setIsSameAsLogin,
  handleInputChange,
}) => {
  const showDelivery = false;

  return (
    <LoginInfoContainer>
      {/* 로그인 정보 */}
      <div className="productTitleTable">
        <div className="title">로그인 정보</div>
        <TheTable
          type="details"
          colgroupList={["15%", "85%"]}
          $width="46.25rem"
        >
          <tr>
            <td>이름</td>
            <td>{loginInfo.USER_NAME}</td>
          </tr>
          <tr>
            <td>아이디</td>
            {/*<td>{removeBeforeFirstDelimiter(loginInfo.id)}</td>*/}
            <td>{loginInfo.USERID}</td>
          </tr>
          <tr>
            <td>이메일</td>
            <td>{loginInfo.USER_EMAIL}</td>
          </tr>
          <tr>
            <td>연락처</td>
            <td>
              {`${loginInfo.USER_PHONE?.slice(0, 3)}-${loginInfo.USER_PHONE?.slice(3, 7)}-${loginInfo.USER_PHONE?.slice(7)}`}
            </td>
            {showDelivery && (
              <td className="inputWrap">
                <TheInput
                  type="text"
                  $width="100%"
                  maxLength="3"
                  value={loginInfo.USER_PHONE?.slice(0, 3)}
                  $padding="0.5rem 1rem"
                  disabled={true}
                />
                <TheInput
                  type="text"
                  $width="100%"
                  maxLength="4"
                  value={loginInfo.USER_PHONE?.slice(3, 7)}
                  $padding="0.5rem 1rem"
                  disabled={true}
                />
                <TheInput
                  type="text"
                  $width="100%"
                  maxLength="4"
                  value={loginInfo.USER_PHONE?.slice(7)}
                  $padding="0.5rem 1rem"
                  disabled={true}
                />
              </td>
            )}
          </tr>
        </TheTable>
      </div>

      {/* 배송지 정보 */}
      {showDelivery && (
        <div className="productTitleTable">
          <div className="title">배송지 정보</div>
          <div className="radioWrap">
            <TheRadioBox
              id="sameInfo"
              label={"기존 정보와 동일"}
              checked={isSameAsLogin}
              _onChange={() => setIsSameAsLogin(true)}
              $size="1.125rem"
            />
            <TheRadioBox
              id="newDelivery"
              label={"새로운 배송지"}
              checked={!isSameAsLogin}
              _onChange={() => setIsSameAsLogin(false)}
              $size="1.125rem"
            />
          </div>

          <TheTable
            type="details"
            colgroupList={["15%", "85%"]}
            $width="46.25rem"
          >
            <tr>
              <td>받는 사람</td>
              <td>
                {isSameAsLogin ? (
                  loginInfo.name
                ) : (
                  <TheInput
                    type="text"
                    value={shippingInfo.name}
                    $padding="0.5rem 1rem"
                    _onChange={e =>
                      handleInputChange({
                        name: "shippingInfo",
                        field: "name",
                        e,
                      })
                    }
                  />
                )}
              </td>
            </tr>
            <tr>
              <td>주소</td>
              <td>
                <TheInput
                  type="text"
                  value={shippingInfo.address}
                  $padding="0.5rem 1rem"
                  _onChange={e =>
                    handleInputChange({
                      name: "shippingInfo",
                      field: "address",
                      e,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td>연락처</td>
              {isSameAsLogin ? (
                <td className="inputWrap">
                  {loginInfo.firstTel} {loginInfo.middleTel}{" "}
                  {loginInfo.finalTel}
                </td>
              ) : (
                <td className="inputWrap">
                  <TheInput
                    type="text"
                    $width="8.375rem"
                    maxLength="3"
                    $padding="0.5rem 1rem"
                    value={shippingInfo.firstTel}
                    _onChange={e =>
                      handleInputChange({
                        name: "shippingInfo",
                        field: "firstTel",
                        e,
                      })
                    }
                  />
                  -
                  <TheInput
                    type="text"
                    $width="8.375rem"
                    maxLength="4"
                    value={shippingInfo.middleTel}
                    $padding="0.5rem 1rem"
                    _onChange={e =>
                      handleInputChange({
                        name: "shippingInfo",
                        field: "middleTel",
                        e,
                      })
                    }
                  />
                  -
                  <TheInput
                    type="text"
                    $width="8.375rem"
                    maxLength="4"
                    $padding="0.5rem 1rem"
                    value={shippingInfo.finalTel}
                    _onChange={e =>
                      handleInputChange({
                        name: "shippingInfo",
                        field: "finalTel",
                        e,
                      })
                    }
                  />
                </td>
              )}
            </tr>
          </TheTable>
        </div>
      )}
    </LoginInfoContainer>
  );
};

const LoginInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  & .productTitleTable {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    & .title {
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 1.25rem;
    }

    & .radioWrap {
      display: flex;
      flex-direction: row;
      gap: 1rem;
    }
  }

  & tbody {
    & tr {
      & td {
        height: 3.75rem;
        padding: 0 1rem;
        text-align: left !important;
        font-size: 0.938rem;
        font-weight: 400;
        line-height: 1.25rem;
        letter-spacing: -0.025rem;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        vertical-align: middle;
      }

      & td:nth-child(odd) {
        color: ${({ theme: { color } }) => color.black[300]};
      }
    }
  }

  & .inputWrap {
    display: flex;
    flex-direction: row;
    gap: 0.625rem;
    padding: 0.75rem 1rem;
    align-items: center;
  }
`;

export default PaymentUserTable;
