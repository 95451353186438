import React from "react";
import styled from "styled-components";

import { ReactComponent as ProgressCheck } from "../../assets/svg/progressCheck.svg";

/**
 * 프로그레스바 컴포넌트
 * @example
 * const [currentStep, setCurrentStep] = useState(0);
 *
 * <TheProgressBar
 *   steps=["서비스 선택", "결제하기", "결제 완료"]
 *   currentStep={currentStep}
 *   $activeColor="#3B4DD3" // 활성화 색상
 *   $deactivateColor="#3B4DD3" // 비활성화 색상
 *   $stepWidth = "15.417rem" // step Item 사이즈
 *   $lineWidth = "7.208rem" // step Line 사이즈
 * />
 */
const TheProgressBar = ({
  steps = [],
  currentStep,
  $stepWidth = "15.417rem",
  $lineWidth = "7.208rem",
  $activeColor,
  $deactivateColor,
}) => {
  return (
    <ProgressBar>
      {steps.map((step, index) => (
        <ProgressItem
          key={index}
          className={index <= currentStep ? "active" : ""}
          $activeColor={$activeColor}
          $deactivateColor={$deactivateColor}
          $stepWidth={$stepWidth}
          $lineWidth={$lineWidth}
        >
          <div className="item">
            <p className={`frontLine ${index === 0 ? "hidden" : ""}`}></p>
            <p className="progressCircle">
              {index < currentStep ? <ProgressCheck /> : <span />}
            </p>
            <p
              className={`backLine ${index === steps.length - 1 ? "hidden" : index === currentStep ? "lastBackLine" : ""}`}
            ></p>
          </div>
          <p
            className={`progressText ${index < currentStep ? "deactivate" : ""}`}
          >
            {step}
          </p>
        </ProgressItem>
      ))}
    </ProgressBar>
  );
};

const ProgressBar = styled.ul`
  display: flex;
  justify-content: center;

  // 활성화
  & .active {
    & .item {
      & .progressCircle {
        background: ${({ $activeColor, theme: { color } }) =>
          $activeColor || color.blue[400]};

        & span {
          background: ${({ theme: { color } }) => color.white[400]};
        }
      }

      & .frontLine,
      .backLine {
        background: ${({ $activeColor, theme: { color } }) =>
          $activeColor || color.blue[400]};
      }
    }

    & .progressText {
      color: ${({ $activeColor, theme: { color } }) =>
        $activeColor || color.blue[400]};
    }

    & .deactivate {
      color: ${({ theme: { color } }) => color.gray[700]} !important;
    }
  }
  & .lastBackLine {
    background: ${({ $deactivateColor, theme: { color } }) =>
      $deactivateColor || color.gray[470]} !important;
  }
`;
const ProgressItem = styled.li`
  width: ${({ $stepWidth }) => $stepWidth};
  height: 3.125rem;
  display: grid;
  row-gap: 1rem;

  // 프로그레스바 도형
  & .item {
    display: flex;
    align-items: center;

    & .progressCircle {
      width: 1rem;
      height: 1rem;
      background: ${({ theme: { color } }) => color.white[400]};
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      & span {
        display: inline-block;
        width: 0.5rem;
        height: 0.5rem;
        background: ${({ $deactivateColor, theme: { color } }) =>
          $deactivateColor || color.gray[470]};
        border-radius: 50%;
      }
    }

    & .frontLine,
    .backLine {
      width: ${({ $lineWidth }) => $lineWidth};
      height: 0.125rem;
      background: ${({ $deactivateColor, theme: { color } }) =>
        $deactivateColor || color.gray[470]};
    }

    & .hidden {
      visibility: hidden;
    }
  }

  // 프로그레스바 텍스트
  & .progressText {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.125rem;
    letter-spacing: -0.025rem;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: ${({ theme: { color } }) => color.gray[700]};
  }
`;

export default TheProgressBar;
