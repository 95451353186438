import { api } from "../index";

export const getFaqListApi = async data => {
  try {
    const response = await api.post("/user/faq/list", data);

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
