import React from "react";

import { TheModal } from "../../../components";
import tos_beta_popup from "../../../assets/images/tos_beta_popup.png";

const EventPopup = ({ _justClose }) => {
  return (
    <TheModal
      showAllocateBtn={false}
      $width="594.28px"
      $height="728.47px"
      showStopWatchingTodayBtn={true}
      $backgroundImg={tos_beta_popup}
      _justClose={_justClose}
    ></TheModal>
  );
};

export default EventPopup;
