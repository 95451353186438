import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";

i18n
  .use(HttpBackend) // HttpBackend를 사용하여 JSON 파일 로드
  .use(initReactI18next)
  .init({
    lng: "kr", // 기본 언어 설정
    fallbackLng: "kr", // 기본적으로 사용할 언어
    backend: {
      loadPath: "/translations/{{lng}}/{{ns}}.json", // JSON 파일 경로 설정
    },
    ns: [
      "download",
      "find",
      "footer",
      "header",
      "home",
      "notFound",
      "notice",
      "common",
      "faq",
      "apply",
      "contact",
      "mypage",
      "mytos",
    ], // 사용되는 네임스페이스
    defaultNS: "home", // 기본 네임스페이스
    interpolation: {
      escapeValue: false, // React에서는 기본적으로 XSS 방지 기능이 적용됨
    },
  });

export default i18n;
