import React from "react";
import styled from "styled-components";

const TheSection = ({
  children,
  title = "",
  $padding = "calc(5rem + 3rem) 2.5rem 3rem",
  $width = "75rem",
}) => {
  const styles = {
    $width,
    $padding,
  };

  return (
    <SectionEl {...styles}>
      {title !== "" && <Title>{title}</Title>}
      {children}
    </SectionEl>
  );
};

const SectionEl = styled.section`
  padding: ${({ $padding }) => $padding};
  width: 100%;
  max-width: ${({ $width }) => $width};
  height: 100%;
  margin: 0 auto;
`;

const Title = styled.h2`
  display: block;
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
`;

export default TheSection;
