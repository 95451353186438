import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import removeBeforeFirstDelimiter from "../../../hooks/useRemoveBeforeFirstDelimiter";

import { TheButton, TheCheckBox } from "../../../components";
import PaymentUserTable from "./PaymentUserTable";
import PaymentPriceTable from "./PaymentPriceTable";

import { getUserInfoApi } from "../../../services/controller/loginController";
import { voucherPaymentApi } from "../../../services/controller/paymentController";
import { SET_ALERT_MSG } from "../shared/commonSlice";
import UserConsentModal from "../signup/UserConsentModal";

const PaymentCard = ({ onProgressStep, payInfo }) => {
  let dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [selectAuth, setSelectAuth] = useState("termsOfUse");
  const [showModal, setShowModal] = useState(false);
  const [consentList, setConsentList] = useState([
    {
      text: "개인 정보 수집 및 이용 동의에 관한 사항을 확인했습니다.",
      required: true,
      requiredMsg: "개인 정보 수집 및 이용에 관한 사항을 확인했습니다.",
      accepted: false,
      content: "",
      name: "privacyPolicy",
      title: "개인 정보 수집 및 이용 동의",
      src: `${window.location.origin}/policy/privacyPolicy.html`,
    },
    {
      text: "서비스 결제/환불/해지에 관한 사항을 확인했습니다.",
      required: true,
      requiredMsg: "서비스 결제/환불/해지에 관한 사항을 확인했습니다.",
      accepted: false,
      content: "",
      name: "DaaSTermsOfUse",
      title: "서비스 결제/환불/해지",
      src: `${window.location.origin}/policy/DaaSTermsOfUse.html`,
    },
    // {
    //   text: "프로모션 정보 수신 동의(선택)",
    //   name: "promotionTermsAgreed",
    //   accepted: false,
    // },
  ]);

  const userAlias = useSelector(state => state.auth.userInfo.UserAlias);

  const [state, setState] = useState({
    loginInfo: {},
    shippingInfo: {
      name: "",
      address: "",
      firstTel: "",
      middleTel: "",
      finalTel: "",
    },
    payInfo: {
      ...payInfo,
    },
  });

  const authList = {
    privacyPolicy: {
      // TOS 개인정보 처리방침
      content: "",
      name: "privacyPolicy",
      title: "TOS 개인정보 처리방침",
      src: `${window.location.origin}/policy/privacyPolicy.html`,
    },
    DaaSTermsOfUse: {
      // Dass약관
      content: "",
      name: "DaaSTermsOfUse",
      title: "서비스 결제/환불/해지 약관",
      src: `${window.location.origin}/policy/DaaSTermsOfUse.html`,
    },
  };

  const [isSameAsLogin, setIsSameAsLogin] = useState(true);

  const handleCheck = checked => {
    setChecked(checked);
  };

  const handleProgressStep = async () => {
    try {
      let data = await voucherPaymentApi({
        userId: removeBeforeFirstDelimiter(userAlias),
        productId: payInfo.product_id,
        couponCode,
      });

      switch (data.status) {
        case "0000":
          onProgressStep({ type: "goForward" });
          dispatch(
            SET_ALERT_MSG("상품 결제에 성공하였습니다. TOS를 즐겨보세요!"),
          );
          return;
        default:
          return;
      }
    } catch (error) {
      console.error(error);
      dispatch(SET_ALERT_MSG(error.message));
    }
  };

  const handleCouponCode = e => {
    setCouponCode(e);
  };

  const handleInputChange = ({ name, field, e }) => {
    const updatedValue = e.target.value;

    setState(prevState => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        [field]: updatedValue,
      },
    }));
  };

  const handleCount = type => {
    let currentCount = state.payInfo.count;
    if (type === "plus") {
      currentCount += 1;
    } else if (type === "minus" && currentCount > 1) {
      currentCount -= 1;
    }

    setState(prevState => ({
      ...prevState,
      payInfo: {
        ...prevState.payInfo,
        count: currentCount,
      },
    }));
  };

  const handleCheckBoxClick = ({ obj /*, value*/ }) => {
    // 만약 해당 정책이 이미 동의 상태일땐? 그냥 동의만 풀어줌.
    if (obj.accepted) {
      setConsentList(prevItems =>
        prevItems.map(item =>
          item.name === obj.name ? { ...item, accepted: false } : item,
        ),
      );
    } else {
      setConsentList(prevItems =>
        prevItems.map(item =>
          item.name === obj.name ? { ...item, accepted: true } : item,
        ),
      );
    }
    // console.log(value);
    // 클릭된 정책 내용을 담은 모달을 엶
    // setSelectAuth(obj.name);
    // setShowModal(value);
  };

  const handleAuthAccept = value => {
    // 모달 내부에서 동의/ 취소 클릭에 따라 체크여부 세팅
    setConsentList(prevItems =>
      prevItems.map(item =>
        item.name === selectAuth ? { ...item, accepted: value } : item,
      ),
    );
    setShowModal(false);
  };

  const justModalOpen = ({ obj, value }) => {
    // 클릭된 정책 내용을 담은 모달을 엶
    setSelectAuth(obj.name);
    setShowModal(value);
  };

  const isAllChecked = () => {
    const allChecked = !consentList.every(item => item.accepted);
    return allChecked || !checked;
  };

  const getUserInfo = async () => {
    try {
      let UserID = removeBeforeFirstDelimiter(userAlias);

      let data = await getUserInfoApi({
        UserID,
      });

      switch (data.status) {
        case "0000":
          setState(prev => ({
            ...prev,
            loginInfo: { ...data.output },
          }));
          return;
        default:
          return;
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <PaymentContent>
      <PaymentUserTable
        loginInfo={state.loginInfo}
        shippingInfo={state.shippingInfo}
        isSameAsLogin={isSameAsLogin}
        setIsSameAsLogin={setIsSameAsLogin}
        handleInputChange={handleInputChange}
      />
      <PaymentPriceTable
        payInfo={state.payInfo}
        couponCode={couponCode}
        setCouponCode={setCouponCode}
        handlevouchersCode={handleCouponCode}
        handleCount={handleCount}
        handleInputChange={handleInputChange}
      />
      <CheckBoxWrap>
        {consentList.map((item, idx) => (
          <CheckBoxLiEl key={`${idx}_consent`}>
            <TheCheckBox
              id={item.name}
              label={item.text}
              checked={item.accepted}
              $color="#111111"
              $checkColor="#111111"
              $margin={idx !== 2 ? "0 0 0.5rem" : "0"}
              _onChange={e =>
                handleCheckBoxClick({
                  e,
                  obj: item,
                  value: true,
                })
              }
            />
            <button onClick={() => justModalOpen({ obj: item, value: true })}>
              &gt;
            </button>
          </CheckBoxLiEl>
        ))}
      </CheckBoxWrap>
      <div className="bottomContent">
        <div>
          <TheCheckBox
            id="remember"
            label="신청 상품과 기간 모두 확인하고 신청합니다."
            $color="#111111"
            $checkColor="#111111"
            _onChange={e => handleCheck(e.target.checked)}
            $size="1.125rem"
          />
        </div>
        <div className="buttonWrap">
          <TheButton
            $style="primaryLine"
            $padding="0.75rem 1.25rem"
            $width="7.781rem"
            $fontSize="0.938rem"
            $fontWeight={600}
            _onClick={() => onProgressStep({ type: "goBack" })}
          >
            돌아가기
          </TheButton>
          <TheButton
            $padding="0.75rem 1.25rem"
            $width="7.781rem"
            $fontSize="0.938rem"
            $fontWeight={600}
            disabled={couponCode === "" || isAllChecked()}
            _onClick={() => handleProgressStep()}
          >
            결제하기
          </TheButton>
        </div>
      </div>
      {showModal && (
        <UserConsentModal
          type="alert"
          authList={authList}
          selectAuth={selectAuth}
          src={authList[selectAuth].src}
          _justClose={() => handleAuthAccept(false)}
          _acceptClose={() => handleAuthAccept(false)}
        />
      )}
    </PaymentContent>
  );
};

const PaymentContent = styled.article`
  display: grid;
  justify-items: center;
  row-gap: 2rem;

  & .bottomContent {
    width: 16.063rem;
    display: grid;
    row-gap: 2rem;

    & .buttonWrap {
      display: flex;
      column-gap: 0.5rem;
    }
  }
`;

const CheckBoxWrap = styled.div`
  width: 46.25rem;
`;
const CheckBoxLiEl = styled.li`
  width: 100%;
  display: flex;
  column-gap: 1rem;
  align-items: center;

  & > button {
    border: none;
    background-color: transparent;
    color: #2f2f2f;
    transition: color ease-in-out 0.3s;
  }
  & > button:hover {
    color: #3b4ddd;
  }
`;

export default PaymentCard;
