import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { TheButton, TheTable } from "../../../components";

import {
  getMyListApi,
  unsubscribeApi,
} from "../../../services/controller/mypageController";

import { SET_ALERT_MSG } from "../shared/commonSlice";
import { useTranslation } from "react-i18next";

const ServiceUsageTable = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation("mypage");

  const [activePage, setActivePage] = useState(1);
  const columnSize = ["7%", "7%", "13%", "15%", "15%", "15%", "15%", "13%"];
  const columns = [
    t("serviceLi1"),
    t("serviceLi2"),
    t("serviceLi3"),
    t("serviceLi4"),
    t("serviceLi5"),
    t("serviceLi6"),
    t("serviceLi7"),
    t("serviceLi8"),
  ];
  const [pageCnt, setPageCnt] = useState(10);
  const [resultCnt, setResultCnt] = useState(2);
  const [serviceList, setServiceList] = useState([]);

  const getMyList = async pageNumber => {
    try {
      let data = await getMyListApi({
        pageNumber,
        pageSize: 50,
      });

      // "output": [
      //       {
      //             "subscription_id": 구독 아이디7,
      //             "end_date": 사용기간 종료일 "2025-03-08",
      //             "VMName": vd 이름 ,
      //             "user_id": 사용자 아이디,
      //             "product_id": 상품 아이디 ,
      //             "subscription_status": "active",
      //             "product_name": 상품명 "PrimeX VD",
      //             "auto_renewal": 정기결제 여부 (베타 X)0,
      //         "start_date": 사용기간 시작일 "2025-02-06"
      //       }
      // ],
      // pages": {
      //       "TotalPageCount": 전체 페이지 개수1,
      //     "DataCount": 데이터 건수
      //   },

      switch (data.status) {
        case "0000":
          setServiceList(data.output);
          setPageCnt(data.pages?.TotalPageCount);
          setResultCnt(data.pages?.DataCount);
          return;
        default:
          return;
      }
    } catch (error) {
      console.error(error);
      setServiceList([]);
      setPageCnt(0);
      setResultCnt(0);
      dispatch(SET_ALERT_MSG(error.message));
    }
  };

  const productUnsubscribe = async item => {
    try {
      let data = await unsubscribeApi({
        subscriptionId: item.subscription_id,
        vmName: item.VMName,
      });

      switch (data.status) {
        case "0000":
          await getMyList(1);
          dispatch(
            SET_ALERT_MSG(
              `${item.product_name}(${item.VMName})${t("serviceLi8")}`,
            ),
          );
          return;
        default:
          return;
      }
    } catch (error) {
      console.error(error);
      dispatch(SET_ALERT_MSG(error.message));
    }
  };

  const returnSubscriptionStatus = status => {
    switch (status) {
      case "active":
        return t("active");
      case "pending":
        return t("nonPayment");
      case "cancel":
        return t("end");
      default:
        return;
    }
  };

  useEffect(() => {
    getMyList(1);
  }, [activePage]);

  return (
    <TheTable
      className="serviceUsageTable"
      colgroupList={columnSize}
      theadList={columns}
      totalPages={pageCnt}
      showDataCnt={resultCnt}
      isNoData={!serviceList?.length}
      datePicker={false}
      noDataStr="사용 내역이 존재하지 않습니다."
      _handlePagination={e => setActivePage(e)}
    >
      {serviceList?.map((service, index) => (
        <tr className="noticeTr" key={index}>
          {service.isFixed ? (
            <td className="importTagTd">
              <p className="importTag">{t("importance")}</p>
            </td>
          ) : (
            <td>{serviceList.length - index}</td>
          )}
          <td>
            <span
              style={{
                color:
                  service.subscription_status === "active" ? "#3B4DDD" : "",
              }}
            >
              {returnSubscriptionStatus(service.subscription_status)}
            </span>
          </td>
          <td>{service.product_name}</td>
          <td>{service.VMName ? service.VMName : "-"}</td>
          <td>{t("couponPayment")}</td>
          <td>{service.start_date}</td>
          <td>{service.end_date}</td>
          <td>
            <TheButton
              disabled={service.subscription_status !== "active"}
              _onClick={() => productUnsubscribe(service)}
            >
              {t("couponCancel")}
            </TheButton>
          </td>
        </tr>
      ))}
    </TheTable>
  );
};

export default ServiceUsageTable;
